.sortImg {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: normal;

  img {
    cursor: pointer;
    margin-left: 5px;
    height: 14px;
  }
}
