@import '../../../styles/variables';

.wrapper,
.radioWrapper {
  display: flex;
  flex-wrap: wrap;
}

.radioItem {
  flex-direction: row-reverse;
}

.radioItem span {
  font-size: 12px;
  color: $grayTwo;
}

.title {
  color: $grayTwo;
  font-size: 15px;
  text-transform: capitalize;
  margin-right: 15px;
}
