@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

.wrapper {
  @extend .popUpWrap;
  padding: 25px 35px 0 35px;
}

.title {
  @extend .popUpTitle;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px;
}

.logo {
  margin-bottom: 20px;
}

.generalItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sectionWrap {
  padding: 10px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    text-align: center;
    font-size: 16px;
    span {
      font-weight: bold;
    }
  }
}

.modalWrap {
  margin: 0 auto;
  width: 400px!important;
}

.btnWrap {
  display: flex;
  justify-content: center;
  padding: 20px 50px;
  border-top: 1px solid $grayLightTwo;
  button {
    width: 30%;
    margin: 0;
  }
}

.emailUs {
  @extend .btnYes;
  background: $mainBtn;
  color: $white;
}

.btnEmailWrap {
  @extend .btnWrap;
  margin-top: 0;
  padding: 30px 50px;
  border-top: none;
}
