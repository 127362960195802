@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

.wrapper {
  @extend .popUpWrap;
}

.title {
  @extend .popUpTitle;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px;
}

.titleSection {
  margin: 10px 0 20px 0;
  color: $dark;
}

.generalItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sectionWrap {
  padding: 5px 0px;
}

.btnSave {
  @extend .btnYes;
}
.btnGet {
  @extend .btn;
  margin-right: 20px;
}

.inputSectionAdditionalMargins {
  margin: 0 10px;
}

.buttonsRow {
  display: flex;
  margin: 10px 10px;
  justify-content: space-between;
}

.surveysBlock {
  display: flex;
  margin: 10px 10px;
  justify-content: space-between;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
  & .surveysCheckBox {
    margin: 5px 5px;
  }
}

.noDataBlock {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  p {
    font-size: 20px;
    margin: 0;
  }

  svg {
    font-size: 26px;
    margin-right: 10px;
    * {
      color: $grayLight;
    }
  }
}

.boldSpan{
  font-weight: bold;
  color: rgba(0,0,0, 0.8)
}

.inputDisabled {
  border: none;
}

.inputDisabledNew {
  width: 200px;
}

.modalWrap {
  margin: 0 auto;
  width: 400px!important;
}

.modalSection {
  padding: 20px;
  text-align: center;
}

.btnModalWrap {
  margin-top: 30px;
}
