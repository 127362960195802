@import '../../styles/variables';

.wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  background: $white;
  z-index: 10000;
}

.loader,
.loader:before,
.loader:after {
  background: $mainBtn;
  animation: loading 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: $mainBtn;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: '';
  }

  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &:after {
    left: 1.5em;
  }
}

@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
