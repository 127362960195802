@import '../../../../styles/variables';
@import '../../../../styles/common';

.wrapper {
  @extend .popUpWrap;
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.btnYes {
  margin-right: 0;
}

.btnNo {
  margin-right: 20px;
}

.popUpText {
  
}
