.iconButton {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

.iconButton:focus {
  outline: 0;
}
