@import '../../../../styles/variables';
@import '../../../../styles/common';

.refresh {
  width: calc(100% - 90px);
  padding: 10px 16px 20px;
  border: 1px solid #C8CCCC;
  border-radius: 4px;
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  bottom: 15px;
  left: 45px;
  z-index: 1000;
  box-shadow: 0 3px 10px rgba(black, 0.2);
  margin-bottom: 10px;
  transition: .1s;

  .refreshText {
    color: $red;
    font-size: 18px;
    font-weight: bold;

    .refreshImg {
      margin-right: 16px;
      transform: scale(1.35);

      path {
        fill: $red
      }
    }
  }

  .refreshDesc {
    color: $dark;
    font-size: 13px;
  }

  .stepsBtn {
    @extend .btn;
    padding: 1.5px 7px;
    height: unset;
    width: auto;
    margin: unset;
    position: relative;
    background: $white;
    border: 2px solid $mainBtn;
    max-width: 200px;
  }

  .refreshTime {
    position: absolute;
    bottom: 3px;
    right: 16px;
    font-size: 10px;
  }
}

.menuItem {
  .title {
    font-family: inherit;
    font-weight: inherit;
  }
}
