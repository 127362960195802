@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli:400,500,700&display=swap);
@font-face {
	font-family: "password-mask";
	font-weight: normal;
	src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);  src: url(/static/media/password.0bf6c6d4.ttf);
  src: url(/static/media/password.0bf6c6d4.ttf);
}

* {
	margin: 0;
	padding: 0;
  font-family: 'Muli', sans-serif;
  box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
	scroll-behavior: smooth;
}

.slick-slider {
	width: 100%;
}

input:-webkit-autofill {
	font-family: 'Muli', sans-serif;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.ant-tooltip-content .ant-tooltip-inner {
	z-index: 2000;
	color: #424142;
	background-color: rgba(255,255,255, 0.98);
	padding: 10px 25px;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	    justify-content: center;
	-ms-flex-align: center;
	    align-items: center;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
	border-bottom-color: white;
}

.ant-tooltip-placement-top .ant-tooltip-arrow:before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow:before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow:before {
	background-color: white;
}


.Footer_footer__7dIj9 {
  padding: 20px 60px;
  text-align: center;
  color: #424142;
  font-size: 14px;
  background-color: rgba(200, 204, 204, 0.15);
  margin-top: 12px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.WarningBanner_btn__26HUP, .WarningBanner_btnNo__2KEwC, .WarningBanner_btnYes__3XiG2, .WarningBanner_button__2C7fG {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .WarningBanner_btn__26HUP:hover, .WarningBanner_btnNo__2KEwC:hover, .WarningBanner_btnYes__3XiG2:hover, .WarningBanner_button__2C7fG:hover {
    background: #ed2224;
    color: #ffffff; }
  .WarningBanner_btn__26HUP:active, .WarningBanner_btnNo__2KEwC:active, .WarningBanner_btnYes__3XiG2:active, .WarningBanner_button__2C7fG:active {
    background: #DC2426;
    color: #ffffff; }

.WarningBanner_btnNo__2KEwC {
  width: 100px; }

.WarningBanner_btnYes__3XiG2 {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.WarningBanner_statusWpar__3-1WE {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .WarningBanner_statusWpar__3-1WE::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.WarningBanner_statusActive__230B8::before {
  background: #28A181; }

.WarningBanner_statusInActive__1uWut::before {
  background: #c8c8c8; }

.WarningBanner_arrow__B0YRN {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .WarningBanner_arrow__B0YRN:before, .WarningBanner_arrow__B0YRN:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .WarningBanner_arrow__B0YRN:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .WarningBanner_arrow__B0YRN:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.WarningBanner_sectionTitle__1HNJ2 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.WarningBanner_popUpTitle__22LOQ {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.WarningBanner_popUpWrap__VqOra {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.WarningBanner_popUpText__Pyjww {
  text-align: center; }
  .WarningBanner_popUpText__Pyjww:last-child {
    margin-bottom: 0; }

.WarningBanner_visuallyHidden__qqrtc {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.WarningBanner_actionsBtn__r26mM {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .WarningBanner_actionsBtn__r26mM svg {
    color: #ed2224; }
  .WarningBanner_actionsBtn__r26mM:focus {
    outline: none; }

.WarningBanner_actionsBtn__r26mM.WarningBanner_actionsBtnDisabled__eY_yI {
  background: none;
  opacity: 0.4; }

.WarningBanner_blocked__ohNik td,
.WarningBanner_blocked__ohNik span {
  color: #cccccc !important; }

.WarningBanner_blocked__ohNik .ant-checkbox-input:focus + .ant-checkbox-inner,
.WarningBanner_blocked__ohNik .ant-checkbox-inner {
  border-color: #cccccc; }

.WarningBanner_isAdmin__2SC_x td,
.WarningBanner_isAdmin__2SC_x span,
.WarningBanner_isAccountManager__3Aj0_ td,
.WarningBanner_isAccountManager__3Aj0_ span {
  font-weight: bold !important; }

.WarningBanner_ellipsis__1Wpr_ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.WarningBanner_wrap__3ykBG {
  color: #ffffff;
  background-color: #ed2224; }

.WarningBanner_inner__11-Ve {
  min-height: 100%;
  height: 100%;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px; }

.WarningBanner_section__2EDp0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin: 0 auto;
  padding: 15px;
  font-size: 18px;
  font-weight: bold; }

.WarningBanner_sectionDesc__1Xz0w {
  -ms-flex-positive: 1;
      flex-grow: 1; }

.WarningBanner_sectionActions__1VdmI {
  -ms-flex-negative: 0;
      flex-shrink: 0; }

.WarningBanner_button__2C7fG {
  padding: 10px 30px;
  margin-left: 20px;
  color: #ffffff;
  border-color: #ffffff;
  background: transparent; }

.FormInput_inputLabeled__1hv7F {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: #536070;
  font-size: 16px;
  z-index: -1;
  background: transparent;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  cursor: text; }
  .FormInput_inputLabeled__1hv7F.FormInput_fieldActive__1YI4r {
    transform: translateY(-215%);
    font-size: 10px;
    color: #817B7A;
    top: 55%; }

.FormInput_inputTxt__nNUGB {
  display: block;
  width: 100%;
  min-height: 33px;
  border: none;
  background: none;
  color: #424142;
  font-size: 16px;
  line-height: 19px; }
  .FormInput_inputTxt__nNUGB:focus {
    outline: none; }

.FormInput_passwordStyle__3Dwg9.FormInput_inputTxt__nNUGB {
  font-family: password-mask;
  letter-spacing: 1.6px;
  font-size: 16px;
  text-security: disc;
  -webkit-text-security: disc;
  -mox-text-security: disc; }

.FormInput_messageError__3U-b8 {
  position: absolute;
  top: 100%;
  color: #ed2224;
  font-size: 12px;
  min-height: 14px;
  z-index: 20; }

.FormInput_warningIcon__d1QUt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0; }

.FormInput_labelWrapper__1r8NV {
  position: relative;
  margin: 16px 0 45px 0; }
  .FormInput_error__2e-O2.FormInput_labelWrapper__1r8NV {
    margin-bottom: 26px; }

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='number'] {
  -moz-appearance: textfield; }

input[type='password'] {
  font: large Verdana, sans-serif;
  -webkit-appearance: none; }
  input[type='password']::-ms-reveal, input[type='password']::-ms-clear {
    display: none; }

.FormInput_labelWrapper__1r8NV {
  border-bottom: 1px solid #C8CCCC; }

.FormInput_inputWrapper__1v216 {
  position: relative;
  z-index: 5; }
  .FormInput_inputWrapper__1v216 input {
    padding-right: 25px;
    box-sizing: border-box; }

.FormInput_inputActive__1PhPm {
  border-bottom: 1px solid #ed2224; }

.FormInput_inputTxt__nNUGB {
  padding: 5px 0; }
  .FormInput_inputTxt__nNUGB::-ms-clear {
    height: 31px; }

.FormInput_passwordInputWrapper__1Imr7 {
  position: relative; }
  .FormInput_passwordInputWrapper__1Imr7 .FormInput_warningIcon__d1QUt {
    right: 30px; }

.FormInput_viewIconBtn__2IToe {
  padding: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 20px; }

.FormInput_viewPasswordIcon__1jy6J {
  color: #536070;
  font-size: 16px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.AddCompany_btn__3F30D, .AddCompany_btnNo__1DSRo, .AddCompany_btnYes__37aCZ, .AddCompany_btnSave__8fGIX {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .AddCompany_btn__3F30D:hover, .AddCompany_btnNo__1DSRo:hover, .AddCompany_btnYes__37aCZ:hover, .AddCompany_btnSave__8fGIX:hover {
    background: #ed2224;
    color: #ffffff; }
  .AddCompany_btn__3F30D:active, .AddCompany_btnNo__1DSRo:active, .AddCompany_btnYes__37aCZ:active, .AddCompany_btnSave__8fGIX:active {
    background: #DC2426;
    color: #ffffff; }

.AddCompany_btnNo__1DSRo {
  width: 100px; }

.AddCompany_btnYes__37aCZ, .AddCompany_btnSave__8fGIX {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.AddCompany_statusWpar__pAGhK {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .AddCompany_statusWpar__pAGhK::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.AddCompany_statusActive__9RKU8::before {
  background: #28A181; }

.AddCompany_statusInActive__3CK0z::before {
  background: #c8c8c8; }

.AddCompany_arrow__2rxJz {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .AddCompany_arrow__2rxJz:before, .AddCompany_arrow__2rxJz:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .AddCompany_arrow__2rxJz:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .AddCompany_arrow__2rxJz:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.AddCompany_sectionTitle__3KsHH {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.AddCompany_popUpTitle__1JHIv, .AddCompany_title__OD26k {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.AddCompany_popUpWrap__3Iopj, .AddCompany_wrapper__35CIN {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.AddCompany_popUpText__2ptO_ {
  text-align: center; }
  .AddCompany_popUpText__2ptO_:last-child {
    margin-bottom: 0; }

.AddCompany_visuallyHidden__11jwU {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.AddCompany_actionsBtn__3TBR_ {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .AddCompany_actionsBtn__3TBR_ svg {
    color: #ed2224; }
  .AddCompany_actionsBtn__3TBR_:focus {
    outline: none; }

.AddCompany_actionsBtn__3TBR_.AddCompany_actionsBtnDisabled__AHY7b {
  background: none;
  opacity: 0.4; }

.AddCompany_blocked__1LUem td,
.AddCompany_blocked__1LUem span {
  color: #cccccc !important; }

.AddCompany_blocked__1LUem .ant-checkbox-input:focus + .ant-checkbox-inner,
.AddCompany_blocked__1LUem .ant-checkbox-inner {
  border-color: #cccccc; }

.AddCompany_isAdmin__3KT1Z td,
.AddCompany_isAdmin__3KT1Z span,
.AddCompany_isAccountManager__1NzIH td,
.AddCompany_isAccountManager__1NzIH span {
  font-weight: bold !important; }

.AddCompany_ellipsis__3axPr {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.AddCompany_formInput__ppS3Y {
  width: 100%;
  margin: 0 10px 0 0; }
  .AddCompany_formInput__ppS3Y input {
    font-size: 14px; }

.AddCompany_inputSection__263oc, .AddCompany_companySelectHasValue__3zAzf,
.AddCompany_usersSelectHasValue__2iENL,
.AddCompany_datasetHasValues__1idJK {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .AddCompany_inputSection__263oc .ant-select, .AddCompany_companySelectHasValue__3zAzf .ant-select, .AddCompany_usersSelectHasValue__2iENL .ant-select, .AddCompany_datasetHasValues__1idJK .ant-select {
    color: #424142; }
  .AddCompany_inputSection__263oc .ant-select-selection__placeholder, .AddCompany_companySelectHasValue__3zAzf .ant-select-selection__placeholder, .AddCompany_usersSelectHasValue__2iENL .ant-select-selection__placeholder, .AddCompany_datasetHasValues__1idJK .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .AddCompany_inputSection__263oc .ant-select-selection__rendered, .AddCompany_companySelectHasValue__3zAzf .ant-select-selection__rendered, .AddCompany_usersSelectHasValue__2iENL .ant-select-selection__rendered, .AddCompany_datasetHasValues__1idJK .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .AddCompany_inputSection__263oc .ant-select-selection, .AddCompany_companySelectHasValue__3zAzf .ant-select-selection, .AddCompany_usersSelectHasValue__2iENL .ant-select-selection, .AddCompany_datasetHasValues__1idJK .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .AddCompany_inputSection__263oc .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .AddCompany_companySelectHasValue__3zAzf .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .AddCompany_usersSelectHasValue__2iENL .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .AddCompany_datasetHasValues__1idJK .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .AddCompany_inputSection__263oc .ant-select-focused .ant-select-selection:focus, .AddCompany_companySelectHasValue__3zAzf .ant-select-focused .ant-select-selection:focus, .AddCompany_usersSelectHasValue__2iENL .ant-select-focused .ant-select-selection:focus, .AddCompany_datasetHasValues__1idJK .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.AddCompany_addUserPasswordSections__utQaJ {
  margin-bottom: 30px; }

.AddCompany_companySelectHasValue__3zAzf .ant-select-selection__placeholder,
.AddCompany_usersSelectHasValue__2iENL .ant-select-selection__placeholder,
.AddCompany_datasetHasValues__1idJK .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.AddCompany_inputSectionLabel__1G6uQ {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.AddCompany_btnWrap__1KS1l, .AddCompany_btnWrapSingle__3opP0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .AddCompany_btnWrap__1KS1l button, .AddCompany_btnWrapSingle__3opP0 button {
    width: 100%; }

.AddCompany_btnWrapSingle__3opP0 button {
  width: 200px; }

.AddCompany_editBtn__2PqPO {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .AddCompany_editBtn__2PqPO svg {
    font-size: 14px; }
    .AddCompany_editBtn__2PqPO svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.AddCompany_title__OD26k {
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px; }

.AddCompany_titleSection__9WcZP {
  margin: 10px 0 20px 0;
  color: #424142; }

.AddCompany_shareAccessBlock__1lBXC {
  margin-left: 25px; }
  .AddCompany_shareAccessBlock__1lBXC .AddCompany_shareAccessContainer__1g9Mo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .AddCompany_shareAccessBlock__1lBXC .AddCompany_shareAccessContainer__1g9Mo .AddCompany_shareAccessTextContainer__3T1T_ {
      max-width: 700px; }
  .AddCompany_shareAccessBlock__1lBXC .AddCompany_shareAccessDefText__1hP-0 {
    font-size: 1.1rem;
    font-weight: bold;
    max-width: 800px; }
  .AddCompany_shareAccessBlock__1lBXC .AddCompany_shareAccessText__1SStN {
    margin: 0 10px 0 20px; }

.AddCompany_notModal__2vhig {
  display: -ms-flexbox;
  display: flex; }
  .AddCompany_notModal__2vhig .AddCompany_notModalBlock__2f9Zs {
    width: 100%; }

.AddCompany_generalItem__XZpS4 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 5px; }

.AddCompany_sectionWrap__3oLu8 {
  padding: 10px 20px; }

.AddCompany_inputDisabled__3WBmh {
  border: none; }

.AddCompany_inputDisabledNew__2l4Ea {
  width: 200px; }

.AddCompany_modalWrap__1V3qS {
  margin: 0 auto;
  width: 400px !important; }

.AddCompany_modalSection__270I9 {
  padding: 20px;
  text-align: center; }

.AddCompany_btnModalWrap__3M5K5 {
  margin-top: 30px; }

.AddCompany_selectIcon__2ODSF {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #424142 transparent transparent transparent;
  margin-top: 4px; }

.AddCompany_selectDropdown__1_DJK li {
  font-size: 14px;
  padding: 0 10px 5px; }

.AddCompany_selectDropdown__1_DJK .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: #ed2224 !important;
  color: white; }

.AddCompany_selectDropdown__1_DJK .ant-select-dropdown-menu-item-selected {
  background: none !important;
  color: #ed2224; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.AddUser_btn__2MF9Z, .AddUser_btnNo__1E9jd, .AddUser_btnYes__2XilN, .AddUser_btnSave__2WcRF {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .AddUser_btn__2MF9Z:hover, .AddUser_btnNo__1E9jd:hover, .AddUser_btnYes__2XilN:hover, .AddUser_btnSave__2WcRF:hover {
    background: #ed2224;
    color: #ffffff; }
  .AddUser_btn__2MF9Z:active, .AddUser_btnNo__1E9jd:active, .AddUser_btnYes__2XilN:active, .AddUser_btnSave__2WcRF:active {
    background: #DC2426;
    color: #ffffff; }

.AddUser_btnNo__1E9jd {
  width: 100px; }

.AddUser_btnYes__2XilN, .AddUser_btnSave__2WcRF {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.AddUser_statusWpar__3jZUt {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .AddUser_statusWpar__3jZUt::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.AddUser_statusActive__30_kI::before {
  background: #28A181; }

.AddUser_statusInActive__3Ft_K::before {
  background: #c8c8c8; }

.AddUser_arrow__38CR2 {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .AddUser_arrow__38CR2:before, .AddUser_arrow__38CR2:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .AddUser_arrow__38CR2:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .AddUser_arrow__38CR2:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.AddUser_sectionTitle__3Jc24 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.AddUser_popUpTitle__2nr8N, .AddUser_title__19ZxL {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.AddUser_popUpWrap__2exYO, .AddUser_wrapper__2rLuA {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.AddUser_popUpText__2pXQW {
  text-align: center; }
  .AddUser_popUpText__2pXQW:last-child {
    margin-bottom: 0; }

.AddUser_visuallyHidden__2PlPF {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.AddUser_actionsBtn__1_gUs {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .AddUser_actionsBtn__1_gUs svg {
    color: #ed2224; }
  .AddUser_actionsBtn__1_gUs:focus {
    outline: none; }

.AddUser_actionsBtn__1_gUs.AddUser_actionsBtnDisabled__urOCF {
  background: none;
  opacity: 0.4; }

.AddUser_blocked__2TomN td,
.AddUser_blocked__2TomN span {
  color: #cccccc !important; }

.AddUser_blocked__2TomN .ant-checkbox-input:focus + .ant-checkbox-inner,
.AddUser_blocked__2TomN .ant-checkbox-inner {
  border-color: #cccccc; }

.AddUser_isAdmin__1L3-Q td,
.AddUser_isAdmin__1L3-Q span,
.AddUser_isAccountManager__Qoc27 td,
.AddUser_isAccountManager__Qoc27 span {
  font-weight: bold !important; }

.AddUser_ellipsis__f3srS {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.AddUser_formInput__1IWnB {
  width: 100%;
  margin: 0 10px 0 0; }
  .AddUser_formInput__1IWnB input {
    font-size: 14px; }

.AddUser_inputSection__uDLXj, .AddUser_companySelectHasValue__12bmh,
.AddUser_usersSelectHasValue__1fBwY,
.AddUser_datasetHasValues__2u5SM {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .AddUser_inputSection__uDLXj .ant-select, .AddUser_companySelectHasValue__12bmh .ant-select, .AddUser_usersSelectHasValue__1fBwY .ant-select, .AddUser_datasetHasValues__2u5SM .ant-select {
    color: #424142; }
  .AddUser_inputSection__uDLXj .ant-select-selection__placeholder, .AddUser_companySelectHasValue__12bmh .ant-select-selection__placeholder, .AddUser_usersSelectHasValue__1fBwY .ant-select-selection__placeholder, .AddUser_datasetHasValues__2u5SM .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .AddUser_inputSection__uDLXj .ant-select-selection__rendered, .AddUser_companySelectHasValue__12bmh .ant-select-selection__rendered, .AddUser_usersSelectHasValue__1fBwY .ant-select-selection__rendered, .AddUser_datasetHasValues__2u5SM .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .AddUser_inputSection__uDLXj .ant-select-selection, .AddUser_companySelectHasValue__12bmh .ant-select-selection, .AddUser_usersSelectHasValue__1fBwY .ant-select-selection, .AddUser_datasetHasValues__2u5SM .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .AddUser_inputSection__uDLXj .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .AddUser_companySelectHasValue__12bmh .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .AddUser_usersSelectHasValue__1fBwY .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .AddUser_datasetHasValues__2u5SM .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .AddUser_inputSection__uDLXj .ant-select-focused .ant-select-selection:focus, .AddUser_companySelectHasValue__12bmh .ant-select-focused .ant-select-selection:focus, .AddUser_usersSelectHasValue__1fBwY .ant-select-focused .ant-select-selection:focus, .AddUser_datasetHasValues__2u5SM .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.AddUser_addUserPasswordSections__2nYxh {
  margin-bottom: 30px; }

.AddUser_companySelectHasValue__12bmh .ant-select-selection__placeholder,
.AddUser_usersSelectHasValue__1fBwY .ant-select-selection__placeholder,
.AddUser_datasetHasValues__2u5SM .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.AddUser_inputSectionLabel__25Axr {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.AddUser_btnWrap__1ww5c, .AddUser_btnWrapSingle__erqgD {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .AddUser_btnWrap__1ww5c button, .AddUser_btnWrapSingle__erqgD button {
    width: 100%; }

.AddUser_btnWrapSingle__erqgD button {
  width: 200px; }

.AddUser_editBtn__2bBJk, .AddUser_addBtn__2CnQy {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .AddUser_editBtn__2bBJk svg, .AddUser_addBtn__2CnQy svg {
    font-size: 14px; }
    .AddUser_editBtn__2bBJk svg *, .AddUser_addBtn__2CnQy svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.AddUser_wrapper__2rLuA .ant-select-focused .ant-select-selection,
.AddUser_wrapper__2rLuA .ant-select-selection:focus,
.AddUser_wrapper__2rLuA .ant-select-selection:active {
  border: none;
  box-shadow: 0 0 0 2px rgba(237, 34, 36, 0.5); }

.AddUser_wrapper__2rLuA .ant-select-selection:hover {
  box-shadow: none;
  border-color: #C8CCCC; }

.AddUser_wrapper__2rLuA .ant-select-selection--single .ant-select-selection__rendered {
  color: #424142; }

.AddUser_title__19ZxL {
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px; }

.AddUser_titleSection__3KBu3 {
  margin: 10px 0 20px 0;
  color: #424142;
  text-align: center; }

.AddUser_generalItem__Cw5zs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 5px; }

.AddUser_sectionWrap__I5FtA {
  padding: 10px 20px; }

.AddUser_addBtn__2CnQy {
  margin-right: 2px;
  margin-left: 23px; }

.AddUser_changePasswordWrap__P7sAl {
  display: -ms-flexbox;
  display: flex; }

.AddUser_changePassword__XXWTv, .AddUser_changePasswordCancel__irckk {
  display: -ms-flexbox;
  display: flex;
  color: #414042;
  margin: 7px 0 7px auto;
  border-radius: 6px;
  padding: 7px 10px;
  background: none;
  border: 1px solid #ed2224;
  transition: 0.3s;
  cursor: pointer; }

.AddUser_errorText__3QqE_ {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 65px;
  text-align: center;
  font-size: 14px;
  color: #ed2224; }

.AddUser_changePasswordCancel__irckk {
  border: 1px solid #ed2224;
  margin-left: 20px; }

.AddUser_inputDisabled__1bKpl {
  border: none; }

.AddUser_modalWrap__s0Vn3 {
  margin: 0 auto;
  width: 400px !important; }

.AddUser_selectIcon__3e3j6 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #424142 transparent transparent transparent;
  margin-top: 4px; }

.AddUser_cognitiveText__R6phl {
  margin: 0 10px 0 0; }

.ant-switch-checked {
  background: #ed2224 !important; }

.AddUser_companiesDropdown__y0bYa li,
.AddUser_usersRolesDropdown__2dJDh li {
  font-size: 14px;
  padding: 0 10px 5px; }

.AddUser_companiesDropdown__y0bYa .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.AddUser_usersRolesDropdown__2dJDh .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: #ed2224 !important;
  color: white; }

.AddUser_companiesDropdown__y0bYa .ant-select-dropdown-menu-item-selected,
.AddUser_usersRolesDropdown__2dJDh .ant-select-dropdown-menu-item-selected {
  background: none !important;
  color: #ed2224; }

.AddUser_sections__g2Uf1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.AddUser_horizontalSections__1eDDa {
  -ms-flex-direction: row;
      flex-direction: row; }
  .AddUser_horizontalSections__1eDDa > div {
    width: 50%; }

.AddUser_sectionCompanies__2s8T4 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.AddUser_checkboxesWrap__c2G36 {
  position: relative;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.AddUser_checkboxesList__2wzut {
  position: absolute;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto; }
  .AddUser_checkboxesList__2wzut p {
    margin-bottom: 2px; }
  .AddUser_checkboxesList__2wzut > label {
    margin-left: 0 !important; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.AreYouWantToCancel_btn__1DtDr, .AreYouWantToCancel_btnNo__3InjX, .AreYouWantToCancel_btnSaveConfirmModal__251gb, .AreYouWantToCancel_btnYes__2-gLk, .AreYouWantToCancel_btnNoConfirmModal__1EtFd {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .AreYouWantToCancel_btn__1DtDr:hover, .AreYouWantToCancel_btnNo__3InjX:hover, .AreYouWantToCancel_btnSaveConfirmModal__251gb:hover, .AreYouWantToCancel_btnYes__2-gLk:hover, .AreYouWantToCancel_btnNoConfirmModal__1EtFd:hover {
    background: #ed2224;
    color: #ffffff; }
  .AreYouWantToCancel_btn__1DtDr:active, .AreYouWantToCancel_btnNo__3InjX:active, .AreYouWantToCancel_btnSaveConfirmModal__251gb:active, .AreYouWantToCancel_btnYes__2-gLk:active, .AreYouWantToCancel_btnNoConfirmModal__1EtFd:active {
    background: #DC2426;
    color: #ffffff; }

.AreYouWantToCancel_btnNo__3InjX, .AreYouWantToCancel_btnSaveConfirmModal__251gb {
  width: 100px; }

.AreYouWantToCancel_btnYes__2-gLk, .AreYouWantToCancel_btnNoConfirmModal__1EtFd {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.AreYouWantToCancel_statusWpar__2c6Hx {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .AreYouWantToCancel_statusWpar__2c6Hx::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.AreYouWantToCancel_statusActive__1RYoQ::before {
  background: #28A181; }

.AreYouWantToCancel_statusInActive__213tC::before {
  background: #c8c8c8; }

.AreYouWantToCancel_arrow__1dgWa {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .AreYouWantToCancel_arrow__1dgWa:before, .AreYouWantToCancel_arrow__1dgWa:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .AreYouWantToCancel_arrow__1dgWa:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .AreYouWantToCancel_arrow__1dgWa:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.AreYouWantToCancel_sectionTitle__3g2rZ {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.AreYouWantToCancel_popUpTitle__1HRzV {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.AreYouWantToCancel_popUpWrap__1pN4_ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.AreYouWantToCancel_popUpText__1isYu {
  text-align: center; }
  .AreYouWantToCancel_popUpText__1isYu:last-child {
    margin-bottom: 0; }

.AreYouWantToCancel_visuallyHidden__1C8r1 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.AreYouWantToCancel_actionsBtn__3V4Ig {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .AreYouWantToCancel_actionsBtn__3V4Ig svg {
    color: #ed2224; }
  .AreYouWantToCancel_actionsBtn__3V4Ig:focus {
    outline: none; }

.AreYouWantToCancel_actionsBtn__3V4Ig.AreYouWantToCancel_actionsBtnDisabled__1tReY {
  background: none;
  opacity: 0.4; }

.AreYouWantToCancel_blocked__28WsH td,
.AreYouWantToCancel_blocked__28WsH span {
  color: #cccccc !important; }

.AreYouWantToCancel_blocked__28WsH .ant-checkbox-input:focus + .ant-checkbox-inner,
.AreYouWantToCancel_blocked__28WsH .ant-checkbox-inner {
  border-color: #cccccc; }

.AreYouWantToCancel_isAdmin__1v2MV td,
.AreYouWantToCancel_isAdmin__1v2MV span,
.AreYouWantToCancel_isAccountManager__GGUuH td,
.AreYouWantToCancel_isAccountManager__GGUuH span {
  font-weight: bold !important; }

.AreYouWantToCancel_ellipsis__13jL_ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.AreYouWantToCancel_wrapper__AgJ2z {
  padding: 20px;
  text-align: center; }

.AreYouWantToCancel_btnWrap__2wCRk {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px; }

.AreYouWantToCancel_btnSaveConfirmModal__251gb {
  margin-right: 20px; }

.AreYouWantToCancel_btnNoConfirmModal__1EtFd {
  margin-right: 0px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.BlockDeleteMethods_btn__3MQrY, .BlockDeleteMethods_btnNo__1_zoO, .BlockDeleteMethods_btnYes__3xm0K {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .BlockDeleteMethods_btn__3MQrY:hover, .BlockDeleteMethods_btnNo__1_zoO:hover, .BlockDeleteMethods_btnYes__3xm0K:hover {
    background: #ed2224;
    color: #ffffff; }
  .BlockDeleteMethods_btn__3MQrY:active, .BlockDeleteMethods_btnNo__1_zoO:active, .BlockDeleteMethods_btnYes__3xm0K:active {
    background: #DC2426;
    color: #ffffff; }

.BlockDeleteMethods_btnNo__1_zoO {
  width: 100px; }

.BlockDeleteMethods_btnYes__3xm0K {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.BlockDeleteMethods_statusWpar__2jVkV {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .BlockDeleteMethods_statusWpar__2jVkV::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.BlockDeleteMethods_statusActive__3JmPi::before {
  background: #28A181; }

.BlockDeleteMethods_statusInActive__gF2Y0::before {
  background: #c8c8c8; }

.BlockDeleteMethods_arrow__2GubY {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .BlockDeleteMethods_arrow__2GubY:before, .BlockDeleteMethods_arrow__2GubY:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .BlockDeleteMethods_arrow__2GubY:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .BlockDeleteMethods_arrow__2GubY:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.BlockDeleteMethods_sectionTitle__32wXc {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.BlockDeleteMethods_popUpTitle__Y8pHz {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.BlockDeleteMethods_popUpWrap__6Rcpl, .BlockDeleteMethods_wrapper__3DC3H {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.BlockDeleteMethods_popUpText__ORlY3 {
  text-align: center; }
  .BlockDeleteMethods_popUpText__ORlY3:last-child {
    margin-bottom: 0; }

.BlockDeleteMethods_visuallyHidden__1pzS4 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.BlockDeleteMethods_actionsBtn__1MarK {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .BlockDeleteMethods_actionsBtn__1MarK svg {
    color: #ed2224; }
  .BlockDeleteMethods_actionsBtn__1MarK:focus {
    outline: none; }

.BlockDeleteMethods_actionsBtn__1MarK.BlockDeleteMethods_actionsBtnDisabled__3Evee {
  background: none;
  opacity: 0.4; }

.BlockDeleteMethods_blocked__13HOx td,
.BlockDeleteMethods_blocked__13HOx span {
  color: #cccccc !important; }

.BlockDeleteMethods_blocked__13HOx .ant-checkbox-input:focus + .ant-checkbox-inner,
.BlockDeleteMethods_blocked__13HOx .ant-checkbox-inner {
  border-color: #cccccc; }

.BlockDeleteMethods_isAdmin__2ccwT td,
.BlockDeleteMethods_isAdmin__2ccwT span,
.BlockDeleteMethods_isAccountManager__36QVx td,
.BlockDeleteMethods_isAccountManager__36QVx span {
  font-weight: bold !important; }

.BlockDeleteMethods_ellipsis__1JTzY {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.BlockDeleteMethods_btnWrap__1Hgn8 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 25px; }

.BlockDeleteMethods_btnYes__3xm0K {
  margin-right: 0; }

.BlockDeleteMethods_btnNo__1_zoO {
  margin-right: 20px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Edit9Box_btn__3hohn, .Edit9Box_btnNo__QLIon, .Edit9Box_btnYes__6Osfp, .Edit9Box_btnSave__3jlYL, .Edit9Box_btnGet__1UZBu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Edit9Box_btn__3hohn:hover, .Edit9Box_btnNo__QLIon:hover, .Edit9Box_btnYes__6Osfp:hover, .Edit9Box_btnSave__3jlYL:hover, .Edit9Box_btnGet__1UZBu:hover {
    background: #ed2224;
    color: #ffffff; }
  .Edit9Box_btn__3hohn:active, .Edit9Box_btnNo__QLIon:active, .Edit9Box_btnYes__6Osfp:active, .Edit9Box_btnSave__3jlYL:active, .Edit9Box_btnGet__1UZBu:active {
    background: #DC2426;
    color: #ffffff; }

.Edit9Box_btnNo__QLIon {
  width: 100px; }

.Edit9Box_btnYes__6Osfp, .Edit9Box_btnSave__3jlYL {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Edit9Box_statusWpar__1d8Zr {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Edit9Box_statusWpar__1d8Zr::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Edit9Box_statusActive__1Vl2J::before {
  background: #28A181; }

.Edit9Box_statusInActive__1tD6n::before {
  background: #c8c8c8; }

.Edit9Box_arrow__3u309 {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Edit9Box_arrow__3u309:before, .Edit9Box_arrow__3u309:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Edit9Box_arrow__3u309:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Edit9Box_arrow__3u309:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Edit9Box_sectionTitle__MgRW8 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Edit9Box_popUpTitle__3QpCp, .Edit9Box_title__1MhvY {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Edit9Box_popUpWrap__97d1C, .Edit9Box_wrapper__VQDw1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Edit9Box_popUpText__2xxTw {
  text-align: center; }
  .Edit9Box_popUpText__2xxTw:last-child {
    margin-bottom: 0; }

.Edit9Box_visuallyHidden__qc1Vm {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Edit9Box_actionsBtn__3hGHq {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Edit9Box_actionsBtn__3hGHq svg {
    color: #ed2224; }
  .Edit9Box_actionsBtn__3hGHq:focus {
    outline: none; }

.Edit9Box_actionsBtn__3hGHq.Edit9Box_actionsBtnDisabled__13URU {
  background: none;
  opacity: 0.4; }

.Edit9Box_blocked__2PuJ5 td,
.Edit9Box_blocked__2PuJ5 span {
  color: #cccccc !important; }

.Edit9Box_blocked__2PuJ5 .ant-checkbox-input:focus + .ant-checkbox-inner,
.Edit9Box_blocked__2PuJ5 .ant-checkbox-inner {
  border-color: #cccccc; }

.Edit9Box_isAdmin__2abkH td,
.Edit9Box_isAdmin__2abkH span,
.Edit9Box_isAccountManager__70GyS td,
.Edit9Box_isAccountManager__70GyS span {
  font-weight: bold !important; }

.Edit9Box_ellipsis__39bKq {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Edit9Box_formInput__2VH_y {
  width: 100%;
  margin: 0 10px 0 0; }
  .Edit9Box_formInput__2VH_y input {
    font-size: 14px; }

.Edit9Box_inputSection__284kY, .Edit9Box_companySelectHasValue__3g5YK,
.Edit9Box_usersSelectHasValue__hxLJe,
.Edit9Box_datasetHasValues__1M88c {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .Edit9Box_inputSection__284kY .ant-select, .Edit9Box_companySelectHasValue__3g5YK .ant-select, .Edit9Box_usersSelectHasValue__hxLJe .ant-select, .Edit9Box_datasetHasValues__1M88c .ant-select {
    color: #424142; }
  .Edit9Box_inputSection__284kY .ant-select-selection__placeholder, .Edit9Box_companySelectHasValue__3g5YK .ant-select-selection__placeholder, .Edit9Box_usersSelectHasValue__hxLJe .ant-select-selection__placeholder, .Edit9Box_datasetHasValues__1M88c .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .Edit9Box_inputSection__284kY .ant-select-selection__rendered, .Edit9Box_companySelectHasValue__3g5YK .ant-select-selection__rendered, .Edit9Box_usersSelectHasValue__hxLJe .ant-select-selection__rendered, .Edit9Box_datasetHasValues__1M88c .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .Edit9Box_inputSection__284kY .ant-select-selection, .Edit9Box_companySelectHasValue__3g5YK .ant-select-selection, .Edit9Box_usersSelectHasValue__hxLJe .ant-select-selection, .Edit9Box_datasetHasValues__1M88c .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .Edit9Box_inputSection__284kY .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Edit9Box_companySelectHasValue__3g5YK .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Edit9Box_usersSelectHasValue__hxLJe .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Edit9Box_datasetHasValues__1M88c .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .Edit9Box_inputSection__284kY .ant-select-focused .ant-select-selection:focus, .Edit9Box_companySelectHasValue__3g5YK .ant-select-focused .ant-select-selection:focus, .Edit9Box_usersSelectHasValue__hxLJe .ant-select-focused .ant-select-selection:focus, .Edit9Box_datasetHasValues__1M88c .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.Edit9Box_addUserPasswordSections__3Bn_y {
  margin-bottom: 30px; }

.Edit9Box_companySelectHasValue__3g5YK .ant-select-selection__placeholder,
.Edit9Box_usersSelectHasValue__hxLJe .ant-select-selection__placeholder,
.Edit9Box_datasetHasValues__1M88c .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.Edit9Box_inputSectionLabel__2uzXw {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.Edit9Box_btnWrap__2vUXk, .Edit9Box_btnWrapSingle__2RQaA {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .Edit9Box_btnWrap__2vUXk button, .Edit9Box_btnWrapSingle__2RQaA button {
    width: 100%; }

.Edit9Box_btnWrapSingle__2RQaA button {
  width: 200px; }

.Edit9Box_editBtn__3FL3V {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .Edit9Box_editBtn__3FL3V svg {
    font-size: 14px; }
    .Edit9Box_editBtn__3FL3V svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.Edit9Box_title__1MhvY {
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px; }

.Edit9Box_titleSection__2SVcU {
  margin: 10px 0 20px 0;
  color: #424142; }

.Edit9Box_titleSectionBig__2Sqj4 {
  margin: 10px 0 20px 0;
  font-size: 25px;
  color: #424142;
  text-align: center; }

.Edit9Box_generalItem__3Np-P {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 5px; }

.Edit9Box_sectionWrap__BHB-M {
  padding: 5px 0px; }

.Edit9Box_btnGet__1UZBu {
  margin-right: 20px; }

.Edit9Box_inputSectionAdditionalMargins__3rvq3 {
  margin: 35px 10px; }

.Edit9Box_valuesSetting__3pOpX, .Edit9Box_orientationLogic__17uYV {
  display: -ms-flexbox;
  display: flex; }

.Edit9Box_btnsChangeOrientation__1T6Ei {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start; }
  .Edit9Box_btnsChangeOrientation__1T6Ei .Edit9Box_btnWrapper__1rzW3 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    margin: 15px; }
    .Edit9Box_btnsChangeOrientation__1T6Ei .Edit9Box_btnWrapper__1rzW3 .Edit9Box_btnLabel__T7KVM {
      font-size: 1.1rem;
      font-weight: bold; }
    .Edit9Box_btnsChangeOrientation__1T6Ei .Edit9Box_btnWrapper__1rzW3 button {
      margin-left: 10px;
      width: 125px; }
      .Edit9Box_btnsChangeOrientation__1T6Ei .Edit9Box_btnWrapper__1rzW3 button:hover svg {
        fill: white; }

.Edit9Box_orientationMatrix__1GD49 {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  margin: 20px 0;
  width: 100%; }
  .Edit9Box_orientationMatrix__1GD49 .Edit9Box_label1__8GMis {
    position: absolute;
    transform: rotate(-90deg);
    left: -91px;
    top: 65px;
    font-weight: bold;
    width: 100%;
    text-align: center; }
  .Edit9Box_orientationMatrix__1GD49 .Edit9Box_label2__ewF-a {
    position: absolute;
    top: 160px;
    font-weight: bold;
    width: 100%;
    text-align: center; }

.Edit9Box_matrixBlock__3UvMT {
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 auto;
  height: 151px;
  width: 152px;
  border-right: 1px solid red;
  border-bottom: 1px solid red; }
  .Edit9Box_matrixBlock__3UvMT .Edit9Box_block__3CHrO {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    height: 50px;
    width: 50px;
    box-sizing: border-box;
    border-left: 1px solid red;
    border-top: 1px solid red;
    font-size: 12px; }

.Edit9Box_container9boxValues__2JQ2P {
  display: -ms-flexbox;
  display: flex; }

.Edit9Box_buttonsRow__2Nwyq {
  display: -ms-flexbox;
  display: flex;
  margin: 10px 10px;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.Edit9Box_surveysBlock__ZCehr {
  display: -ms-flexbox;
  display: flex;
  margin: 10px 10px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 300px;
  overflow-y: auto; }
  .Edit9Box_surveysBlock__ZCehr .Edit9Box_surveysCheckBox__5WS4z {
    margin: 5px 5px; }

.Edit9Box_noDataBlock__24uL1 {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 200px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .Edit9Box_noDataBlock__24uL1 p {
    font-size: 20px;
    margin: 0; }
  .Edit9Box_noDataBlock__24uL1 svg {
    font-size: 26px;
    margin-right: 10px; }
    .Edit9Box_noDataBlock__24uL1 svg * {
      color: #C8CCCC; }

.Edit9Box_boldText__2e7zJ {
  font-weight: bold;
  font-size: 1.5rem; }
  .Edit9Box_boldText__2e7zJ input {
    font-weight: bold;
    font-size: 1.2rem; }

.Edit9Box_boldSpan__aC2N2 {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8); }

.Edit9Box_inputDisabled__1VUgH {
  border: none; }

.Edit9Box_inputDisabledNew__2QTzC {
  width: 200px; }

.Edit9Box_modalWrap__1K6oJ {
  margin: 0 auto;
  width: 400px !important; }

.Edit9Box_modalSection__1-mX3 {
  padding: 20px;
  text-align: center; }

.Edit9Box_btnModalWrap__3f9NT {
  margin-top: 30px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.RefreshAssessments_btn__2OXa-, .RefreshAssessments_btnNo__3yU1G, .RefreshAssessments_btnYes__23Iex, .RefreshAssessments_refresh__2KB77 .RefreshAssessments_stepsBtn__2ZZ-N {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .RefreshAssessments_btn__2OXa-:hover, .RefreshAssessments_btnNo__3yU1G:hover, .RefreshAssessments_btnYes__23Iex:hover, .RefreshAssessments_refresh__2KB77 .RefreshAssessments_stepsBtn__2ZZ-N:hover {
    background: #ed2224;
    color: #ffffff; }
  .RefreshAssessments_btn__2OXa-:active, .RefreshAssessments_btnNo__3yU1G:active, .RefreshAssessments_btnYes__23Iex:active, .RefreshAssessments_refresh__2KB77 .RefreshAssessments_stepsBtn__2ZZ-N:active {
    background: #DC2426;
    color: #ffffff; }

.RefreshAssessments_btnNo__3yU1G {
  width: 100px; }

.RefreshAssessments_btnYes__23Iex {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.RefreshAssessments_statusWpar__1-GfG {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .RefreshAssessments_statusWpar__1-GfG::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.RefreshAssessments_statusActive__2NMnG::before {
  background: #28A181; }

.RefreshAssessments_statusInActive__3KGVJ::before {
  background: #c8c8c8; }

.RefreshAssessments_arrow__1ZNhn {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .RefreshAssessments_arrow__1ZNhn:before, .RefreshAssessments_arrow__1ZNhn:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .RefreshAssessments_arrow__1ZNhn:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .RefreshAssessments_arrow__1ZNhn:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.RefreshAssessments_sectionTitle__2NQm7 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.RefreshAssessments_popUpTitle__3dNnc {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.RefreshAssessments_popUpWrap__3QlkB {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.RefreshAssessments_popUpText__34JRs {
  text-align: center; }
  .RefreshAssessments_popUpText__34JRs:last-child {
    margin-bottom: 0; }

.RefreshAssessments_visuallyHidden__10DLf {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.RefreshAssessments_actionsBtn__2Xs1u {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .RefreshAssessments_actionsBtn__2Xs1u svg {
    color: #ed2224; }
  .RefreshAssessments_actionsBtn__2Xs1u:focus {
    outline: none; }

.RefreshAssessments_actionsBtn__2Xs1u.RefreshAssessments_actionsBtnDisabled__1FlaL {
  background: none;
  opacity: 0.4; }

.RefreshAssessments_blocked__3GLfV td,
.RefreshAssessments_blocked__3GLfV span {
  color: #cccccc !important; }

.RefreshAssessments_blocked__3GLfV .ant-checkbox-input:focus + .ant-checkbox-inner,
.RefreshAssessments_blocked__3GLfV .ant-checkbox-inner {
  border-color: #cccccc; }

.RefreshAssessments_isAdmin__1bFUx td,
.RefreshAssessments_isAdmin__1bFUx span,
.RefreshAssessments_isAccountManager__2O-ZD td,
.RefreshAssessments_isAccountManager__2O-ZD span {
  font-weight: bold !important; }

.RefreshAssessments_ellipsis__Rv-Q2 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.RefreshAssessments_refresh__2KB77 {
  width: calc(100% - 90px);
  padding: 10px 16px 20px;
  border: 1px solid #C8CCCC;
  border-radius: 4px;
  background: #ffffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  bottom: 15px;
  left: 45px;
  z-index: 1000;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  transition: .1s; }
  .RefreshAssessments_refresh__2KB77 .RefreshAssessments_refreshText__11hQD {
    color: #ed2224;
    font-size: 18px;
    font-weight: bold; }
    .RefreshAssessments_refresh__2KB77 .RefreshAssessments_refreshText__11hQD .RefreshAssessments_refreshImg__TZbuD {
      margin-right: 16px;
      transform: scale(1.35); }
      .RefreshAssessments_refresh__2KB77 .RefreshAssessments_refreshText__11hQD .RefreshAssessments_refreshImg__TZbuD path {
        fill: #ed2224; }
  .RefreshAssessments_refresh__2KB77 .RefreshAssessments_refreshDesc__2ys8u {
    color: #424142;
    font-size: 13px; }
  .RefreshAssessments_refresh__2KB77 .RefreshAssessments_stepsBtn__2ZZ-N {
    padding: 1.5px 7px;
    height: unset;
    width: auto;
    margin: unset;
    position: relative;
    background: #ffffff;
    border: 2px solid #ed2224;
    max-width: 200px; }
  .RefreshAssessments_refresh__2KB77 .RefreshAssessments_refreshTime__1ZYap {
    position: absolute;
    bottom: 3px;
    right: 16px;
    font-size: 10px; }

.RefreshAssessments_menuItem__1WRIL .RefreshAssessments_title__378Yw {
  font-family: inherit;
  font-weight: inherit; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Header_btn__9aKgf, .Header_btnNo__3hHyX, .Header_btnYes__37_1h, .Header_infoWhenShared__1dNj0 .Header_footerButton__IM7Q9, .Header_modalWrap__3Bodp .Header_stepsBtn__j-zKk, .Header_modalWrap__3Bodp .Header_stepsBtnFill__1iwaP, .Header_stepsBtn__j-zKk {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Header_btn__9aKgf:hover, .Header_btnNo__3hHyX:hover, .Header_btnYes__37_1h:hover, .Header_infoWhenShared__1dNj0 .Header_footerButton__IM7Q9:hover, .Header_modalWrap__3Bodp .Header_stepsBtnFill__1iwaP:hover, .Header_stepsBtn__j-zKk:hover {
    background: #ed2224;
    color: #ffffff; }
  .Header_btn__9aKgf:active, .Header_btnNo__3hHyX:active, .Header_btnYes__37_1h:active, .Header_infoWhenShared__1dNj0 .Header_footerButton__IM7Q9:active, .Header_modalWrap__3Bodp .Header_stepsBtnFill__1iwaP:active, .Header_stepsBtn__j-zKk:active {
    background: #DC2426;
    color: #ffffff; }

.Header_btnNo__3hHyX {
  width: 100px; }

.Header_btnYes__37_1h {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Header_statusWpar__3-Qud {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Header_statusWpar__3-Qud::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Header_statusActive__34ocK::before {
  background: #28A181; }

.Header_statusInActive__3ZdxT::before {
  background: #c8c8c8; }

.Header_arrow__37dgV {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Header_arrow__37dgV:before, .Header_arrow__37dgV:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Header_arrow__37dgV:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Header_arrow__37dgV:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Header_sectionTitle__-ov6M {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Header_popUpTitle__1PGlh, .Header_modalWrap__3Bodp .Header_title__3SEjb {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Header_popUpWrap__-ek1t {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Header_popUpText__3LUyg {
  text-align: center; }
  .Header_popUpText__3LUyg:last-child {
    margin-bottom: 0; }

.Header_visuallyHidden__ctsN0 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Header_actionsBtn__3U7tq {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Header_actionsBtn__3U7tq svg {
    color: #ed2224; }
  .Header_actionsBtn__3U7tq:focus {
    outline: none; }

.Header_actionsBtn__3U7tq.Header_actionsBtnDisabled__1dbOl {
  background: none;
  opacity: 0.4; }

.Header_blocked__1INfQ td,
.Header_blocked__1INfQ span {
  color: #cccccc !important; }

.Header_blocked__1INfQ .ant-checkbox-input:focus + .ant-checkbox-inner,
.Header_blocked__1INfQ .ant-checkbox-inner {
  border-color: #cccccc; }

.Header_isAdmin__bXaws td,
.Header_isAdmin__bXaws span,
.Header_isAccountManager__14h-O td,
.Header_isAccountManager__14h-O span {
  font-weight: bold !important; }

.Header_ellipsis__2SnZ3 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Header_formInput__3pPVN {
  width: 100%;
  margin: 0 10px 0 0; }
  .Header_formInput__3pPVN input {
    font-size: 14px; }

.Header_inputSection__dA1tG, .Header_companySelectHasValue__3Ln2s,
.Header_usersSelectHasValue__8Co7J,
.Header_datasetHasValues__29ooL {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .Header_inputSection__dA1tG .ant-select, .Header_companySelectHasValue__3Ln2s .ant-select, .Header_usersSelectHasValue__8Co7J .ant-select, .Header_datasetHasValues__29ooL .ant-select {
    color: #424142; }
  .Header_inputSection__dA1tG .ant-select-selection__placeholder, .Header_companySelectHasValue__3Ln2s .ant-select-selection__placeholder, .Header_usersSelectHasValue__8Co7J .ant-select-selection__placeholder, .Header_datasetHasValues__29ooL .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .Header_inputSection__dA1tG .ant-select-selection__rendered, .Header_companySelectHasValue__3Ln2s .ant-select-selection__rendered, .Header_usersSelectHasValue__8Co7J .ant-select-selection__rendered, .Header_datasetHasValues__29ooL .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .Header_inputSection__dA1tG .ant-select-selection, .Header_companySelectHasValue__3Ln2s .ant-select-selection, .Header_usersSelectHasValue__8Co7J .ant-select-selection, .Header_datasetHasValues__29ooL .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .Header_inputSection__dA1tG .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Header_companySelectHasValue__3Ln2s .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Header_usersSelectHasValue__8Co7J .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Header_datasetHasValues__29ooL .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .Header_inputSection__dA1tG .ant-select-focused .ant-select-selection:focus, .Header_companySelectHasValue__3Ln2s .ant-select-focused .ant-select-selection:focus, .Header_usersSelectHasValue__8Co7J .ant-select-focused .ant-select-selection:focus, .Header_datasetHasValues__29ooL .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.Header_addUserPasswordSections__2F1s_ {
  margin-bottom: 30px; }

.Header_companySelectHasValue__3Ln2s .ant-select-selection__placeholder,
.Header_usersSelectHasValue__8Co7J .ant-select-selection__placeholder,
.Header_datasetHasValues__29ooL .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.Header_inputSectionLabel__B4CZS {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.Header_btnWrap__gVdh9, .Header_btnWrapSingle__3TlMl {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .Header_btnWrap__gVdh9 button, .Header_btnWrapSingle__3TlMl button {
    width: 100%; }

.Header_btnWrapSingle__3TlMl button {
  width: 200px; }

.Header_editBtn__3sBq- {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .Header_editBtn__3sBq- svg {
    font-size: 14px; }
    .Header_editBtn__3sBq- svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  height: 100%;
  margin-bottom: 10px;
  padding: 0 60px;
  background: #ffffff;
  z-index: 200; }
  header .Header_refresh__2uOm7 {
    width: 100%;
    padding: 10px 16px 20px;
    border: 1px solid #C8CCCC;
    border-radius: 4px;
    background: #ffffff;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    position: relative; }
    header .Header_refresh__2uOm7 .Header_refreshText__2x97L {
      color: #ed2224;
      font-size: 18px;
      font-weight: bold; }
      header .Header_refresh__2uOm7 .Header_refreshText__2x97L .Header_refreshImg__2BQaC {
        margin-right: 16px;
        transform: scale(1.35); }
        header .Header_refresh__2uOm7 .Header_refreshText__2x97L .Header_refreshImg__2BQaC path {
          fill: #ed2224; }
    header .Header_refresh__2uOm7 .Header_refreshDesc__14tJt {
      color: #424142;
      font-size: 13px; }
    header .Header_refresh__2uOm7 .Header_stepsBtn__j-zKk {
      padding: 1.5px 7px;
      height: unset;
      width: auto;
      margin: unset; }
    header .Header_refresh__2uOm7 .Header_refreshTime__2TfjH {
      position: absolute;
      bottom: 3px;
      right: 16px;
      font-size: 10px; }

.Header_infoWhenShared__1dNj0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: baseline;
      align-items: baseline;
  width: calc(100% - 120px);
  height: 75px;
  border: 1px solid #ff0e0e;
  margin: 0 auto 10px;
  border-radius: 4px;
  background: #eecece;
  font-weight: bold;
  font-size: 1.2rem;
  max-width: 1920px; }
  .Header_infoWhenShared__1dNj0 .Header_companyName__2mwKl {
    margin-left: 15px; }
  .Header_infoWhenShared__1dNj0 .Header_footerButton__IM7Q9 {
    margin: 15px;
    padding: 11px 30px;
    border-radius: 4px; }
    .Header_infoWhenShared__1dNj0 .Header_footerButton__IM7Q9 .Header_centeredText__1k2ev {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
    .Header_infoWhenShared__1dNj0 .Header_footerButton__IM7Q9:hover .Header_buttonImg__26Nz9 path {
      fill: #ffffff; }

.Header_logo__3T0lc {
  margin-right: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .Header_logo__3T0lc span {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: #424142; }

nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: end;
      justify-content: end; }

.Header_links__3Aw6q {
  display: -ms-flexbox;
  display: flex; }

.Header_link__1uLZ- {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1.21333px;
  color: #424142;
  text-decoration: none;
  margin-right: 50px;
  text-transform: uppercase;
  padding: 5px 0;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  cursor: pointer;
  transition: color 0.3s; }
  .Header_link__1uLZ-:hover {
    color: #ed2224; }

.Header_linkMenu__1vQ7U {
  margin-right: 0px;
  width: 100%;
  text-align: left; }

.Header_logout__28K7C {
  background: none;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer; }

.Header_linkActive__2CVGa {
  color: #ed2224; }

.ant-dropdown-menu-item > a {
  display: inline-block; }

.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: transparent; }

.Header_modalWrap__3Bodp {
  width: 500px !important; }
  .Header_modalWrap__3Bodp .Header_wrapper__3tGRg {
    height: 600px;
    padding: 25px; }
  .Header_modalWrap__3Bodp .Header_title__3SEjb {
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 30px; }
  .Header_modalWrap__3Bodp .Header_innerWrap__3Bgp8 {
    height: 100%;
    width: 100%; }
  .Header_modalWrap__3Bodp .Header_content__3z1OQ {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: start;
        align-items: flex-start;
    max-height: 600px;
    width: 100%;
    padding: 25px 35px; }
    .Header_modalWrap__3Bodp .Header_content__3z1OQ .Header_text__IkRmy {
      margin: 15px 10px 20px 10px;
      font-size: 10px; }
    .Header_modalWrap__3Bodp .Header_content__3z1OQ .ant-input:focus {
      border-color: #ed2224;
      --antd-wave-shadow-color: $mainBtn;
      box-shadow: 0 0 0 2px #e76566; }
    .Header_modalWrap__3Bodp .Header_content__3z1OQ .ant-input:hover {
      border-color: #ed2224; }
  .Header_modalWrap__3Bodp .Header_btnWrapper__3DebW {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%; }
  .Header_modalWrap__3Bodp .Header_stepsBtn__j-zKk {
    position: relative;
    background: #ffffff;
    border: 2px solid #ed2224;
    max-width: 200px;
    width: 100%;
    margin: auto 10px 0 10px;
    height: 40px; }
  .Header_modalWrap__3Bodp .Header_stepsBtnFill__1iwaP {
    position: relative;
    background: #ed2224;
    border: 2px solid #ed2224;
    max-width: 200px;
    width: 100%;
    margin: auto 10px 0 10px;
    height: 40px;
    color: #ffffff; }
  .Header_modalWrap__3Bodp .Header_pointer__3a8QX {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    height: 30px;
    cursor: pointer; }
  .Header_modalWrap__3Bodp .Header_noWrap__1b1Yc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .Header_modalWrap__3Bodp .Header_textQuestion__1zfAs {
    margin: 0 10px 20px 10px;
    font-size: 16px; }
  .Header_modalWrap__3Bodp .Header_iconPencil__cErCq {
    position: absolute;
    height: 10px;
    width: 10px;
    top: -5px;
    left: 73px; }
  .Header_modalWrap__3Bodp .Header_link__1uLZ- {
    font-weight: bold;
    color: #2944ae;
    text-decoration: underline; }
  .Header_modalWrap__3Bodp .Header_errorText__2tp2j {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 65px;
    text-align: center;
    font-size: 14px;
    color: #ed2224; }
  .Header_modalWrap__3Bodp .Header_subTitle__2SvDT {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    text-align: left;
    -ms-flex-item-align: start;
        align-self: flex-start; }
    .Header_modalWrap__3Bodp .Header_subTitle__2SvDT ul {
      margin-left: 20px; }

.Header_stepsBtn__j-zKk {
  position: relative;
  background: #ffffff;
  border: 2px solid #ed2224;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px; }

.Header_wrapper__3tGRg {
  max-width: 335px;
  width: 100%; }

.Header_wrapperTitle__17pRj {
  margin-bottom: 30px !important; }

.Header_menuIcon__2sryw {
  margin-left: 6px; }

.Header_radio__2kizU {
  margin: 0 5px; }

.Header_radioWrap__1Pf1S {
  margin-bottom: 30px; }

.Header_radioItemWrap__1lJ-T {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .Header_radioItemWrap__1lJ-T span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 180%;
    color: rgba(0, 0, 0, 0.75); }
    .Header_radioItemWrap__1lJ-T span::first-letter {
      text-transform: uppercase; }

.Header_logoWrapper__zM9x7 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: baseline;
      align-items: baseline; }

.Header_logoCopyright__9iYbH {
  margin-top: 5px;
  color: #414042;
  font-size: 10px; }

.IconButton_iconButton__2XbtX {
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  padding: 0; }

.IconButton_iconButton__2XbtX:focus {
  outline: 0; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.HelpPopUp_btn__1CYTC, .HelpPopUp_btnNo__2RPW_, .HelpPopUp_btnYes__302gm, .HelpPopUp_emailUs__3L1Q9 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .HelpPopUp_btn__1CYTC:hover, .HelpPopUp_btnNo__2RPW_:hover, .HelpPopUp_btnYes__302gm:hover, .HelpPopUp_emailUs__3L1Q9:hover {
    background: #ed2224;
    color: #ffffff; }
  .HelpPopUp_btn__1CYTC:active, .HelpPopUp_btnNo__2RPW_:active, .HelpPopUp_btnYes__302gm:active, .HelpPopUp_emailUs__3L1Q9:active {
    background: #DC2426;
    color: #ffffff; }

.HelpPopUp_btnNo__2RPW_ {
  width: 100px; }

.HelpPopUp_btnYes__302gm, .HelpPopUp_emailUs__3L1Q9 {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.HelpPopUp_statusWpar__JlzCo {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .HelpPopUp_statusWpar__JlzCo::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.HelpPopUp_statusActive__VPXQ-::before {
  background: #28A181; }

.HelpPopUp_statusInActive__XG1Jo::before {
  background: #c8c8c8; }

.HelpPopUp_arrow__1ofu8 {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .HelpPopUp_arrow__1ofu8:before, .HelpPopUp_arrow__1ofu8:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .HelpPopUp_arrow__1ofu8:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .HelpPopUp_arrow__1ofu8:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.HelpPopUp_sectionTitle__Uugtm {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.HelpPopUp_popUpTitle__2Cv4y, .HelpPopUp_title__1aR1e {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.HelpPopUp_popUpWrap__10u8q, .HelpPopUp_wrapper__2S8oA {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.HelpPopUp_popUpText__2bVX_ {
  text-align: center; }
  .HelpPopUp_popUpText__2bVX_:last-child {
    margin-bottom: 0; }

.HelpPopUp_visuallyHidden__uJxLS {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.HelpPopUp_actionsBtn__1J1Xl {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .HelpPopUp_actionsBtn__1J1Xl svg {
    color: #ed2224; }
  .HelpPopUp_actionsBtn__1J1Xl:focus {
    outline: none; }

.HelpPopUp_actionsBtn__1J1Xl.HelpPopUp_actionsBtnDisabled__36SJG {
  background: none;
  opacity: 0.4; }

.HelpPopUp_blocked__3IrcO td,
.HelpPopUp_blocked__3IrcO span {
  color: #cccccc !important; }

.HelpPopUp_blocked__3IrcO .ant-checkbox-input:focus + .ant-checkbox-inner,
.HelpPopUp_blocked__3IrcO .ant-checkbox-inner {
  border-color: #cccccc; }

.HelpPopUp_isAdmin__13kx_ td,
.HelpPopUp_isAdmin__13kx_ span,
.HelpPopUp_isAccountManager__3Yh0m td,
.HelpPopUp_isAccountManager__3Yh0m span {
  font-weight: bold !important; }

.HelpPopUp_ellipsis__oIrsR {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.HelpPopUp_formInput__1KB5j {
  width: 100%;
  margin: 0 10px 0 0; }
  .HelpPopUp_formInput__1KB5j input {
    font-size: 14px; }

.HelpPopUp_inputSection__3H1Ng, .HelpPopUp_companySelectHasValue__2p0Qn,
.HelpPopUp_usersSelectHasValue__30nFK,
.HelpPopUp_datasetHasValues__1ICjo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .HelpPopUp_inputSection__3H1Ng .ant-select, .HelpPopUp_companySelectHasValue__2p0Qn .ant-select, .HelpPopUp_usersSelectHasValue__30nFK .ant-select, .HelpPopUp_datasetHasValues__1ICjo .ant-select {
    color: #424142; }
  .HelpPopUp_inputSection__3H1Ng .ant-select-selection__placeholder, .HelpPopUp_companySelectHasValue__2p0Qn .ant-select-selection__placeholder, .HelpPopUp_usersSelectHasValue__30nFK .ant-select-selection__placeholder, .HelpPopUp_datasetHasValues__1ICjo .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .HelpPopUp_inputSection__3H1Ng .ant-select-selection__rendered, .HelpPopUp_companySelectHasValue__2p0Qn .ant-select-selection__rendered, .HelpPopUp_usersSelectHasValue__30nFK .ant-select-selection__rendered, .HelpPopUp_datasetHasValues__1ICjo .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .HelpPopUp_inputSection__3H1Ng .ant-select-selection, .HelpPopUp_companySelectHasValue__2p0Qn .ant-select-selection, .HelpPopUp_usersSelectHasValue__30nFK .ant-select-selection, .HelpPopUp_datasetHasValues__1ICjo .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .HelpPopUp_inputSection__3H1Ng .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .HelpPopUp_companySelectHasValue__2p0Qn .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .HelpPopUp_usersSelectHasValue__30nFK .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .HelpPopUp_datasetHasValues__1ICjo .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .HelpPopUp_inputSection__3H1Ng .ant-select-focused .ant-select-selection:focus, .HelpPopUp_companySelectHasValue__2p0Qn .ant-select-focused .ant-select-selection:focus, .HelpPopUp_usersSelectHasValue__30nFK .ant-select-focused .ant-select-selection:focus, .HelpPopUp_datasetHasValues__1ICjo .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.HelpPopUp_addUserPasswordSections__1CIul {
  margin-bottom: 30px; }

.HelpPopUp_companySelectHasValue__2p0Qn .ant-select-selection__placeholder,
.HelpPopUp_usersSelectHasValue__30nFK .ant-select-selection__placeholder,
.HelpPopUp_datasetHasValues__1ICjo .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.HelpPopUp_inputSectionLabel___-1FH {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.HelpPopUp_btnWrap__3uKoD, .HelpPopUp_btnWrapSingle__2a73-, .HelpPopUp_btnEmailWrap__3dxx5 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .HelpPopUp_btnWrap__3uKoD button, .HelpPopUp_btnWrapSingle__2a73- button, .HelpPopUp_btnEmailWrap__3dxx5 button {
    width: 100%; }

.HelpPopUp_btnWrapSingle__2a73- button {
  width: 200px; }

.HelpPopUp_editBtn__3s-ri {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .HelpPopUp_editBtn__3s-ri svg {
    font-size: 14px; }
    .HelpPopUp_editBtn__3s-ri svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.HelpPopUp_wrapper__2S8oA {
  padding: 25px 35px 0 35px; }

.HelpPopUp_title__1aR1e {
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px; }

.HelpPopUp_logo__xmQOc {
  margin-bottom: 20px; }

.HelpPopUp_generalItem__1II4r {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 5px; }

.HelpPopUp_sectionWrap__2zERd {
  padding: 10px 0px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }
  .HelpPopUp_sectionWrap__2zERd p {
    margin: 0;
    text-align: center;
    font-size: 16px; }
    .HelpPopUp_sectionWrap__2zERd p span {
      font-weight: bold; }

.HelpPopUp_modalWrap__gGf5q {
  margin: 0 auto;
  width: 400px !important; }

.HelpPopUp_btnWrap__3uKoD, .HelpPopUp_btnWrapSingle__2a73-, .HelpPopUp_btnEmailWrap__3dxx5 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 20px 50px;
  border-top: 1px solid #F2F2F2; }
  .HelpPopUp_btnWrap__3uKoD button, .HelpPopUp_btnWrapSingle__2a73- button, .HelpPopUp_btnEmailWrap__3dxx5 button {
    width: 30%;
    margin: 0; }

.HelpPopUp_emailUs__3L1Q9 {
  background: #ed2224;
  color: #ffffff; }

.HelpPopUp_btnEmailWrap__3dxx5 {
  margin-top: 0;
  padding: 30px 50px;
  border-top: none; }

.Layout_wrapper__2dOTA {
  min-height: 100%;
  margin: 0 auto;
  background: #ffffff; }

.Layout_inner__3LF0- {
  min-height: 100%;
  height: 100%;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px; }

.Layout_header__1V-LV {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 100px; }

.Layout_container__1qghn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.Loader_wrapper__3hasz {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  background: #ffffff;
  z-index: 10000; }

.Loader_loader__2hZcM,
.Loader_loader__2hZcM:before,
.Loader_loader__2hZcM:after {
  background: #ed2224;
  -webkit-animation: Loader_loading__21jB7 1s infinite ease-in-out;
          animation: Loader_loading__21jB7 1s infinite ease-in-out;
  width: 1em;
  height: 4em; }

.Loader_loader__2hZcM {
  color: #ed2224;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s; }
  .Loader_loader__2hZcM:before, .Loader_loader__2hZcM:after {
    position: absolute;
    top: 0;
    content: ''; }
  .Loader_loader__2hZcM:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s; }
  .Loader_loader__2hZcM:after {
    left: 1.5em; }

@-webkit-keyframes Loader_loading__21jB7 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes Loader_loading__21jB7 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

.LoaderMini_wrapper__3gyWd {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  opacity: 0.8;
  transform: translate(-50%, -50%);
  background: #ffffff;
  z-index: 10000; }

.LoaderMini_loader__3EW1C,
.LoaderMini_loader__3EW1C:before,
.LoaderMini_loader__3EW1C:after {
  background: #ed2224;
  -webkit-animation: LoaderMini_loading__2DEm5 1s infinite ease-in-out;
          animation: LoaderMini_loading__2DEm5 1s infinite ease-in-out;
  width: 1em;
  height: 4em; }

.LoaderMini_loader__3EW1C {
  color: #ed2224;
  position: relative;
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
  opacity: 0;
  visibility: hidden; }
  .LoaderMini_loader__3EW1C:before, .LoaderMini_loader__3EW1C:after {
    position: absolute;
    top: 0;
    content: ''; }
  .LoaderMini_loader__3EW1C:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s; }
  .LoaderMini_loader__3EW1C:after {
    left: 1.5em; }

.LoaderMini_visible__1bKd2 {
  opacity: 1;
  visibility: visible; }

@-webkit-keyframes LoaderMini_loading__2DEm5 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

@keyframes LoaderMini_loading__2DEm5 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em; }
  40% {
    box-shadow: 0 -2em;
    height: 5em; } }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.FiltersCollapse_btn__L6RUd, .FiltersCollapse_btnNo__211KT, .FiltersCollapse_btnYes__38GBX, .FiltersCollapse_stepsBtn__wM9zU {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .FiltersCollapse_btn__L6RUd:hover, .FiltersCollapse_btnNo__211KT:hover, .FiltersCollapse_btnYes__38GBX:hover, .FiltersCollapse_stepsBtn__wM9zU:hover {
    background: #ed2224;
    color: #ffffff; }
  .FiltersCollapse_btn__L6RUd:active, .FiltersCollapse_btnNo__211KT:active, .FiltersCollapse_btnYes__38GBX:active, .FiltersCollapse_stepsBtn__wM9zU:active {
    background: #DC2426;
    color: #ffffff; }

.FiltersCollapse_btnNo__211KT {
  width: 100px; }

.FiltersCollapse_btnYes__38GBX {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.FiltersCollapse_statusWpar__do8Up {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .FiltersCollapse_statusWpar__do8Up::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.FiltersCollapse_statusActive__2zoHt::before {
  background: #28A181; }

.FiltersCollapse_statusInActive__1BGC2::before {
  background: #c8c8c8; }

.FiltersCollapse_arrow__1esba, .FiltersCollapse_collapseArrow__3Pp8v {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .FiltersCollapse_arrow__1esba:before, .FiltersCollapse_collapseArrow__3Pp8v:before, .FiltersCollapse_arrow__1esba:after, .FiltersCollapse_collapseArrow__3Pp8v:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .FiltersCollapse_arrow__1esba:before, .FiltersCollapse_collapseArrow__3Pp8v:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .FiltersCollapse_arrow__1esba:after, .FiltersCollapse_collapseArrow__3Pp8v:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.FiltersCollapse_sectionTitle__3n_qm {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.FiltersCollapse_popUpTitle__3TI5V {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.FiltersCollapse_popUpWrap__2MUbG {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.FiltersCollapse_popUpText__29LKp {
  text-align: center; }
  .FiltersCollapse_popUpText__29LKp:last-child {
    margin-bottom: 0; }

.FiltersCollapse_visuallyHidden__2p5X4 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.FiltersCollapse_actionsBtn__3EBMw {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .FiltersCollapse_actionsBtn__3EBMw svg {
    color: #ed2224; }
  .FiltersCollapse_actionsBtn__3EBMw:focus {
    outline: none; }

.FiltersCollapse_actionsBtn__3EBMw.FiltersCollapse_actionsBtnDisabled__13XQL {
  background: none;
  opacity: 0.4; }

.FiltersCollapse_blocked__KMV_H td,
.FiltersCollapse_blocked__KMV_H span {
  color: #cccccc !important; }

.FiltersCollapse_blocked__KMV_H .ant-checkbox-input:focus + .ant-checkbox-inner,
.FiltersCollapse_blocked__KMV_H .ant-checkbox-inner {
  border-color: #cccccc; }

.FiltersCollapse_isAdmin__1_OJ3 td,
.FiltersCollapse_isAdmin__1_OJ3 span,
.FiltersCollapse_isAccountManager__11lIV td,
.FiltersCollapse_isAccountManager__11lIV span {
  font-weight: bold !important; }

.FiltersCollapse_ellipsis__34H0b {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.FiltersCollapse_wrapper__1ELLY {
  /*min-height: 100vh;*/
  width: 100%;
  margin: 0 auto 0; }
  .FiltersCollapse_wrapper__1ELLY * {
    font-family: 'Roboto', sans-serif; }
  .FiltersCollapse_wrapper__1ELLY .ant-collapse {
    border: 1px solid rgba(46, 56, 77, 0.2); }
    .FiltersCollapse_wrapper__1ELLY .ant-collapse-header {
      background: #ffffff;
      letter-spacing: 1.21333px;
      text-transform: uppercase;
      color: #536070;
      font-size: 16px;
      line-height: 19px;
      padding: 30px !important;
      padding-right: 45px !important;
      border-radius: 4px !important; }
      .FiltersCollapse_wrapper__1ELLY .ant-collapse-header i {
        width: 16px;
        right: 30px !important; }
      .FiltersCollapse_wrapper__1ELLY .ant-collapse-header svg {
        color: red;
        width: 100%;
        height: 100%; }
    .FiltersCollapse_wrapper__1ELLY .ant-collapse-content {
      border-top: none; }
      .FiltersCollapse_wrapper__1ELLY .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 30px 10px 30px; }
    .FiltersCollapse_wrapper__1ELLY .ant-collapse-item {
      border: none; }
    .FiltersCollapse_wrapper__1ELLY .ant-collapse-item-active span.ant-collapse-arrow {
      transform: rotate(90deg) !important; }

.FiltersCollapse_collapseWrap__1ItB8 {
  margin-bottom: 8px; }

.FiltersCollapse_collapseWrapOnlyView__1mUOe {
  margin-bottom: 35px;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 4px; }
  .FiltersCollapse_collapseWrapOnlyView__1mUOe :focus {
    outline: none; }

.FiltersCollapse_collapseArrow__3Pp8v {
  top: 44% !important;
  right: 30px !important; }
  .FiltersCollapse_collapseArrow__3Pp8v:before, .FiltersCollapse_collapseArrow__3Pp8v:after {
    display: -ms-flexbox !important;
    display: flex !important; }

.FiltersCollapse_modalWrap__3lJ10 {
  width: 350px !important; }
  .FiltersCollapse_modalWrap__3lJ10 .FiltersCollapse_wrapper__1ELLY {
    height: 200px;
    padding: 25px; }

.FiltersCollapse_innerWrap__3dc6X {
  height: 100%;
  width: 100%; }

.FiltersCollapse_content__5fy9G {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  height: 200px;
  width: 100%;
  padding: 35px 35px 15px 35px; }

.FiltersCollapse_btnWrapper__3TcfJ {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px; }

.FiltersCollapse_stepsBtn__wM9zU {
  position: relative;
  background: #ffffff;
  border: 2px solid #ed2224;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px; }
  .FiltersCollapse_stepsBtn__wM9zU:first-child {
    margin-left: 0; }
  .FiltersCollapse_stepsBtn__wM9zU:last-child {
    margin-right: 0; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.DashboardActions_btn__3vEH9, .DashboardActions_btnNo__yJ7nb, .DashboardActions_btnYes__3IMc9 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .DashboardActions_btn__3vEH9:hover, .DashboardActions_btnNo__yJ7nb:hover, .DashboardActions_btnYes__3IMc9:hover {
    background: #ed2224;
    color: #ffffff; }
  .DashboardActions_btn__3vEH9:active, .DashboardActions_btnNo__yJ7nb:active, .DashboardActions_btnYes__3IMc9:active {
    background: #DC2426;
    color: #ffffff; }

.DashboardActions_btnNo__yJ7nb {
  width: 100px; }

.DashboardActions_btnYes__3IMc9 {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.DashboardActions_statusWpar__3wwRc {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .DashboardActions_statusWpar__3wwRc::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.DashboardActions_statusActive__18vF-::before {
  background: #28A181; }

.DashboardActions_statusInActive__1RotF::before {
  background: #c8c8c8; }

.DashboardActions_arrow__2RkkZ {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .DashboardActions_arrow__2RkkZ:before, .DashboardActions_arrow__2RkkZ:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .DashboardActions_arrow__2RkkZ:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .DashboardActions_arrow__2RkkZ:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.DashboardActions_sectionTitle__3t70d {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.DashboardActions_popUpTitle__3L4LN {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.DashboardActions_popUpWrap__HAaDU {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.DashboardActions_popUpText__7x4B0 {
  text-align: center; }
  .DashboardActions_popUpText__7x4B0:last-child {
    margin-bottom: 0; }

.DashboardActions_visuallyHidden__2au25 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.DashboardActions_actionsBtn__4DSuX {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .DashboardActions_actionsBtn__4DSuX svg {
    color: #ed2224; }
  .DashboardActions_actionsBtn__4DSuX:focus {
    outline: none; }

.DashboardActions_actionsBtn__4DSuX.DashboardActions_actionsBtnDisabled__xzG3u {
  background: none;
  opacity: 0.4; }

.DashboardActions_blocked__o5CFN td,
.DashboardActions_blocked__o5CFN span {
  color: #cccccc !important; }

.DashboardActions_blocked__o5CFN .ant-checkbox-input:focus + .ant-checkbox-inner,
.DashboardActions_blocked__o5CFN .ant-checkbox-inner {
  border-color: #cccccc; }

.DashboardActions_isAdmin__e4UYR td,
.DashboardActions_isAdmin__e4UYR span,
.DashboardActions_isAccountManager__1kTC3 td,
.DashboardActions_isAccountManager__1kTC3 span {
  font-weight: bold !important; }

.DashboardActions_ellipsis__1LkIl {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.DashboardActions_wrapper__3vJIt {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.DashboardActions_titleWrapper__3RoJV {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  margin-right: 35px; }

.DashboardActions_actionsWrapper__2ZU_u {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 100%;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center; }

.DashboardActions_dashboardType__1x_Qo {
  font-size: 12px;
  margin-right: 10px;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 500; }

.DashboardActions_editBtn__3CEZj {
  width: 10px;
  height: 11px;
  margin-left: 5px;
  margin-top: 4px;
  display: -ms-flexbox;
  display: flex;
  background: none;
  border: none;
  cursor: pointer; }
  .DashboardActions_editBtn__3CEZj svg * {
    color: #ed2224; }
  .DashboardActions_editBtn__3CEZj:disabled {
    border: none; }
    .DashboardActions_editBtn__3CEZj:disabled svg * {
      color: #ABB1B9; }

.DashboardActions_tooltipOnButtonLink__1ojMX {
  width: 180px; }

.DashboardActions_actionsImg__1mBCd {
  margin-right: 5px;
  width: 15px !important; }

.DashboardActions_trashIcon__1r4ct {
  width: 15px; }

.DashboardActions_actionsBtn__4DSuX {
  border: red;
  font-size: 9px; }

.DashboardType_wrapper__hxur_,
.DashboardType_radioWrapper__uCTQA {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.DashboardType_radioItem__2qLuw {
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; }

.DashboardType_radioItem__2qLuw span {
  font-size: 12px;
  color: #2E384D; }

.DashboardType_title__lXIrf {
  color: #2E384D;
  font-size: 15px;
  text-transform: capitalize;
  margin-right: 15px; }

.ShowBlocks_itemTitle__1lxbQ {
  display: block;
  color: #2E384D;
  font-size: 15px;
  margin-bottom: 10px; }

.ShowBlocks_checkboxes__1ooWs span {
  color: #536070;
  font-size: 12px; }

.ShowBlocks_checkbox__iFHYy {
  margin-left: 0 !important;
  margin-right: 10px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.DataSet_btn__9s2kY, .DataSet_btnNo__2qog5, .DataSet_btnYes__3Bqav, .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 .DataSet_badgesItem__39chj {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .DataSet_btn__9s2kY:hover, .DataSet_btnNo__2qog5:hover, .DataSet_btnYes__3Bqav:hover, .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 .DataSet_badgesItem__39chj:hover {
    background: #ed2224;
    color: #ffffff; }
  .DataSet_btn__9s2kY:active, .DataSet_btnNo__2qog5:active, .DataSet_btnYes__3Bqav:active, .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 .DataSet_badgesItem__39chj:active {
    background: #DC2426;
    color: #ffffff; }

.DataSet_btnNo__2qog5 {
  width: 100px; }

.DataSet_btnYes__3Bqav {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.DataSet_statusWpar__3NjBS {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .DataSet_statusWpar__3NjBS::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.DataSet_statusActive__3J7Rz::before {
  background: #28A181; }

.DataSet_statusInActive__2C1Cp::before {
  background: #c8c8c8; }

.DataSet_arrow__3DTKk {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .DataSet_arrow__3DTKk:before, .DataSet_arrow__3DTKk:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .DataSet_arrow__3DTKk:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .DataSet_arrow__3DTKk:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.DataSet_sectionTitle__nvEMf {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.DataSet_popUpTitle__2JiN6 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.DataSet_popUpWrap__3kvGZ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.DataSet_popUpText__1Ex02 {
  text-align: center; }
  .DataSet_popUpText__1Ex02:last-child {
    margin-bottom: 0; }

.DataSet_visuallyHidden__-8v5_ {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.DataSet_actionsBtn__1qgpb {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .DataSet_actionsBtn__1qgpb svg {
    color: #ed2224; }
  .DataSet_actionsBtn__1qgpb:focus {
    outline: none; }

.DataSet_actionsBtn__1qgpb.DataSet_actionsBtnDisabled__1U1qf {
  background: none;
  opacity: 0.4; }

.DataSet_blocked__1Kd3B td,
.DataSet_blocked__1Kd3B span {
  color: #cccccc !important; }

.DataSet_blocked__1Kd3B .ant-checkbox-input:focus + .ant-checkbox-inner,
.DataSet_blocked__1Kd3B .ant-checkbox-inner {
  border-color: #cccccc; }

.DataSet_isAdmin__2iRca td,
.DataSet_isAdmin__2iRca span,
.DataSet_isAccountManager__3Sssb td,
.DataSet_isAccountManager__3Sssb span {
  font-weight: bold !important; }

.DataSet_ellipsis__3ON8_, .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 .DataSet_badgesItem__39chj {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

/*@import '../../../../styles/popUpForms';*/
.DataSet_badgesWrap__372FT {
  padding: 11px 0 0; }
  .DataSet_badgesWrap__372FT .DataSet_badgesTitle__kw_RH {
    font-size: 15px;
    line-height: 22px;
    color: #2E384D;
    margin-bottom: 11px; }
  .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    /*margin-right: -45px;*/
    /*overflow: hidden;
    transition: .2s;*/ }
    .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 .DataSet_badgesItem__39chj {
      display: block;
      border-color: #414042;
      margin-right: 45px;
      margin-bottom: 22.5px;
      width: 100px;
      padding: 8px;
      font-size: 14px;
      line-height: 1;
      transition: .2s; }
      .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 .DataSet_badgesItem__39chj:hover {
        background: #FAC5C5; }
      .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 .DataSet_badgesItem__39chj.DataSet_selected__1UBig {
        background: #FAC5C5;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-color: red;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
    .DataSet_badgesWrap__372FT .DataSet_badges__36Qk7 .DataSet_showMore__lBiYx {
      box-shadow: none;
      text-shadow: none;
      background: #fff;
      position: relative;
      border-color: #ed2224; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Filters_btn__2Yw0B, .Filters_btnNo__37kC8, .Filters_btnYes__1MguL {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Filters_btn__2Yw0B:hover, .Filters_btnNo__37kC8:hover, .Filters_btnYes__1MguL:hover {
    background: #ed2224;
    color: #ffffff; }
  .Filters_btn__2Yw0B:active, .Filters_btnNo__37kC8:active, .Filters_btnYes__1MguL:active {
    background: #DC2426;
    color: #ffffff; }

.Filters_btnNo__37kC8 {
  width: 100px; }

.Filters_btnYes__1MguL {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Filters_statusWpar__drv4F {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Filters_statusWpar__drv4F::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Filters_statusActive__3oMwa::before {
  background: #28A181; }

.Filters_statusInActive__1kDOe::before {
  background: #c8c8c8; }

.Filters_arrow__Pcght {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Filters_arrow__Pcght:before, .Filters_arrow__Pcght:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Filters_arrow__Pcght:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Filters_arrow__Pcght:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Filters_sectionTitle__2Fvxc {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Filters_popUpTitle__3Fyf- {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Filters_popUpWrap__--6_c {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Filters_popUpText__kMLte {
  text-align: center; }
  .Filters_popUpText__kMLte:last-child {
    margin-bottom: 0; }

.Filters_visuallyHidden__1r9Fe {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Filters_actionsBtn__1KrW1 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Filters_actionsBtn__1KrW1 svg {
    color: #ed2224; }
  .Filters_actionsBtn__1KrW1:focus {
    outline: none; }

.Filters_actionsBtn__1KrW1.Filters_actionsBtnDisabled__3DOHa {
  background: none;
  opacity: 0.4; }

.Filters_blocked__2GGZ4 td,
.Filters_blocked__2GGZ4 span {
  color: #cccccc !important; }

.Filters_blocked__2GGZ4 .ant-checkbox-input:focus + .ant-checkbox-inner,
.Filters_blocked__2GGZ4 .ant-checkbox-inner {
  border-color: #cccccc; }

.Filters_isAdmin__2a1mv td,
.Filters_isAdmin__2a1mv span,
.Filters_isAccountManager__CBLxG td,
.Filters_isAccountManager__CBLxG span {
  font-weight: bold !important; }

.Filters_ellipsis__3dw_T {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Filters_wrapper__1z0T0 {
  width: 100%;
  padding-top: 10px; }

.Filters_itemTitle__1AodG {
  display: block;
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: #2E384D; }

.Filters_filterWrapper__He5oS {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.Filters_filterSets__n-_6R {
  width: 100%;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: baseline;
      align-items: baseline;
  margin-top: 15px; }

.Filters_teamFilter__d6LBS {
  position: relative;
  padding: 25px 0 0 25px;
  margin: 0 10px 10px 0;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 5px; }
  .Filters_teamFilter__d6LBS > div {
    margin-right: 30px;
    margin-bottom: 30px; }

.Filters_filterOption__XiSfE {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 30px; }

.Filters_selectFilterIcon__3c0Ik {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #536070 transparent transparent transparent;
  transition: 0.3s; }

.Filters_teamFilterHasValue__1uykl .ant-select-selection__placeholder {
  transform: translate(-10px, -25px) !important; }

.Filters_teamFilter__d6LBS .ant-select-selection,
.Filters_filterSets__n-_6R .ant-select-selection {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #536070;
  box-shadow: none;
  cursor: pointer; }

.Filters_teamFilter__d6LBS .ant-select-focused .ant-select-selection,
.Filters_teamFilter__d6LBS .ant-select-selection:focus,
.Filters_teamFilter__d6LBS .ant-select-selection:active,
.Filters_filterSets__n-_6R .ant-select-focused .ant-select-selection,
.Filters_filterSets__n-_6R .ant-select-selection:focus,
.Filters_filterSets__n-_6R .ant-select-selection:active {
  box-shadow: none; }

.Filters_teamFilter__d6LBS .ant-select-selection__choice,
.Filters_filterSets__n-_6R .ant-select-selection__choice {
  background: none;
  border-color: #ed2224;
  max-width: 92%; }

.Filters_teamFilter__d6LBS .ant-select-selection__placeholder,
.Filters_filterSets__n-_6R .ant-select-selection__placeholder {
  display: block !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #536070;
  transition: 0.3s; }

.Filters_teamFilter__d6LBS .ant-select-focused .ant-select-selection__placeholder,
.Filters_filterSets__n-_6R .ant-select-focused .ant-select-selection__placeholder {
  transform: translate(-10px, -25px); }

.Filters_teamFilter__d6LBS .ant-select-open .ant-select-arrow-icon,
.Filters_filterSets__n-_6R .ant-select-open .ant-select-arrow-icon {
  transform: rotate(180deg); }

.Filters_teamFilter__d6LBS .ant-select-disabled .ant-select-selection,
.Filters_filterSets__n-_6R .ant-select-disabled .ant-select-selection {
  background: transparent;
  /*opacity: unset;*/
  -webkit-filter: opacity(0.5);
          filter: opacity(0.5); }

.Filters_teamFilterDropdown__16wb6 .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.Filters_statusDropdown__2UaIl .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: #ed2224 !important;
  color: white; }
  .Filters_teamFilterDropdown__16wb6 .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) svg,
  .Filters_statusDropdown__2UaIl .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) svg {
    color: #ffffff; }

.Filters_teamFilterDropdown__16wb6 .ant-select-dropdown-menu-item-selected,
.Filters_statusDropdown__2UaIl .ant-select-dropdown-menu-item-selected {
  background: none !important;
  color: #ed2224; }
  .Filters_teamFilterDropdown__16wb6 .ant-select-dropdown-menu-item-selected svg,
  .Filters_statusDropdown__2UaIl .ant-select-dropdown-menu-item-selected svg {
    color: #ed2224; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.FilterActions_btn__2ELHY, .FilterActions_btnNo__1gwUj, .FilterActions_btnYes__2Dn88, .FilterActions_footerButton__38RYa {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .FilterActions_btn__2ELHY:hover, .FilterActions_btnNo__1gwUj:hover, .FilterActions_btnYes__2Dn88:hover, .FilterActions_footerButton__38RYa:hover {
    background: #ed2224;
    color: #ffffff; }
  .FilterActions_btn__2ELHY:active, .FilterActions_btnNo__1gwUj:active, .FilterActions_btnYes__2Dn88:active, .FilterActions_footerButton__38RYa:active {
    background: #DC2426;
    color: #ffffff; }

.FilterActions_btnNo__1gwUj {
  width: 100px; }

.FilterActions_btnYes__2Dn88 {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.FilterActions_statusWpar__2ihSi {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .FilterActions_statusWpar__2ihSi::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.FilterActions_statusActive__cZRGr::before {
  background: #28A181; }

.FilterActions_statusInActive__2FT6d::before {
  background: #c8c8c8; }

.FilterActions_arrow__6yElG {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .FilterActions_arrow__6yElG:before, .FilterActions_arrow__6yElG:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .FilterActions_arrow__6yElG:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .FilterActions_arrow__6yElG:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.FilterActions_sectionTitle__3lJq4 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.FilterActions_popUpTitle__2yLvg {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.FilterActions_popUpWrap__2wUe0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.FilterActions_popUpText__lvPx4 {
  text-align: center; }
  .FilterActions_popUpText__lvPx4:last-child {
    margin-bottom: 0; }

.FilterActions_visuallyHidden__TdAcP {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.FilterActions_actionsBtn__2oCPg {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .FilterActions_actionsBtn__2oCPg svg {
    color: #ed2224; }
  .FilterActions_actionsBtn__2oCPg:focus {
    outline: none; }

.FilterActions_actionsBtn__2oCPg.FilterActions_actionsBtnDisabled__2zpQ0 {
  background: none;
  opacity: 0.4; }

.FilterActions_blocked__MOjDa td,
.FilterActions_blocked__MOjDa span {
  color: #cccccc !important; }

.FilterActions_blocked__MOjDa .ant-checkbox-input:focus + .ant-checkbox-inner,
.FilterActions_blocked__MOjDa .ant-checkbox-inner {
  border-color: #cccccc; }

.FilterActions_isAdmin__1N-py td,
.FilterActions_isAdmin__1N-py span,
.FilterActions_isAccountManager__2bGAD td,
.FilterActions_isAccountManager__2bGAD span {
  font-weight: bold !important; }

.FilterActions_ellipsis__OUS0E {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.FilterActions_actionsWrapper__2LlZJ {
  margin: 10px 0 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: end;
      align-items: flex-end;
  -ms-flex-pack: end;
      justify-content: flex-end; }
  .FilterActions_actionsWrapper__2LlZJ button:first-child {
    margin-right: 20px; }

.FilterActions_buttonLoader__3taXf {
  display: -ms-flexbox;
  display: flex;
  margin-right: 8px; }
  .FilterActions_buttonLoader__3taXf i {
    background: #DC2426; }

.FilterActions_footerButton__38RYa {
  padding: 5px;
  border-radius: 6px; }
  .FilterActions_footerButton__38RYa .FilterActions_centeredText__38Kcr {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-size: 12px; }
  .FilterActions_footerButton__38RYa:hover .FilterActions_buttonImg__pbmAT path {
    fill: #ffffff; }
  .FilterActions_footerButton__38RYa:active {
    background: #DC2426;
    color: #ffffff; }
  .FilterActions_footerButton__38RYa:disabled {
    border: 2px solid #ed2224;
    background: none;
    opacity: 0.4; }
    .FilterActions_footerButton__38RYa:disabled .FilterActions_buttonImg__pbmAT path {
      fill: #DC2426; }

.FilterActions_buttonImg__pbmAT {
  width: 12px;
  height: 16px;
  margin-right: 10px; }
  .FilterActions_buttonImg__pbmAT path {
    transition: fill 0.5s ease;
    fill: #ed2224; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.CreateLinkModal_btn__3EG1l, .CreateLinkModal_btnNo__2cNNU, .CreateLinkModal_btnYes__1X72g, .CreateLinkModal_stepsBtn__2gT5o {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .CreateLinkModal_btn__3EG1l:hover, .CreateLinkModal_btnNo__2cNNU:hover, .CreateLinkModal_btnYes__1X72g:hover, .CreateLinkModal_stepsBtn__2gT5o:hover {
    background: #ed2224;
    color: #ffffff; }
  .CreateLinkModal_btn__3EG1l:active, .CreateLinkModal_btnNo__2cNNU:active, .CreateLinkModal_btnYes__1X72g:active, .CreateLinkModal_stepsBtn__2gT5o:active {
    background: #DC2426;
    color: #ffffff; }

.CreateLinkModal_btnNo__2cNNU {
  width: 100px; }

.CreateLinkModal_btnYes__1X72g {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.CreateLinkModal_statusWpar__1xmHI {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .CreateLinkModal_statusWpar__1xmHI::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.CreateLinkModal_statusActive__1aB3G::before {
  background: #28A181; }

.CreateLinkModal_statusInActive__TcNbi::before {
  background: #c8c8c8; }

.CreateLinkModal_arrow__1LSxq {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .CreateLinkModal_arrow__1LSxq:before, .CreateLinkModal_arrow__1LSxq:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .CreateLinkModal_arrow__1LSxq:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .CreateLinkModal_arrow__1LSxq:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.CreateLinkModal_sectionTitle__208sa {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.CreateLinkModal_popUpTitle__2Rcva {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.CreateLinkModal_popUpWrap__i1Ngq {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.CreateLinkModal_popUpText__18U5M {
  text-align: center; }
  .CreateLinkModal_popUpText__18U5M:last-child {
    margin-bottom: 0; }

.CreateLinkModal_visuallyHidden__1Uv2m {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.CreateLinkModal_actionsBtn__10NR9 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .CreateLinkModal_actionsBtn__10NR9 svg {
    color: #ed2224; }
  .CreateLinkModal_actionsBtn__10NR9:focus {
    outline: none; }

.CreateLinkModal_actionsBtn__10NR9.CreateLinkModal_actionsBtnDisabled__2OOQU {
  background: none;
  opacity: 0.4; }

.CreateLinkModal_blocked__zj5UC td,
.CreateLinkModal_blocked__zj5UC span {
  color: #cccccc !important; }

.CreateLinkModal_blocked__zj5UC .ant-checkbox-input:focus + .ant-checkbox-inner,
.CreateLinkModal_blocked__zj5UC .ant-checkbox-inner {
  border-color: #cccccc; }

.CreateLinkModal_isAdmin__2gd3x td,
.CreateLinkModal_isAdmin__2gd3x span,
.CreateLinkModal_isAccountManager__Vs3rX td,
.CreateLinkModal_isAccountManager__Vs3rX span {
  font-weight: bold !important; }

.CreateLinkModal_ellipsis__15BDZ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.CreateLinkModal_modalWrap__1N_oF {
  width: 350px !important; }
  .CreateLinkModal_modalWrap__1N_oF .CreateLinkModal_wrapper__3RJuS {
    height: 200px;
    padding: 25px; }

.CreateLinkModal_innerWrap__114jz {
  height: 100%;
  width: 100%; }

.CreateLinkModal_content__2o73b {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  height: 200px;
  width: 100%;
  padding: 35px 35px 15px 35px; }

.CreateLinkModal_btnWrapper__3I4WR {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%; }

.CreateLinkModal_stepsBtn__2gT5o {
  position: relative;
  background: #ffffff;
  border: 2px solid #ed2224;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px; }
  .CreateLinkModal_stepsBtn__2gT5o:first-child {
    margin-left: 0; }
  .CreateLinkModal_stepsBtn__2gT5o:last-child {
    margin-right: 0; }

.CreateLinkModal_pointer__3vxii {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 30px;
  cursor: pointer; }

.CreateLinkModal_noWrap__SnIz5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.CreateLinkModal_iconPencil__3cn17 {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  left: 73px; }

.CreateLinkModal_link__2NQCi {
  font-weight: bold;
  color: #2944ae;
  text-decoration: underline; }

.CreateLinkModal_errorText__1KAjo {
  position: absolute;
  font-size: 10px;
  color: red; }

.CreateLinkModal_formInput__1-99S {
  width: 100%; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.DeleteDashboardModal_btn__2R_uD, .DeleteDashboardModal_btnNo__18cNF, .DeleteDashboardModal_btnYes__3PxGn, .DeleteDashboardModal_stepsBtn__16p28 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .DeleteDashboardModal_btn__2R_uD:hover, .DeleteDashboardModal_btnNo__18cNF:hover, .DeleteDashboardModal_btnYes__3PxGn:hover, .DeleteDashboardModal_stepsBtn__16p28:hover {
    background: #ed2224;
    color: #ffffff; }
  .DeleteDashboardModal_btn__2R_uD:active, .DeleteDashboardModal_btnNo__18cNF:active, .DeleteDashboardModal_btnYes__3PxGn:active, .DeleteDashboardModal_stepsBtn__16p28:active {
    background: #DC2426;
    color: #ffffff; }

.DeleteDashboardModal_btnNo__18cNF {
  width: 100px; }

.DeleteDashboardModal_btnYes__3PxGn {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.DeleteDashboardModal_statusWpar__2Vz-P {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .DeleteDashboardModal_statusWpar__2Vz-P::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.DeleteDashboardModal_statusActive__2DsfK::before {
  background: #28A181; }

.DeleteDashboardModal_statusInActive__vuoA_::before {
  background: #c8c8c8; }

.DeleteDashboardModal_arrow__2YGAk {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .DeleteDashboardModal_arrow__2YGAk:before, .DeleteDashboardModal_arrow__2YGAk:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .DeleteDashboardModal_arrow__2YGAk:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .DeleteDashboardModal_arrow__2YGAk:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.DeleteDashboardModal_sectionTitle__pZgB3 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.DeleteDashboardModal_popUpTitle__1Hi6- {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.DeleteDashboardModal_popUpWrap__1m6Af {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.DeleteDashboardModal_popUpText__2uk9Y {
  text-align: center; }
  .DeleteDashboardModal_popUpText__2uk9Y:last-child {
    margin-bottom: 0; }

.DeleteDashboardModal_visuallyHidden__19bkR {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.DeleteDashboardModal_actionsBtn__2Qfwz {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .DeleteDashboardModal_actionsBtn__2Qfwz svg {
    color: #ed2224; }
  .DeleteDashboardModal_actionsBtn__2Qfwz:focus {
    outline: none; }

.DeleteDashboardModal_actionsBtn__2Qfwz.DeleteDashboardModal_actionsBtnDisabled__-K9S8 {
  background: none;
  opacity: 0.4; }

.DeleteDashboardModal_blocked__r6xhV td,
.DeleteDashboardModal_blocked__r6xhV span {
  color: #cccccc !important; }

.DeleteDashboardModal_blocked__r6xhV .ant-checkbox-input:focus + .ant-checkbox-inner,
.DeleteDashboardModal_blocked__r6xhV .ant-checkbox-inner {
  border-color: #cccccc; }

.DeleteDashboardModal_isAdmin__2TwNX td,
.DeleteDashboardModal_isAdmin__2TwNX span,
.DeleteDashboardModal_isAccountManager__1vrbN td,
.DeleteDashboardModal_isAccountManager__1vrbN span {
  font-weight: bold !important; }

.DeleteDashboardModal_ellipsis__3iHHq {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.DeleteDashboardModal_modalWrap__3IZFk {
  width: 350px !important; }
  .DeleteDashboardModal_modalWrap__3IZFk .DeleteDashboardModal_wrapper__352lt {
    height: 200px;
    padding: 25px; }

.DeleteDashboardModal_innerWrap__3hbNT {
  height: 100%;
  width: 100%; }

.DeleteDashboardModal_title__2raOK {
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding-bottom: 50px;
  font-style: normal;
  font-weight: bold;
  color: #424142; }

.DeleteDashboardModal_content__xwjzn {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  height: 200px;
  width: 100%;
  padding: 35px 35px 15px 35px; }

.DeleteDashboardModal_btnWrapper__20qzy {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px; }

.DeleteDashboardModal_stepsBtn__16p28 {
  position: relative;
  background: #ffffff;
  border: 2px solid #ed2224;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px; }
  .DeleteDashboardModal_stepsBtn__16p28:first-child {
    margin-left: 0; }
  .DeleteDashboardModal_stepsBtn__16p28:last-child {
    margin-right: 0; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Table_btn__1yk2R, .Table_btnNo__3p7AU, .Table_btnYes__RRRDV {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Table_btn__1yk2R:hover, .Table_btnNo__3p7AU:hover, .Table_btnYes__RRRDV:hover {
    background: #ed2224;
    color: #ffffff; }
  .Table_btn__1yk2R:active, .Table_btnNo__3p7AU:active, .Table_btnYes__RRRDV:active {
    background: #DC2426;
    color: #ffffff; }

.Table_btnNo__3p7AU {
  width: 100px; }

.Table_btnYes__RRRDV {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Table_statusWpar__33jPf {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Table_statusWpar__33jPf::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Table_statusActive__36eqA::before {
  background: #28A181; }

.Table_statusInActive__2d_Mq::before {
  background: #c8c8c8; }

.Table_arrow__1a1SM {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Table_arrow__1a1SM:before, .Table_arrow__1a1SM:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Table_arrow__1a1SM:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Table_arrow__1a1SM:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Table_sectionTitle__1uixi {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Table_popUpTitle__3UeJr {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Table_popUpWrap__2qySr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Table_popUpText__311LV {
  text-align: center; }
  .Table_popUpText__311LV:last-child {
    margin-bottom: 0; }

.Table_visuallyHidden__3SvKu {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Table_actionsBtn__16JfS {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Table_actionsBtn__16JfS svg {
    color: #ed2224; }
  .Table_actionsBtn__16JfS:focus {
    outline: none; }

.Table_actionsBtn__16JfS.Table_actionsBtnDisabled__3iMnO {
  background: none;
  opacity: 0.4; }

.Table_blocked__2QFSf td,
.Table_blocked__2QFSf span {
  color: #cccccc !important; }

.Table_blocked__2QFSf .ant-checkbox-input:focus + .ant-checkbox-inner,
.Table_blocked__2QFSf .ant-checkbox-inner {
  border-color: #cccccc; }

.Table_isAdmin__3v59R td,
.Table_isAdmin__3v59R span,
.Table_isAccountManager__1XyZi td,
.Table_isAccountManager__1XyZi span {
  font-weight: bold !important; }

.Table_ellipsis__2Rq58, .Table_tableWrap__F5kvr tr.Table_boldDivision__1cNPJ td:not(.Table_wordWrap__MvtqN), .Table_tableWrap__F5kvr tr.Table_bold__SPkQG td:not(.Table_wordWrap__MvtqN), tbody .Table_location__3b23-, th[colspan='3'] *,
td[colspan='3'] * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Table_tableWrap__F5kvr {
  position: relative; }
  .Table_tableWrap__F5kvr tr.Table_boldDivision__1cNPJ {
    background: #526071 !important; }
    .Table_tableWrap__F5kvr tr.Table_boldDivision__1cNPJ .Table_jobTitle__1EJTh, .Table_tableWrap__F5kvr tr.Table_boldDivision__1cNPJ .Table_jobSupervisor__1jTqR {
      font-weight: bold !important;
      font-size: 14px !important; }
    .Table_tableWrap__F5kvr tr.Table_boldDivision__1cNPJ td:not(.Table_graphContainer__35cOe) {
      padding: 2px 4px !important;
      font-size: 9px !important;
      color: #ffffff !important;
      white-space: normal;
      background: inherit !important; }
    .Table_tableWrap__F5kvr tr.Table_boldDivision__1cNPJ td:nth-child(1) {
      z-index: 3;
      background: #526071;
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      border-right-width: 2px; }
  .Table_tableWrap__F5kvr .Table_loading-mini__3tim_ {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    top: 50%;
    z-index: 5; }
  .Table_tableWrap__F5kvr .ant-table * {
    text-align: center !important; }
  .Table_tableWrap__F5kvr .ant-table .ant-table-header table {
    border-radius: 4px 0 0 0; }
  .Table_tableWrap__F5kvr .ant-table-thead tr:nth-child(1)::after {
    /*content: '';*/
    width: 99.9%;
    height: 58px;
    background: #526071;
    position: absolute;
    left: 0;
    top: 1px;
    z-index: -1;
    border-bottom: 1px solid #c8cccc;
    border-right: 1px solid #c8cccc;
    border-radius: 4px 4px 0 0; }
  .Table_tableWrap__F5kvr .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0; }
  .Table_tableWrap__F5kvr .deleteEmloyeeIcon {
    height: 30px; }
  .Table_tableWrap__F5kvr .ant-table-content,
  .Table_tableWrap__F5kvr .ant-table-fixed,
  .Table_tableWrap__F5kvr .ant-table-fixed-left {
    z-index: -100;
    background: #ffffff; }
    .Table_tableWrap__F5kvr .ant-table-content tr,
    .Table_tableWrap__F5kvr .ant-table-content td,
    .Table_tableWrap__F5kvr .ant-table-content th,
    .Table_tableWrap__F5kvr .ant-table-fixed tr,
    .Table_tableWrap__F5kvr .ant-table-fixed td,
    .Table_tableWrap__F5kvr .ant-table-fixed th,
    .Table_tableWrap__F5kvr .ant-table-fixed-left tr,
    .Table_tableWrap__F5kvr .ant-table-fixed-left td,
    .Table_tableWrap__F5kvr .ant-table-fixed-left th {
      border-color: #C8CCCC !important; }
  .Table_tableWrap__F5kvr .ant-table-scroll-position-left .ant-table-fixed-left {
    z-index: -100; }
  .Table_tableWrap__F5kvr .ant-table-fixed {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
    border: 1px solid #C8CCCC !important; }
  .Table_tableWrap__F5kvr .ant-table-body table {
    border: 0 solid #C8CCCC !important; }
  .Table_tableWrap__F5kvr .ant-table-body tr {
    height: 50px; }
  .Table_tableWrap__F5kvr .ant-table-thead tr {
    height: 55px; }
  .Table_tableWrap__F5kvr .ant-table-thead tr:first-child {
    text-transform: uppercase;
    background-color: #526071; }
    .Table_tableWrap__F5kvr .ant-table-thead tr:first-child th:nth-child(1) {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 100;
      background-color: #526071 !important; }
    .Table_tableWrap__F5kvr .ant-table-thead tr:first-child * {
      color: #ffffff; }
  .Table_tableWrap__F5kvr .ant-table-thead tr:nth-child(2) {
    background-color: #ffffff; }
    .Table_tableWrap__F5kvr .ant-table-thead tr:nth-child(2) th:nth-child(-n + 3) {
      box-sizing: border-box;
      position: -webkit-sticky;
      position: sticky;
      z-index: 100;
      background-color: #ffffff !important;
      border-right-width: 2px; }
    .Table_tableWrap__F5kvr .ant-table-thead tr:nth-child(2) th:nth-child(1) {
      left: 0;
      border-left: 1px solid #e8e8e8; }
    .Table_tableWrap__F5kvr .ant-table-thead tr:nth-child(2) th:nth-child(2) {
      left: 40px; }
    .Table_tableWrap__F5kvr .ant-table-thead tr:nth-child(2) th:nth-child(3) {
      left: 125.5px; }
  .Table_tableWrap__F5kvr .ant-table-thead * {
    font-size: 12px;
    color: #424142; }
  .Table_tableWrap__F5kvr .ant-table-tbody tr:nth-child(odd) {
    background-color: #F2F2F2; }
  .Table_tableWrap__F5kvr .ant-table-tbody tr:nth-child(even) {
    background-color: #fff; }
  .Table_tableWrap__F5kvr .ant-table-tbody .ant-table-row-hover > td {
    background: unset !important; }
  .Table_tableWrap__F5kvr .ant-table-tbody tr.ant-table-row:hover td {
    background-color: transparent; }
  .Table_tableWrap__F5kvr .ant-table-tbody td {
    color: #424142;
    font-size: 11px; }
  .Table_tableWrap__F5kvr .ant-select {
    display: -ms-flexbox;
    display: flex; }
  .Table_tableWrap__F5kvr .ant-select-selection {
    background: transparent;
    border: none;
    font-size: 11px;
    text-transform: lowercase;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }
    .Table_tableWrap__F5kvr .ant-select-selection div:first-letter {
      text-transform: uppercase; }
    .Table_tableWrap__F5kvr .ant-select-selection .ant-select-arrow {
      display: -ms-flexbox;
      display: flex;
      position: relative;
      top: auto;
      right: auto; }
    .Table_tableWrap__F5kvr .ant-select-selection__rendered {
      margin: 0px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
    .Table_tableWrap__F5kvr .ant-select-selection__placeholder,
    .Table_tableWrap__F5kvr .ant-select-selection .ant-select-selection-selected-value {
      color: #424142;
      text-align: start;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      margin: 0 10px 0 0;
      top: auto;
      right: auto;
      left: auto; }
  .Table_tableWrap__F5kvr .ant-select-focused .ant-select-selection,
  .Table_tableWrap__F5kvr .ant-select-selection:focus,
  .Table_tableWrap__F5kvr .ant-select-selection:active {
    box-shadow: none; }
  .Table_tableWrap__F5kvr tr.Table_usial__1Oe-2 td:nth-child(-n + 3) {
    box-sizing: border-box;
    position: -webkit-sticky;
    position: sticky;
    z-index: 2;
    background: inherit !important;
    border-right-width: 2px; }
  .Table_tableWrap__F5kvr tr.Table_usial__1Oe-2 td:nth-child(1) {
    left: 0;
    border-left: 1px solid #e8e8e8; }
  .Table_tableWrap__F5kvr tr.Table_usial__1Oe-2 td:nth-child(2) {
    left: 40px; }
  .Table_tableWrap__F5kvr tr.Table_usial__1Oe-2 td:nth-child(3) {
    left: 125px; }
  .Table_tableWrap__F5kvr tr.Table_bold__SPkQG {
    background: #0093c2; }
    .Table_tableWrap__F5kvr tr.Table_bold__SPkQG td:not(.Table_graphContainer__35cOe) {
      padding: 2px 4px !important;
      font-size: 9px !important;
      color: #ffffff !important;
      white-space: normal; }
    .Table_tableWrap__F5kvr tr.Table_bold__SPkQG td {
      z-index: 1;
      background: #0093c2;
      position: -webkit-sticky;
      position: sticky;
      top: 0; }
      .Table_tableWrap__F5kvr tr.Table_bold__SPkQG td:nth-child(1) {
        z-index: 4;
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        border-right-width: 2px; }
    .Table_tableWrap__F5kvr tr.Table_bold__SPkQG:hover td {
      background: #0093c2 !important; }

thead th.Table_behaviorHeader__1hDhA {
  border-right: none !important; }

thead tr {
  height: 58px !important; }

thead .Table_behaviorHeaderFixed__3SGig {
  white-space: nowrap; }

tbody tr:first-child .Table_graphImg__5NnEE {
  transform-origin: top; }

tbody tr:first-child .Table_graphImgMod__3Ec9c {
  transform-origin: bottom; }

tbody tr {
  height: 50px !important; }

th[colspan='3'],
td[colspan='3'] {
  max-width: 200px !important;
  overflow: hidden; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.styles_btn__1UVlL, .styles_btnNo__l-Kfk, .styles_btnYes__W5so7, .styles_footerButton__2ALUd {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .styles_btn__1UVlL:hover, .styles_btnNo__l-Kfk:hover, .styles_btnYes__W5so7:hover, .styles_footerButton__2ALUd:hover {
    background: #ed2224;
    color: #ffffff; }
  .styles_btn__1UVlL:active, .styles_btnNo__l-Kfk:active, .styles_btnYes__W5so7:active, .styles_footerButton__2ALUd:active {
    background: #DC2426;
    color: #ffffff; }

.styles_btnNo__l-Kfk {
  width: 100px; }

.styles_btnYes__W5so7 {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.styles_statusWpar__2MAyZ {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .styles_statusWpar__2MAyZ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.styles_statusActive__1w3ks::before {
  background: #28A181; }

.styles_statusInActive__1Gval::before {
  background: #c8c8c8; }

.styles_arrow__1T6ap, .styles_collapseArrow__2OW8T {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .styles_arrow__1T6ap:before, .styles_collapseArrow__2OW8T:before, .styles_arrow__1T6ap:after, .styles_collapseArrow__2OW8T:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .styles_arrow__1T6ap:before, .styles_collapseArrow__2OW8T:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .styles_arrow__1T6ap:after, .styles_collapseArrow__2OW8T:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.styles_sectionTitle__XGHLF {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.styles_popUpTitle__3Q34n {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.styles_popUpWrap__dPDRk {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.styles_popUpText__1O7IU {
  text-align: center; }
  .styles_popUpText__1O7IU:last-child {
    margin-bottom: 0; }

.styles_visuallyHidden__JqVQJ {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.styles_actionsBtn__1ywfV, .styles_actionsBtnImage__3WRL9 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .styles_actionsBtn__1ywfV svg, .styles_actionsBtnImage__3WRL9 svg {
    color: #ed2224; }
  .styles_actionsBtn__1ywfV:focus, .styles_actionsBtnImage__3WRL9:focus {
    outline: none; }

.styles_actionsBtn__1ywfV.styles_actionsBtnDisabled__3J-Bo, .styles_actionsBtnDisabled__3J-Bo.styles_actionsBtnImage__3WRL9 {
  background: none;
  opacity: 0.4; }

.styles_blocked__1nhr0 td,
.styles_blocked__1nhr0 span {
  color: #cccccc !important; }

.styles_blocked__1nhr0 .ant-checkbox-input:focus + .ant-checkbox-inner,
.styles_blocked__1nhr0 .ant-checkbox-inner {
  border-color: #cccccc; }

.styles_isAdmin__2hnZf td,
.styles_isAdmin__2hnZf span,
.styles_isAccountManager__17zNE td,
.styles_isAccountManager__17zNE span {
  font-weight: bold !important; }

.styles_ellipsis__2ZMbB, tbody .styles_location__29A6I, th[colspan='3'] *,
td[colspan='3'] *, .styles_name__3LWoU {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.styles_wrapper__3T08S {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto 0; }
  .styles_wrapper__3T08S * {
    font-family: 'Roboto', sans-serif; }
  .styles_wrapper__3T08S .ant-collapse {
    border: 1px solid rgba(46, 56, 77, 0.2); }
    .styles_wrapper__3T08S .ant-collapse-header {
      background: #ffffff;
      letter-spacing: 1.21333px;
      text-transform: uppercase;
      color: #536070;
      font-size: 16px;
      line-height: 19px;
      padding: 30px !important;
      padding-right: 45px !important;
      border-radius: 4px !important; }
      .styles_wrapper__3T08S .ant-collapse-header i {
        width: 16px;
        right: 30px !important; }
      .styles_wrapper__3T08S .ant-collapse-header svg {
        color: red;
        width: 100%;
        height: 100%; }
    .styles_wrapper__3T08S .ant-collapse-content {
      border-top: none; }
      .styles_wrapper__3T08S .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 30px 10px 30px; }
    .styles_wrapper__3T08S .ant-collapse-item {
      border: none; }
    .styles_wrapper__3T08S .ant-collapse-item-active span.ant-collapse-arrow {
      transform: rotate(90deg) !important; }
  .styles_wrapper__3T08S .ant-table * {
    text-align: center !important; }
  .styles_wrapper__3T08S .ant-table .ant-table-header table {
    border-radius: 4px 0 0 0; }
  .styles_wrapper__3T08S .ant-table-thead tr:nth-child(1)::after {
    /*content: '';*/
    width: 99.9%;
    height: 58px;
    background: #526071;
    position: absolute;
    left: 0;
    top: 1px;
    z-index: -1;
    border-bottom: 1px solid #c8cccc;
    border-right: 1px solid #c8cccc;
    border-radius: 4px 4px 0 0; }
  .styles_wrapper__3T08S .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0; }
  .styles_wrapper__3T08S .deleteEmloyeeIcon {
    height: 30px; }
  .styles_wrapper__3T08S .ant-table-content,
  .styles_wrapper__3T08S .ant-table-fixed,
  .styles_wrapper__3T08S .ant-table-fixed-left {
    z-index: -100;
    background: #ffffff; }
    .styles_wrapper__3T08S .ant-table-content tr,
    .styles_wrapper__3T08S .ant-table-content td,
    .styles_wrapper__3T08S .ant-table-content th,
    .styles_wrapper__3T08S .ant-table-fixed tr,
    .styles_wrapper__3T08S .ant-table-fixed td,
    .styles_wrapper__3T08S .ant-table-fixed th,
    .styles_wrapper__3T08S .ant-table-fixed-left tr,
    .styles_wrapper__3T08S .ant-table-fixed-left td,
    .styles_wrapper__3T08S .ant-table-fixed-left th {
      border-color: #C8CCCC !important; }
  .styles_wrapper__3T08S .ant-table-scroll-position-left .ant-table-fixed-left {
    z-index: -100; }
  .styles_wrapper__3T08S .ant-table-fixed {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
    border: 1px solid #C8CCCC !important; }
  .styles_wrapper__3T08S .ant-table-body table {
    border: 0 solid #C8CCCC !important; }
  .styles_wrapper__3T08S .ant-table-body tr {
    height: 50px; }
  .styles_wrapper__3T08S .ant-table-thead tr {
    height: 55px; }
  .styles_wrapper__3T08S .ant-table-thead tr:first-child {
    text-transform: uppercase;
    background-color: #526071; }
    .styles_wrapper__3T08S .ant-table-thead tr:first-child th:nth-child(1) {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 100;
      background-color: #526071 !important; }
    .styles_wrapper__3T08S .ant-table-thead tr:first-child * {
      color: #ffffff; }
  .styles_wrapper__3T08S .ant-table-thead tr:nth-child(2) {
    background-color: #ffffff; }
    .styles_wrapper__3T08S .ant-table-thead tr:nth-child(2) th:nth-child(-n + 3) {
      box-sizing: border-box;
      position: -webkit-sticky;
      position: sticky;
      z-index: 100;
      background-color: #ffffff !important;
      border-right-width: 2px; }
    .styles_wrapper__3T08S .ant-table-thead tr:nth-child(2) th:nth-child(1) {
      left: 0;
      border-left: 1px solid #e8e8e8; }
    .styles_wrapper__3T08S .ant-table-thead tr:nth-child(2) th:nth-child(2) {
      left: 40px; }
    .styles_wrapper__3T08S .ant-table-thead tr:nth-child(2) th:nth-child(3) {
      left: 125.5px; }
  .styles_wrapper__3T08S .ant-table-thead * {
    font-size: 12px;
    color: #424142; }
  .styles_wrapper__3T08S .ant-table-tbody tr:nth-child(odd) {
    background-color: #F2F2F2; }
  .styles_wrapper__3T08S .ant-table-tbody tr:nth-child(even) {
    background-color: #fff; }
  .styles_wrapper__3T08S .ant-table-tbody .ant-table-row-hover > td {
    background: unset !important; }
  .styles_wrapper__3T08S .ant-table-tbody tr.ant-table-row:hover td {
    background-color: transparent !important; }
  .styles_wrapper__3T08S .ant-table-tbody td {
    color: #424142;
    font-size: 11px; }

.styles_collapseWrap__2d8Ar {
  margin-bottom: 35px; }

.styles_collapseWrapOnlyView__2M2fI {
  margin-bottom: 35px;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 4px; }
  .styles_collapseWrapOnlyView__2M2fI :focus {
    outline: none; }

.styles_collapseArrow__2OW8T {
  top: 44% !important;
  right: 30px !important; }
  .styles_collapseArrow__2OW8T:before, .styles_collapseArrow__2OW8T:after {
    display: -ms-flexbox !important;
    display: flex !important; }

.styles_checkboxes__1OjX8 {
  margin: 10px; }
  .styles_checkboxes__1OjX8 span {
    color: #536070; }

.styles_itemTitle__2IRvP {
  display: inline-block;
  margin-right: 50px;
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: #2E384D; }

.styles_actionsBtnImage__3WRL9 {
  position: absolute;
  left: 328px;
  top: 0; }
  .styles_actionsBtnImage__3WRL9 svg {
    height: 24px;
    width: 24px; }

.styles_footerButton__2ALUd {
  padding: 11px 30px;
  margin-right: 30px;
  border-radius: 4px; }
  .styles_footerButton__2ALUd .styles_centeredText__3gHly {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .styles_footerButton__2ALUd:hover .styles_buttonImg__1QnII path {
    fill: #ffffff; }

.styles_buttonImg__1QnII {
  width: 24px;
  height: 24px;
  margin-right: 10px; }
  .styles_buttonImg__1QnII path {
    transition: fill 0.5s ease;
    fill: #ed2224; }

.styles_actionsBtn__1ywfV.styles_actionsBtnDisabled__3J-Bo, .styles_actionsBtnDisabled__3J-Bo.styles_actionsBtnImage__3WRL9 {
  background: none;
  opacity: 0.4; }

.styles_itemFooter__199Sg {
  margin: 10px 0 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: end;
      align-items: flex-end; }

.styles_lastUpdate__1Ex3Y {
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
  line-height: 13px; }
  .styles_lastUpdate__1Ex3Y span {
    color: #000000;
    font-size: 11px; }

.styles_lastUpdateTitle__kz6o5 {
  margin-left: 20px; }

.styles_actualWrap__3ldVt {
  position: relative; }

.styles_toolTipWrap__1iF5F {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.styles_actualBadge__w1ldB {
  position: absolute;
  bottom: -7px;
  right: -4px;
  background: #f9d772;
  color: white;
  border-radius: 50%;
  font-size: 9px;
  width: 13px;
  height: 13px; }

.styles_behavior__axSjO, .styles_score__WIZGn, .styles_scoreNoData__3Jj3U, .styles_scoreNoDataMod__noWDs {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 9px; }

.styles_fontSize9__3IPUj span {
  font-size: 9px !important; }

.styles_score__WIZGn {
  width: 26px;
  height: 26px; }

.styles_scoreNoData__3Jj3U, .styles_scoreNoDataMod__noWDs {
  width: 31px;
  height: 31px;
  border: 1px solid #C8CCCC;
  color: #424142;
  text-align: center; }

.styles_scoreNoDataMod__noWDs {
  color: #ffffff; }

.styles_headersWithPopover__2Q8tb {
  max-width: 350px; }

thead th.styles_behaviorHeader__3sByH {
  border-right: none !important; }

thead tr {
  height: 58px !important; }

thead .styles_behaviorHeaderFixed__1_n5J {
  white-space: nowrap; }

.styles_graphImg__3Dj_h {
  width: 85%;
  max-height: 30px;
  object-fit: scale-down;
  position: relative;
  transition: all 0.6s ease;
  transform-origin: bottom; }

.styles_graphImgModal__4EDnc {
  font-size: 13px; }
  .styles_graphImgModal__4EDnc .styles_graphImg__3Dj_h {
    width: 100%;
    min-height: 160px;
    max-height: 100%;
    object-fit: contain; }
  .styles_graphImgModal__4EDnc div {
    margin: 5px 0; }
  .styles_graphImgModal__4EDnc b {
    margin: 0 5px; }

tbody tr:first-child .styles_graphImg__3Dj_h {
  transform-origin: top; }

tbody tr:first-child .styles_graphImgMod__2Ej2v {
  transform-origin: bottom; }

tbody tr {
  height: 50px !important; }

th[colspan='3'],
td[colspan='3'] {
  max-width: 200px !important;
  overflow: hidden; }

.styles_graphContainer__2MS_q {
  position: relative;
  padding: 8px 0 !important; }

.styles_behaviorGreen__27OzX {
  background: #28A181; }

.styles_behaviorYellow__2vW1a {
  background: #f9d772; }

.styles_behaviorRed__1BG7g {
  background: #c8c8c8; }

.styles_behaviorBlack__1jcHn {
  color: black; }

.styles_showArrayOnHover__rcaGE {
  list-style-type: none; }

.styles_imgLoader__1CL4y {
  margin: 0 auto;
  border: 2px solid #ffffff;
  border-top: 2px solid #ed2224;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation: styles_spin__2zTjl 1.5s linear infinite;
          animation: styles_spin__2zTjl 1.5s linear infinite; }

@-webkit-keyframes styles_spin__2zTjl {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes styles_spin__2zTjl {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.styles_jobTitleWrap__3vr3j {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .styles_jobTitleWrap__3vr3j .styles_jobTitle__NuKxU, .styles_jobTitleWrap__3vr3j .styles_jobSupervisor__1XzkF {
    font-weight: bold;
    font-size: 14px; }

.styles_name__3LWoU {
  max-width: 80px; }

.styles_comboMatch__2KNnX .styles_sortImg__3oIzQ {
  display: -ms-flexbox;
  display: flex;
  word-break: normal;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  .styles_comboMatch__2KNnX .styles_sortImg__3oIzQ span {
    font-size: 9px;
    display: -ms-flexbox;
    display: flex; }

.styles_comboMatch__2KNnX .ant-table-column-title {
  font-size: 9px;
  display: -ms-flexbox;
  display: flex; }

.styles_comboMatch__2KNnX span.styles_behavior__axSjO, .styles_comboMatch__2KNnX span.styles_score__WIZGn, .styles_comboMatch__2KNnX span.styles_scoreNoData__3Jj3U, .styles_comboMatch__2KNnX span.styles_scoreNoDataMod__noWDs {
  width: 30px;
  height: 30px; }

.styles_tooltipOnButtonLink__xEMtM {
  width: 180px; }

.styles_statusDropdown__3JiAG li {
  font-size: 11px;
  text-transform: lowercase;
  padding: 0 10px; }
  .styles_statusDropdown__3JiAG li:first-letter {
    text-transform: uppercase; }

.styles_selectIcon__2PJhT {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: #424142 transparent transparent transparent;
  margin-top: 4px; }

.styles_selectFilterIcon__ce2xn {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #536070 transparent transparent transparent;
  transition: 0.3s; }

.styles_actionsWrap__2TqT_ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 50px; }

.styles_actionsButtons__1lCQQ {
  margin-left: 0; }

.styles_actionsBtn__1ywfV, .styles_actionsBtnImage__3WRL9 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  color: #000000;
  cursor: pointer; }

.styles_filterSets__1iBUz {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
      align-items: baseline;
  margin: 25px 0; }

.styles_linkToShare__2g141 {
  margin-left: 10px;
  margin-bottom: auto;
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: #2E384D; }

.styles_actionsImg__3MrGI {
  margin-right: 5px; }

.styles_sortImg__3oIzQ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  word-break: normal; }
  .styles_sortImg__3oIzQ img {
    cursor: pointer;
    margin-left: 5px;
    height: 14px; }

.styles_filterOption__YVER_ {
  display: inline-block; }

.styles_teamFilter__2Ehuw {
  position: relative;
  padding: 25px 0 0 25px;
  margin: 0 10px 10px 0;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 5px; }
  .styles_teamFilter__2Ehuw > div {
    margin-right: 30px;
    margin-bottom: 30px; }

.styles_teamFilter__2Ehuw .ant-select-selection,
.styles_filterSets__1iBUz .ant-select-selection {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #536070;
  box-shadow: none;
  cursor: pointer; }

.styles_teamFilter__2Ehuw .ant-select-focused .ant-select-selection,
.styles_teamFilter__2Ehuw .ant-select-selection:focus,
.styles_teamFilter__2Ehuw .ant-select-selection:active,
.styles_filterSets__1iBUz .ant-select-focused .ant-select-selection,
.styles_filterSets__1iBUz .ant-select-selection:focus,
.styles_filterSets__1iBUz .ant-select-selection:active {
  box-shadow: none; }

.styles_teamFilter__2Ehuw .ant-select-selection__choice,
.styles_filterSets__1iBUz .ant-select-selection__choice {
  background: none;
  border-color: #ed2224; }

.styles_teamFilter__2Ehuw .ant-select-selection__placeholder,
.styles_filterSets__1iBUz .ant-select-selection__placeholder {
  display: block !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #536070;
  transition: 0.3s; }

.styles_teamFilter__2Ehuw .ant-select-focused .ant-select-selection__placeholder,
.styles_filterSets__1iBUz .ant-select-focused .ant-select-selection__placeholder {
  transform: translate(-10px, -25px); }

.styles_teamFilter__2Ehuw .ant-select-open .ant-select-arrow-icon,
.styles_filterSets__1iBUz .ant-select-open .ant-select-arrow-icon {
  transform: rotate(180deg); }

.styles_teamFilterHasValue__1a4hG .ant-select-selection__placeholder {
  transform: translate(-10px, -25px) !important; }

.styles_teamFilterDropdown__15NrS .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.styles_statusDropdown__3JiAG .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: #ed2224 !important;
  color: white; }
  .styles_teamFilterDropdown__15NrS .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) svg,
  .styles_statusDropdown__3JiAG .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) svg {
    color: #ffffff; }

.styles_teamFilterDropdown__15NrS .ant-select-dropdown-menu-item-selected,
.styles_statusDropdown__3JiAG .ant-select-dropdown-menu-item-selected {
  background: none !important;
  color: #ed2224; }
  .styles_teamFilterDropdown__15NrS .ant-select-dropdown-menu-item-selected svg,
  .styles_statusDropdown__3JiAG .ant-select-dropdown-menu-item-selected svg {
    color: #ed2224; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #ed2224;
  border-radius: 4px; }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #ed2224;
    height: 8px; }

.ant-checkbox-inner {
  border-color: #ed2224; }

.ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ed2224; }

.ant-modal-body {
  padding: 0; }

.ant-tooltip {
  max-width: 540px !important; }
  .ant-tooltip div {
    text-align: justify; }

.styles_calculatorContainer__WuN8v {
  padding: 2px 10px !important; }

.styles_calculator__1zsdb {
  transition: all 0.6s ease;
  transform-origin: bottom; }

.styles_divisionCalculator__3DV3a {
  cursor: pointer; }

.styles_iconTrash__3Mr2t {
  height: 20px;
  cursor: pointer; }

.styles_loading-mini__G-Cxf {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101; }

.styles_titleName__1XcRA {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .styles_titleName__1XcRA .styles_formInput__3FIIM {
    transition: .1s;
    margin-bottom: 1px;
    margin-top: 0; }
    .styles_titleName__1XcRA .styles_formInput__3FIIM input {
      /*text-align: center;*/
      transition: .1s;
      padding-right: 5px;
      text-transform: uppercase; }
      .styles_titleName__1XcRA .styles_formInput__3FIIM input:focus {
        text-align: left; }
    .styles_titleName__1XcRA .styles_formInput__3FIIM.styles_disabled__sHKEe {
      border-color: transparent; }
      .styles_titleName__1XcRA .styles_formInput__3FIIM.styles_disabled__sHKEe input {
        overflow: hidden;
        text-overflow: ellipsis; }
  .styles_titleName__1XcRA button {
    position: absolute;
    right: 0;
    margin-bottom: 3px;
    cursor: pointer;
    border: 0;
    background: transparent;
    z-index: 10;
    margin-right: -15px; }
    .styles_titleName__1XcRA button svg {
      width: 10px; }
      .styles_titleName__1XcRA button svg path {
        fill: white; }
    .styles_titleName__1XcRA button[disabled] {
      cursor: not-allowed; }
      .styles_titleName__1XcRA button[disabled] svg path {
        fill: rgba(237, 34, 36, 0.3); }

.styles_tooltipJobTitle__2ib-r {
  background: #0093c2 !important;
  color: white !important;
  font-weight: bold !important; }

.styles_fontSize9OnHover__x4bNU {
  text-align: center;
  font-size: 9px; }

th.styles_fontSize9OnHover__x4bNU {
  background: #526071 !important;
  text-align: center !important; }
  th.styles_fontSize9OnHover__x4bNU span {
    color: #ffffff;
    text-align: center !important; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.CalculatorTable_btn__1Zcx4, .CalculatorTable_btnNo__2Svpi, .CalculatorTable_btnYes__2HBae {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .CalculatorTable_btn__1Zcx4:hover, .CalculatorTable_btnNo__2Svpi:hover, .CalculatorTable_btnYes__2HBae:hover {
    background: #ed2224;
    color: #ffffff; }
  .CalculatorTable_btn__1Zcx4:active, .CalculatorTable_btnNo__2Svpi:active, .CalculatorTable_btnYes__2HBae:active {
    background: #DC2426;
    color: #ffffff; }

.CalculatorTable_btnNo__2Svpi {
  width: 100px; }

.CalculatorTable_btnYes__2HBae {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.CalculatorTable_statusWpar__1FFfs {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .CalculatorTable_statusWpar__1FFfs::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.CalculatorTable_statusActive__1cAFh::before {
  background: #28A181; }

.CalculatorTable_statusInActive__CMS4z::before {
  background: #c8c8c8; }

.CalculatorTable_arrow__3-RWq {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .CalculatorTable_arrow__3-RWq:before, .CalculatorTable_arrow__3-RWq:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .CalculatorTable_arrow__3-RWq:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .CalculatorTable_arrow__3-RWq:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.CalculatorTable_sectionTitle__Tt2W3 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.CalculatorTable_popUpTitle__KeJfo {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.CalculatorTable_popUpWrap__18tLc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.CalculatorTable_popUpText__3R4cs {
  text-align: center; }
  .CalculatorTable_popUpText__3R4cs:last-child {
    margin-bottom: 0; }

.CalculatorTable_visuallyHidden__1hNVi {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.CalculatorTable_actionsBtn__lljK4 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .CalculatorTable_actionsBtn__lljK4 svg {
    color: #ed2224; }
  .CalculatorTable_actionsBtn__lljK4:focus {
    outline: none; }

.CalculatorTable_actionsBtn__lljK4.CalculatorTable_actionsBtnDisabled__2JrjL {
  background: none;
  opacity: 0.4; }

.CalculatorTable_blocked__3EsVi td,
.CalculatorTable_blocked__3EsVi span {
  color: #cccccc !important; }

.CalculatorTable_blocked__3EsVi .ant-checkbox-input:focus + .ant-checkbox-inner,
.CalculatorTable_blocked__3EsVi .ant-checkbox-inner {
  border-color: #cccccc; }

.CalculatorTable_isAdmin__1jUvu td,
.CalculatorTable_isAdmin__1jUvu span,
.CalculatorTable_isAccountManager__2m1dh td,
.CalculatorTable_isAccountManager__2m1dh span {
  font-weight: bold !important; }

.CalculatorTable_ellipsis__2G7Rw {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.CalculatorTable_formInput__Ggila {
  width: 100%;
  margin: 0 10px 0 0; }
  .CalculatorTable_formInput__Ggila input {
    font-size: 14px; }

.CalculatorTable_inputSection__2V-Af, .CalculatorTable_companySelectHasValue__1vcrR,
.CalculatorTable_usersSelectHasValue__2oA6x,
.CalculatorTable_datasetHasValues__3640h {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .CalculatorTable_inputSection__2V-Af .ant-select, .CalculatorTable_companySelectHasValue__1vcrR .ant-select, .CalculatorTable_usersSelectHasValue__2oA6x .ant-select, .CalculatorTable_datasetHasValues__3640h .ant-select {
    color: #424142; }
  .CalculatorTable_inputSection__2V-Af .ant-select-selection__placeholder, .CalculatorTable_companySelectHasValue__1vcrR .ant-select-selection__placeholder, .CalculatorTable_usersSelectHasValue__2oA6x .ant-select-selection__placeholder, .CalculatorTable_datasetHasValues__3640h .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .CalculatorTable_inputSection__2V-Af .ant-select-selection__rendered, .CalculatorTable_companySelectHasValue__1vcrR .ant-select-selection__rendered, .CalculatorTable_usersSelectHasValue__2oA6x .ant-select-selection__rendered, .CalculatorTable_datasetHasValues__3640h .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .CalculatorTable_inputSection__2V-Af .ant-select-selection, .CalculatorTable_companySelectHasValue__1vcrR .ant-select-selection, .CalculatorTable_usersSelectHasValue__2oA6x .ant-select-selection, .CalculatorTable_datasetHasValues__3640h .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .CalculatorTable_inputSection__2V-Af .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CalculatorTable_companySelectHasValue__1vcrR .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CalculatorTable_usersSelectHasValue__2oA6x .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CalculatorTable_datasetHasValues__3640h .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .CalculatorTable_inputSection__2V-Af .ant-select-focused .ant-select-selection:focus, .CalculatorTable_companySelectHasValue__1vcrR .ant-select-focused .ant-select-selection:focus, .CalculatorTable_usersSelectHasValue__2oA6x .ant-select-focused .ant-select-selection:focus, .CalculatorTable_datasetHasValues__3640h .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.CalculatorTable_addUserPasswordSections__U8dKG {
  margin-bottom: 30px; }

.CalculatorTable_companySelectHasValue__1vcrR .ant-select-selection__placeholder,
.CalculatorTable_usersSelectHasValue__2oA6x .ant-select-selection__placeholder,
.CalculatorTable_datasetHasValues__3640h .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.CalculatorTable_inputSectionLabel__R5mvY {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.CalculatorTable_btnWrap__2uCqB, .CalculatorTable_btnWrapSingle__3BHjL {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .CalculatorTable_btnWrap__2uCqB button, .CalculatorTable_btnWrapSingle__3BHjL button {
    width: 100%; }

.CalculatorTable_btnWrapSingle__3BHjL button {
  width: 200px; }

.CalculatorTable_editBtn__Jl-Qz {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .CalculatorTable_editBtn__Jl-Qz svg {
    font-size: 14px; }
    .CalculatorTable_editBtn__Jl-Qz svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.CalculatorTable_wrapper__1Z481, .CalculatorTable_innerWrap__17omB {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
  width: 50px;
  margin: 0 auto; }

.CalculatorTable_innerWrap__17omB {
  width: 100%; }

.CalculatorTable_item__9eTI0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.CalculatorTable_itemModal__3LiIo {
  display: -ms-flexbox;
  display: flex; }
  .CalculatorTable_itemModal__3LiIo:not(:nth-child(3n)) {
    border-right: 1px solid #C8CCCC; }
  .CalculatorTable_itemModal__3LiIo:not(:nth-child(n + 7)) {
    border-bottom: 1px solid #C8CCCC; }

.CalculatorTable_circle__3lFwo {
  width: 9px;
  height: 9px;
  margin: 2px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid black; }

.CalculatorTable_circleInModal__1Jt7v {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 20px;
  height: 20px;
  margin: 10px 30px 10px 0;
  cursor: pointer; }

.CalculatorTable_color__1aKFH {
  background: #ed2224; }

.CalculatorTable_colorOne__5A66T {
  background: #d9b64c; }

.CalculatorTable_colorTwo__3qe8X {
  background: #28A181; }

.CalculatorTable_colorThree__3y9jp {
  background: #adadad; }

.CalculatorTable_modalWrap__20Nxj {
  width: 700px !important; }
  .CalculatorTable_modalWrap__20Nxj .CalculatorTable_wrapper__1Z481, .CalculatorTable_modalWrap__20Nxj .CalculatorTable_innerWrap__17omB {
    height: 600px;
    padding: 25px; }
  .CalculatorTable_modalWrap__20Nxj .CalculatorTable_item__9eTI0 {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: start;
        justify-content: flex-start;
    padding: 10px;
    max-width: 233.33333px;
    width: 100%;
    max-height: 200px;
    overflow-y: auto; }

.CalculatorTable_boxListWrap__1YrMf {
  width: 100%;
  padding: 10px; }
  .CalculatorTable_boxListWrap__1YrMf li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.CalculatorTable_boxListItemWrap__EY4aq {
  position: relative; }

.CalculatorTable_boxListItem__36yLJ {
  padding-left: 20px; }

.CalculatorTable_index__5LrhH {
  position: absolute;
  top: 0;
  left: 0; }

.SortIcon_red__2Kn4u path {
  color: red !important; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.SetTerminationDateTable_btn__2G5WK, .SetTerminationDateTable_btnNo__2DH9k, .SetTerminationDateTable_btnYes__1-Yh8, .SetTerminationDateTable_stepsBtn__2dP7g {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .SetTerminationDateTable_btn__2G5WK:hover, .SetTerminationDateTable_btnNo__2DH9k:hover, .SetTerminationDateTable_btnYes__1-Yh8:hover, .SetTerminationDateTable_stepsBtn__2dP7g:hover {
    background: #ed2224;
    color: #ffffff; }
  .SetTerminationDateTable_btn__2G5WK:active, .SetTerminationDateTable_btnNo__2DH9k:active, .SetTerminationDateTable_btnYes__1-Yh8:active, .SetTerminationDateTable_stepsBtn__2dP7g:active {
    background: #DC2426;
    color: #ffffff; }

.SetTerminationDateTable_btnNo__2DH9k {
  width: 100px; }

.SetTerminationDateTable_btnYes__1-Yh8 {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.SetTerminationDateTable_statusWpar__Ha6Cz {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .SetTerminationDateTable_statusWpar__Ha6Cz::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.SetTerminationDateTable_statusActive__2kkf7::before {
  background: #28A181; }

.SetTerminationDateTable_statusInActive__2k8fV::before {
  background: #c8c8c8; }

.SetTerminationDateTable_arrow__10loy {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .SetTerminationDateTable_arrow__10loy:before, .SetTerminationDateTable_arrow__10loy:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .SetTerminationDateTable_arrow__10loy:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .SetTerminationDateTable_arrow__10loy:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.SetTerminationDateTable_sectionTitle__1Avnw {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.SetTerminationDateTable_popUpTitle__2PTi6 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.SetTerminationDateTable_popUpWrap__1J36D {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.SetTerminationDateTable_popUpText__19inu {
  text-align: center; }
  .SetTerminationDateTable_popUpText__19inu:last-child {
    margin-bottom: 0; }

.SetTerminationDateTable_visuallyHidden__1ki9s {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.SetTerminationDateTable_actionsBtn__3qzbw {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .SetTerminationDateTable_actionsBtn__3qzbw svg {
    color: #ed2224; }
  .SetTerminationDateTable_actionsBtn__3qzbw:focus {
    outline: none; }

.SetTerminationDateTable_actionsBtn__3qzbw.SetTerminationDateTable_actionsBtnDisabled__31fMH {
  background: none;
  opacity: 0.4; }

.SetTerminationDateTable_blocked__2ZFC9 td,
.SetTerminationDateTable_blocked__2ZFC9 span {
  color: #cccccc !important; }

.SetTerminationDateTable_blocked__2ZFC9 .ant-checkbox-input:focus + .ant-checkbox-inner,
.SetTerminationDateTable_blocked__2ZFC9 .ant-checkbox-inner {
  border-color: #cccccc; }

.SetTerminationDateTable_isAdmin__1p3kt td,
.SetTerminationDateTable_isAdmin__1p3kt span,
.SetTerminationDateTable_isAccountManager__2igGw td,
.SetTerminationDateTable_isAccountManager__2igGw span {
  font-weight: bold !important; }

.SetTerminationDateTable_ellipsis__3iEeB {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.SetTerminationDateTable_modalWrap__2Sn-A {
  width: 500px !important; }
  .SetTerminationDateTable_modalWrap__2Sn-A .SetTerminationDateTable_wrapper__3LMOx {
    height: 133px;
    padding: 25px; }

.SetTerminationDateTable_innerWrap__26DmE {
  width: 100%; }

.SetTerminationDateTable_dates__33yvL {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.SetTerminationDateTable_content__oC1Zv {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  width: 100%;
  padding: 40px 25px 0 25px; }

.SetTerminationDateTable_underContent__Ho3iE {
  width: 100%;
  padding: 15px 47px 0;
  color: #ed2224; }
  .SetTerminationDateTable_underContent__Ho3iE p {
    margin-bottom: 0; }

.SetTerminationDateTable_btnWrapper__1ecP5 {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  padding: 40px 25px 40px 25px; }

.SetTerminationDateTable_stepsBtn__2dP7g {
  position: relative;
  background: #ffffff;
  border: 2px solid #ed2224;
  max-width: 176px;
  width: 100%;
  height: 40px; }

.SetTerminationDateTable_pointer__19zil {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 30px;
  cursor: pointer; }

.SetTerminationDateTable_noWrap__2Iwsk {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis; }

.SetTerminationDateTable_iconPencil__12Ru5 {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  left: 73px; }

.SetTerminationDateTable_link__2aKiT {
  font-weight: bold;
  color: #2944ae;
  text-decoration: underline; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.SetStatusTable_btn__3AMBh, .SetStatusTable_btnNo__2Agfb, .SetStatusTable_btnYes__Loo2-, .SetStatusTable_btnCancel__16vCS, .SetStatusTable_btnDone__3ClaN {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .SetStatusTable_btn__3AMBh:hover, .SetStatusTable_btnNo__2Agfb:hover, .SetStatusTable_btnYes__Loo2-:hover, .SetStatusTable_btnCancel__16vCS:hover, .SetStatusTable_btnDone__3ClaN:hover {
    background: #ed2224;
    color: #ffffff; }
  .SetStatusTable_btn__3AMBh:active, .SetStatusTable_btnNo__2Agfb:active, .SetStatusTable_btnYes__Loo2-:active, .SetStatusTable_btnCancel__16vCS:active, .SetStatusTable_btnDone__3ClaN:active {
    background: #DC2426;
    color: #ffffff; }

.SetStatusTable_btnNo__2Agfb {
  width: 100px; }

.SetStatusTable_btnYes__Loo2- {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.SetStatusTable_statusWpar__o9dpl {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .SetStatusTable_statusWpar__o9dpl::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.SetStatusTable_statusActive__2fN4Y::before {
  background: #28A181; }

.SetStatusTable_statusInActive__syiWz::before {
  background: #c8c8c8; }

.SetStatusTable_arrow__3kwke {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .SetStatusTable_arrow__3kwke:before, .SetStatusTable_arrow__3kwke:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .SetStatusTable_arrow__3kwke:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .SetStatusTable_arrow__3kwke:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.SetStatusTable_sectionTitle__3O3M9 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.SetStatusTable_popUpTitle__3YEaz, .SetStatusTable_title__1jOAO {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.SetStatusTable_popUpWrap__1nbCu, .SetStatusTable_modalWrap__3s2L_ .SetStatusTable_wrapper__2VNqA {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.SetStatusTable_popUpText__2FAhp {
  text-align: center; }
  .SetStatusTable_popUpText__2FAhp:last-child {
    margin-bottom: 0; }

.SetStatusTable_visuallyHidden__2uGM_ {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.SetStatusTable_actionsBtn__1JpXE {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .SetStatusTable_actionsBtn__1JpXE svg {
    color: #ed2224; }
  .SetStatusTable_actionsBtn__1JpXE:focus {
    outline: none; }

.SetStatusTable_actionsBtn__1JpXE.SetStatusTable_actionsBtnDisabled__iVXki {
  background: none;
  opacity: 0.4; }

.SetStatusTable_blocked__2Ysps td,
.SetStatusTable_blocked__2Ysps span {
  color: #cccccc !important; }

.SetStatusTable_blocked__2Ysps .ant-checkbox-input:focus + .ant-checkbox-inner,
.SetStatusTable_blocked__2Ysps .ant-checkbox-inner {
  border-color: #cccccc; }

.SetStatusTable_isAdmin__ULjzN td,
.SetStatusTable_isAdmin__ULjzN span,
.SetStatusTable_isAccountManager__2hUoR td,
.SetStatusTable_isAccountManager__2hUoR span {
  font-weight: bold !important; }

.SetStatusTable_ellipsis__2XpPT {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.SetStatusTable_modalWrap__3s2L_ {
  max-width: 335px;
  width: 100%; }

.SetStatusTable_title__1jOAO {
  font-size: 30px;
  margin-bottom: 30px;
  text-align: left; }

.SetStatusTable_subTitle__2OUwJ {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%; }

.SetStatusTable_radioWrap__-76-4 {
  position: relative; }
  .SetStatusTable_radioWrap__-76-4 .SetStatusTable_border__2_NJH {
    position: absolute;
    width: calc(100% + 15px);
    height: 85px;
    top: 50%;
    transform: translate(-10px, -55%);
    border: 1px solid #C8CCCC;
    border-radius: 4px; }

.SetStatusTable_btnWrap__22xUi {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 40px; }

.SetStatusTable_radio__F67aS {
  margin: 0 5px; }

.SetStatusTable_radioItemWrap__ag0ii {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 10px; }
  .SetStatusTable_radioItemWrap__ag0ii span {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 180%; }
    .SetStatusTable_radioItemWrap__ag0ii span::first-letter {
      text-transform: uppercase; }

.SetStatusTable_statusWpar__o9dpl {
  position: absolute;
  left: 27px;
  border: none;
  margin: 0 10px 0 0; }

.SetStatusTable_btnCancel__16vCS {
  background: #ffffff;
  border: 2px solid #ed2224;
  width: 100%; }

.SetStatusTable_btnDone__3ClaN {
  margin-right: 25px;
  color: #ffffff;
  background: #ed2224;
  width: 100%; }

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: red; }

.ant-radio + span {
  padding-left: 35px; }

.ant-radio-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.ant-radio-inner {
  border-color: #ed2224;
  width: 10px;
  height: 10px; }
  .ant-radio-inner::after {
    background-color: #ed2224;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    width: 5px;
    height: 5px; }

.ant-radio-checked .ant-radio-inner {
  border-color: #ed2224; }

.SetStatusTable_statusTableWpar__tl-LI {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .SetStatusTable_statusTableWpar__tl-LI::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.SetStatusTable_statusActive__2fN4Y::before {
  background: #28A181; }

.SetStatusTable_statusInActive__syiWz::before {
  background: #c8c8c8; }

.SetStatusTable_statusInActiveVoluntary__9SKVt::before {
  background: #2267ed; }

.SetStatusTable_statusInActiveInvoluntary__sBxNv::before {
  background: #f48e15; }

.SetStatusTable_warning__13z6J {
  color: #ed2224; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.JobSetTable_btn__JZCjE, .JobSetTable_btnNo__CzQjM, .JobSetTable_btnYes__W9Chq, .JobSetTable_stepsBtn__3P7h6 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .JobSetTable_btn__JZCjE:hover, .JobSetTable_btnNo__CzQjM:hover, .JobSetTable_btnYes__W9Chq:hover, .JobSetTable_stepsBtn__3P7h6:hover {
    background: #ed2224;
    color: #ffffff; }
  .JobSetTable_btn__JZCjE:active, .JobSetTable_btnNo__CzQjM:active, .JobSetTable_btnYes__W9Chq:active, .JobSetTable_stepsBtn__3P7h6:active {
    background: #DC2426;
    color: #ffffff; }

.JobSetTable_btnNo__CzQjM {
  width: 100px; }

.JobSetTable_btnYes__W9Chq {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.JobSetTable_statusWpar__3TTIi {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .JobSetTable_statusWpar__3TTIi::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.JobSetTable_statusActive__jQVbc::before {
  background: #28A181; }

.JobSetTable_statusInActive__3MJuW::before {
  background: #c8c8c8; }

.JobSetTable_arrow__gQh6c {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .JobSetTable_arrow__gQh6c:before, .JobSetTable_arrow__gQh6c:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .JobSetTable_arrow__gQh6c:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .JobSetTable_arrow__gQh6c:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.JobSetTable_sectionTitle__3wJUp {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.JobSetTable_popUpTitle__3wZQx {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.JobSetTable_popUpWrap__2u5M4 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.JobSetTable_popUpText__1V0xP {
  text-align: center; }
  .JobSetTable_popUpText__1V0xP:last-child {
    margin-bottom: 0; }

.JobSetTable_visuallyHidden__2YB4M {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.JobSetTable_actionsBtn__22RkF {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .JobSetTable_actionsBtn__22RkF svg {
    color: #ed2224; }
  .JobSetTable_actionsBtn__22RkF:focus {
    outline: none; }

.JobSetTable_actionsBtn__22RkF.JobSetTable_actionsBtnDisabled__pPSkh {
  background: none;
  opacity: 0.4; }

.JobSetTable_blocked__1k470 td,
.JobSetTable_blocked__1k470 span {
  color: #cccccc !important; }

.JobSetTable_blocked__1k470 .ant-checkbox-input:focus + .ant-checkbox-inner,
.JobSetTable_blocked__1k470 .ant-checkbox-inner {
  border-color: #cccccc; }

.JobSetTable_isAdmin__Ndlr2 td,
.JobSetTable_isAdmin__Ndlr2 span,
.JobSetTable_isAccountManager__3WYiQ td,
.JobSetTable_isAccountManager__3WYiQ span {
  font-weight: bold !important; }

.JobSetTable_ellipsis__2KVz0 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.JobSetTable_modalWrap__JfnM- {
  width: 500px !important; }
  .JobSetTable_modalWrap__JfnM- .JobSetTable_wrapper__2MnMV {
    height: 200px;
    padding: 25px; }

.JobSetTable_innerWrap__34Xut {
  height: 100%;
  width: 100%; }

.JobSetTable_content__1t9oM {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  height: 200px;
  width: 100%;
  padding: 50px; }

.JobSetTable_btnWrapper__1WCDj {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%; }

.JobSetTable_stepsBtn__3P7h6 {
  position: relative;
  background: #ffffff;
  border: 2px solid #ed2224;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px; }

.JobSetTable_pointer__1cd-Z {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 30px;
  cursor: pointer; }

.JobSetTable_noWrap__181Mr {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.JobSetTable_iconPencil__3-rG3 {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  left: 73px; }

.JobSetTable_link__3Q8IU {
  font-weight: bold;
  color: #2944ae;
  text-decoration: underline; }

.JobSetTable_errorText__xAgFE {
  position: absolute;
  font-size: 10px;
  color: red; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.ConfirmDelSave_btn__qCYf7, .ConfirmDelSave_btnNo__1Hlm2, .ConfirmDelSave_btnYes__2kJRZ, .ConfirmDelSave_stepsBtn__2kQwK {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .ConfirmDelSave_btn__qCYf7:hover, .ConfirmDelSave_btnNo__1Hlm2:hover, .ConfirmDelSave_btnYes__2kJRZ:hover, .ConfirmDelSave_stepsBtn__2kQwK:hover {
    background: #ed2224;
    color: #ffffff; }
  .ConfirmDelSave_btn__qCYf7:active, .ConfirmDelSave_btnNo__1Hlm2:active, .ConfirmDelSave_btnYes__2kJRZ:active, .ConfirmDelSave_stepsBtn__2kQwK:active {
    background: #DC2426;
    color: #ffffff; }

.ConfirmDelSave_btnNo__1Hlm2 {
  width: 100px; }

.ConfirmDelSave_btnYes__2kJRZ {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.ConfirmDelSave_statusWpar__15x-u {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .ConfirmDelSave_statusWpar__15x-u::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.ConfirmDelSave_statusActive__23vFf::before {
  background: #28A181; }

.ConfirmDelSave_statusInActive__3_hOE::before {
  background: #c8c8c8; }

.ConfirmDelSave_arrow__1OJZB {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .ConfirmDelSave_arrow__1OJZB:before, .ConfirmDelSave_arrow__1OJZB:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .ConfirmDelSave_arrow__1OJZB:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .ConfirmDelSave_arrow__1OJZB:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.ConfirmDelSave_sectionTitle__1Heut {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.ConfirmDelSave_popUpTitle__2o2kv {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.ConfirmDelSave_popUpWrap__FsX3z {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.ConfirmDelSave_popUpText__3LvYS {
  text-align: center; }
  .ConfirmDelSave_popUpText__3LvYS:last-child {
    margin-bottom: 0; }

.ConfirmDelSave_visuallyHidden__1WP65 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.ConfirmDelSave_actionsBtn__2CYBL {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .ConfirmDelSave_actionsBtn__2CYBL svg {
    color: #ed2224; }
  .ConfirmDelSave_actionsBtn__2CYBL:focus {
    outline: none; }

.ConfirmDelSave_actionsBtn__2CYBL.ConfirmDelSave_actionsBtnDisabled__z3B1o {
  background: none;
  opacity: 0.4; }

.ConfirmDelSave_blocked__2ox-h td,
.ConfirmDelSave_blocked__2ox-h span {
  color: #cccccc !important; }

.ConfirmDelSave_blocked__2ox-h .ant-checkbox-input:focus + .ant-checkbox-inner,
.ConfirmDelSave_blocked__2ox-h .ant-checkbox-inner {
  border-color: #cccccc; }

.ConfirmDelSave_isAdmin__2Yt9s td,
.ConfirmDelSave_isAdmin__2Yt9s span,
.ConfirmDelSave_isAccountManager__3Ahph td,
.ConfirmDelSave_isAccountManager__3Ahph span {
  font-weight: bold !important; }

.ConfirmDelSave_ellipsis__UTOOV {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.ConfirmDelSave_modalWrap__2gQeV {
  width: 500px !important; }
  .ConfirmDelSave_modalWrap__2gQeV .ConfirmDelSave_wrapper__1YJ9X {
    height: 400px;
    padding: 25px; }

.ConfirmDelSave_disabled__EDIWm {
  opacity: 0.5; }
  .ConfirmDelSave_disabled__EDIWm * {
    cursor: not-allowed !important; }

.ConfirmDelSave_innerWrap__2AIaR {
  height: 100%;
  width: 100%; }

.ConfirmDelSave_content__SwqwP {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: start;
      align-items: flex-start;
  max-height: 400px;
  width: 100%;
  padding: 50px; }
  .ConfirmDelSave_content__SwqwP .ConfirmDelSave_text__2Hi0Z {
    margin: 15px 10px 20px 10px;
    font-size: 10px; }
  .ConfirmDelSave_content__SwqwP .ant-input:focus {
    border-color: #ed2224;
    --antd-wave-shadow-color: $mainBtn;
    box-shadow: 0 0 0 2px #e76566; }
  .ConfirmDelSave_content__SwqwP .ant-input:hover {
    border-color: #ed2224; }

.ConfirmDelSave_btnWrapper__3nyxc {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%; }

.ConfirmDelSave_stepsBtn__2kQwK {
  position: relative;
  background: #ffffff;
  border: 2px solid #ed2224;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px; }

.ConfirmDelSave_pointer__3OCdT {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 30px;
  cursor: pointer; }

.ConfirmDelSave_noWrap__3J4Ln {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.ConfirmDelSave_textQuestion__1KSC5 {
  margin: 0 10px 20px 10px;
  font-size: 16px; }

.ConfirmDelSave_iconPencil__2WNbf {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  left: 73px; }

.ConfirmDelSave_link__JAxXA {
  font-weight: bold;
  color: #2944ae;
  text-decoration: underline; }

.ConfirmDelSave_errorText__2BxTa {
  position: absolute;
  font-size: 10px;
  color: red; }

.SortingTitle_sortImg__3T7TE {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  word-break: normal; }
  .SortingTitle_sortImg__3T7TE img {
    cursor: pointer;
    margin-left: 5px;
    height: 14px; }

.DatePickerCell_pointer__18S5L {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  height: 30px;
  cursor: pointer; }

.DatePickerCell_noWrap__24skm {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis; }

.DatePickerCell_iconPencil__IFCSv {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  right: 0; }

.DatePickerCell_pickerActions__2nq0_ {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 5px  0; }
  .DatePickerCell_pickerActions__2nq0_ button:first-child {
    margin-right: 8px; }
  .DatePickerCell_pickerActions__2nq0_ button {
    width: 50%; }

.ant-calendar-input {
  pointer-events: none; }

.ant-calendar-time-picker-btn, .ant-calendar-ok-btn {
  display: none !important; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.DataSetTable_btn__10Aef, .DataSetTable_btnNo__6Ec1t, .DataSetTable_btnYes__adT7v {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .DataSetTable_btn__10Aef:hover, .DataSetTable_btnNo__6Ec1t:hover, .DataSetTable_btnYes__adT7v:hover {
    background: #ed2224;
    color: #ffffff; }
  .DataSetTable_btn__10Aef:active, .DataSetTable_btnNo__6Ec1t:active, .DataSetTable_btnYes__adT7v:active {
    background: #DC2426;
    color: #ffffff; }

.DataSetTable_btnNo__6Ec1t {
  width: 100px; }

.DataSetTable_btnYes__adT7v {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.DataSetTable_statusWpar__17Bo3 {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .DataSetTable_statusWpar__17Bo3::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.DataSetTable_statusActive__ayVFT::before {
  background: #28A181; }

.DataSetTable_statusInActive__3lCqV::before {
  background: #c8c8c8; }

.DataSetTable_arrow__3GXlf {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .DataSetTable_arrow__3GXlf:before, .DataSetTable_arrow__3GXlf:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .DataSetTable_arrow__3GXlf:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .DataSetTable_arrow__3GXlf:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.DataSetTable_sectionTitle__VoSHb {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.DataSetTable_popUpTitle__141iq {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.DataSetTable_popUpWrap__1QKck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.DataSetTable_popUpText__3BkuL {
  text-align: center; }
  .DataSetTable_popUpText__3BkuL:last-child {
    margin-bottom: 0; }

.DataSetTable_visuallyHidden__2ExEp {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.DataSetTable_actionsBtn__1wTkh {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .DataSetTable_actionsBtn__1wTkh svg {
    color: #ed2224; }
  .DataSetTable_actionsBtn__1wTkh:focus {
    outline: none; }

.DataSetTable_actionsBtn__1wTkh.DataSetTable_actionsBtnDisabled__1FG87 {
  background: none;
  opacity: 0.4; }

.DataSetTable_blocked__3H__Y td,
.DataSetTable_blocked__3H__Y span {
  color: #cccccc !important; }

.DataSetTable_blocked__3H__Y .ant-checkbox-input:focus + .ant-checkbox-inner,
.DataSetTable_blocked__3H__Y .ant-checkbox-inner {
  border-color: #cccccc; }

.DataSetTable_isAdmin__2wh3V td,
.DataSetTable_isAdmin__2wh3V span,
.DataSetTable_isAccountManager__3oS2i td,
.DataSetTable_isAccountManager__3oS2i span {
  font-weight: bold !important; }

.DataSetTable_ellipsis__1SVLB {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.DataSetTable_formInput__2OFfx {
  width: 100%;
  margin: 0 10px 0 0; }
  .DataSetTable_formInput__2OFfx input {
    font-size: 14px; }

.DataSetTable_inputSection__2WG-p, .DataSetTable_companySelectHasValue__2bfAu,
.DataSetTable_usersSelectHasValue__3lbkn,
.DataSetTable_datasetHasValues__2EP0I {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .DataSetTable_inputSection__2WG-p .ant-select, .DataSetTable_companySelectHasValue__2bfAu .ant-select, .DataSetTable_usersSelectHasValue__3lbkn .ant-select, .DataSetTable_datasetHasValues__2EP0I .ant-select {
    color: #424142; }
  .DataSetTable_inputSection__2WG-p .ant-select-selection__placeholder, .DataSetTable_companySelectHasValue__2bfAu .ant-select-selection__placeholder, .DataSetTable_usersSelectHasValue__3lbkn .ant-select-selection__placeholder, .DataSetTable_datasetHasValues__2EP0I .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .DataSetTable_inputSection__2WG-p .ant-select-selection__rendered, .DataSetTable_companySelectHasValue__2bfAu .ant-select-selection__rendered, .DataSetTable_usersSelectHasValue__3lbkn .ant-select-selection__rendered, .DataSetTable_datasetHasValues__2EP0I .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .DataSetTable_inputSection__2WG-p .ant-select-selection, .DataSetTable_companySelectHasValue__2bfAu .ant-select-selection, .DataSetTable_usersSelectHasValue__3lbkn .ant-select-selection, .DataSetTable_datasetHasValues__2EP0I .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .DataSetTable_inputSection__2WG-p .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .DataSetTable_companySelectHasValue__2bfAu .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .DataSetTable_usersSelectHasValue__3lbkn .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .DataSetTable_datasetHasValues__2EP0I .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .DataSetTable_inputSection__2WG-p .ant-select-focused .ant-select-selection:focus, .DataSetTable_companySelectHasValue__2bfAu .ant-select-focused .ant-select-selection:focus, .DataSetTable_usersSelectHasValue__3lbkn .ant-select-focused .ant-select-selection:focus, .DataSetTable_datasetHasValues__2EP0I .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.DataSetTable_addUserPasswordSections__3H-Am {
  margin-bottom: 30px; }

.DataSetTable_companySelectHasValue__2bfAu .ant-select-selection__placeholder,
.DataSetTable_usersSelectHasValue__3lbkn .ant-select-selection__placeholder,
.DataSetTable_datasetHasValues__2EP0I .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.DataSetTable_inputSectionLabel__M3xXp {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.DataSetTable_btnWrap___B33O, .DataSetTable_btnWrapSingle__3737S {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .DataSetTable_btnWrap___B33O button, .DataSetTable_btnWrapSingle__3737S button {
    width: 100%; }

.DataSetTable_btnWrapSingle__3737S button {
  width: 200px; }

.DataSetTable_editBtn__l19hM {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .DataSetTable_editBtn__l19hM svg {
    font-size: 14px; }
    .DataSetTable_editBtn__l19hM svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.DataSetTable_tableWrap__2tH1p .ant-table-wrapper {
  background: #ffffff; }
  .DataSetTable_tableWrap__2tH1p .ant-table-wrapper table,
  .DataSetTable_tableWrap__2tH1p .ant-table-wrapper tr,
  .DataSetTable_tableWrap__2tH1p .ant-table-wrapper th,
  .DataSetTable_tableWrap__2tH1p .ant-table-wrapper td {
    border-color: #C8CCCC !important; }
  .DataSetTable_tableWrap__2tH1p .ant-table-wrapper th {
    border-radius: 0 !important;
    text-align: center; }

.DataSetTable_tableWrap__2tH1p .ant-table-thead th {
  background: #666261;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  height: 40px;
  padding: 10px 20px; }

.DataSetTable_tableWrap__2tH1p .ant-table-thead .ant-table-selection-column span {
  display: none; }

.DataSetTable_tableWrap__2tH1p .ant-table-tbody tr {
  text-align: center; }

.DataSetTable_tableWrap__2tH1p .ant-table-tbody tr:nth-child(even) {
  background: #F2F2F2; }

.DataSetTable_tableWrap__2tH1p .ant-table-tbody tr:hover:not(.ant-table-row-selected) > td {
  background: transparent !important; }

.DataSetTable_tableWrap__2tH1p .ant-table-tbody td {
  color: #424142;
  font-size: 14px; }

.DataSetTable_tableWrap__2tH1p .ant-table-tbody tr.ant-table-row-selected td {
  background: #ff9091;
  color: #ffffff; }
  .DataSetTable_tableWrap__2tH1p .ant-table-tbody tr.ant-table-row-selected td span {
    color: #ffffff; }

.DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw .DataSetTable_formInput__2OFfx {
    transition: .1s;
    margin-bottom: 1px; }
    .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw .DataSetTable_formInput__2OFfx input {
      /*text-align: center;*/
      transition: .1s;
      padding-right: 5px; }
      .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw .DataSetTable_formInput__2OFfx input:focus {
        text-align: left; }
    .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw .DataSetTable_formInput__2OFfx.DataSetTable_disabled__39NDe {
      border-color: transparent; }
      .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw .DataSetTable_formInput__2OFfx.DataSetTable_disabled__39NDe input {
        overflow: hidden;
        text-overflow: ellipsis; }
  .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw button {
    position: absolute;
    right: 0;
    margin-bottom: 3px;
    cursor: pointer;
    border: 0;
    background: transparent; }
    .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw button svg {
      width: 10px; }
      .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw button svg path {
        fill: #ed2224; }
    .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw button[disabled] {
      cursor: not-allowed; }
      .DataSetTable_tableWrap__2tH1p .DataSetTable_datasetName__3gmSw button[disabled] svg path {
        fill: rgba(237, 34, 36, 0.3); }

.DataSetTable_tableWrap__2tH1p .DataSetTable_owned__1TaWj td, .DataSetTable_tableWrap__2tH1p .DataSetTable_owned__1TaWj input {
  font-weight: 800; }

.DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center; }
  .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph svg {
    color: #ed2224;
    width: 19px;
    height: 25px; }
  .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_download__2_53c, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_upload__3rR_V, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_delete__xHjVo {
    background: transparent;
    border: 0;
    cursor: pointer; }
    .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_download__2_53c button:disabled, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_upload__3rR_V button:disabled, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_delete__xHjVo button:disabled {
      background: transparent; }
      .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_download__2_53c button:disabled svg, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_upload__3rR_V button:disabled svg, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_delete__xHjVo button:disabled svg {
        color: rgba(237, 34, 36, 0.3); }
    .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_download__2_53c .ant-upload-disabled, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_upload__3rR_V .ant-upload-disabled, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_delete__xHjVo .ant-upload-disabled {
      width: 100%; }
      .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_download__2_53c .ant-upload-disabled svg, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_upload__3rR_V .ant-upload-disabled svg, .DataSetTable_tableWrap__2tH1p .DataSetTable_actionsTableCol__2gJph .DataSetTable_delete__xHjVo .ant-upload-disabled svg {
        color: rgba(237, 34, 36, 0.3); }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.CreateDataSetModal_btn__2LW1w, .CreateDataSetModal_btnNo__2GKuv, .CreateDataSetModal_btnYes__1XWwD {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .CreateDataSetModal_btn__2LW1w:hover, .CreateDataSetModal_btnNo__2GKuv:hover, .CreateDataSetModal_btnYes__1XWwD:hover {
    background: #ed2224;
    color: #ffffff; }
  .CreateDataSetModal_btn__2LW1w:active, .CreateDataSetModal_btnNo__2GKuv:active, .CreateDataSetModal_btnYes__1XWwD:active {
    background: #DC2426;
    color: #ffffff; }

.CreateDataSetModal_btnNo__2GKuv {
  width: 100px; }

.CreateDataSetModal_btnYes__1XWwD {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.CreateDataSetModal_statusWpar__18THS {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .CreateDataSetModal_statusWpar__18THS::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.CreateDataSetModal_statusActive__2h3c_::before {
  background: #28A181; }

.CreateDataSetModal_statusInActive__2yqR5::before {
  background: #c8c8c8; }

.CreateDataSetModal_arrow__1ra6I {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .CreateDataSetModal_arrow__1ra6I:before, .CreateDataSetModal_arrow__1ra6I:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .CreateDataSetModal_arrow__1ra6I:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .CreateDataSetModal_arrow__1ra6I:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.CreateDataSetModal_sectionTitle__34KwJ {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.CreateDataSetModal_popUpTitle__y7QCi, .CreateDataSetModal_modalWrap__1RFZY .CreateDataSetModal_innerWrap__Fn0Q1 .CreateDataSetModal_title__e1BzK {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.CreateDataSetModal_popUpWrap__JxVI3 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.CreateDataSetModal_popUpText__1IsnZ {
  text-align: center; }
  .CreateDataSetModal_popUpText__1IsnZ:last-child {
    margin-bottom: 0; }

.CreateDataSetModal_visuallyHidden__3MRNP {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.CreateDataSetModal_actionsBtn__2UVQX {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .CreateDataSetModal_actionsBtn__2UVQX svg {
    color: #ed2224; }
  .CreateDataSetModal_actionsBtn__2UVQX:focus {
    outline: none; }

.CreateDataSetModal_actionsBtn__2UVQX.CreateDataSetModal_actionsBtnDisabled__2PJrI {
  background: none;
  opacity: 0.4; }

.CreateDataSetModal_blocked__2AZAR td,
.CreateDataSetModal_blocked__2AZAR span {
  color: #cccccc !important; }

.CreateDataSetModal_blocked__2AZAR .ant-checkbox-input:focus + .ant-checkbox-inner,
.CreateDataSetModal_blocked__2AZAR .ant-checkbox-inner {
  border-color: #cccccc; }

.CreateDataSetModal_isAdmin__2yYId td,
.CreateDataSetModal_isAdmin__2yYId span,
.CreateDataSetModal_isAccountManager__2BNsD td,
.CreateDataSetModal_isAccountManager__2BNsD span {
  font-weight: bold !important; }

.CreateDataSetModal_ellipsis__3-bSv {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.CreateDataSetModal_formInput__1KBF_ {
  width: 100%;
  margin: 0 10px 0 0; }
  .CreateDataSetModal_formInput__1KBF_ input {
    font-size: 14px; }

.CreateDataSetModal_inputSection__2O5oE, .CreateDataSetModal_companySelectHasValue__3wkqx,
.CreateDataSetModal_usersSelectHasValue__1mSf3,
.CreateDataSetModal_datasetHasValues__25Hut {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .CreateDataSetModal_inputSection__2O5oE .ant-select, .CreateDataSetModal_companySelectHasValue__3wkqx .ant-select, .CreateDataSetModal_usersSelectHasValue__1mSf3 .ant-select, .CreateDataSetModal_datasetHasValues__25Hut .ant-select {
    color: #424142; }
  .CreateDataSetModal_inputSection__2O5oE .ant-select-selection__placeholder, .CreateDataSetModal_companySelectHasValue__3wkqx .ant-select-selection__placeholder, .CreateDataSetModal_usersSelectHasValue__1mSf3 .ant-select-selection__placeholder, .CreateDataSetModal_datasetHasValues__25Hut .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .CreateDataSetModal_inputSection__2O5oE .ant-select-selection__rendered, .CreateDataSetModal_companySelectHasValue__3wkqx .ant-select-selection__rendered, .CreateDataSetModal_usersSelectHasValue__1mSf3 .ant-select-selection__rendered, .CreateDataSetModal_datasetHasValues__25Hut .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .CreateDataSetModal_inputSection__2O5oE .ant-select-selection, .CreateDataSetModal_companySelectHasValue__3wkqx .ant-select-selection, .CreateDataSetModal_usersSelectHasValue__1mSf3 .ant-select-selection, .CreateDataSetModal_datasetHasValues__25Hut .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .CreateDataSetModal_inputSection__2O5oE .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CreateDataSetModal_companySelectHasValue__3wkqx .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CreateDataSetModal_usersSelectHasValue__1mSf3 .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CreateDataSetModal_datasetHasValues__25Hut .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .CreateDataSetModal_inputSection__2O5oE .ant-select-focused .ant-select-selection:focus, .CreateDataSetModal_companySelectHasValue__3wkqx .ant-select-focused .ant-select-selection:focus, .CreateDataSetModal_usersSelectHasValue__1mSf3 .ant-select-focused .ant-select-selection:focus, .CreateDataSetModal_datasetHasValues__25Hut .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.CreateDataSetModal_addUserPasswordSections__2nQuw {
  margin-bottom: 30px; }

.CreateDataSetModal_companySelectHasValue__3wkqx .ant-select-selection__placeholder,
.CreateDataSetModal_usersSelectHasValue__1mSf3 .ant-select-selection__placeholder,
.CreateDataSetModal_datasetHasValues__25Hut .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.CreateDataSetModal_inputSectionLabel__9U45I {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.CreateDataSetModal_btnWrap__2V4nI, .CreateDataSetModal_btnWrapSingle__2o8wC {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .CreateDataSetModal_btnWrap__2V4nI button, .CreateDataSetModal_btnWrapSingle__2o8wC button {
    width: 100%; }

.CreateDataSetModal_btnWrapSingle__2o8wC button {
  width: 200px; }

.CreateDataSetModal_editBtn__1aVtL {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .CreateDataSetModal_editBtn__1aVtL svg {
    font-size: 14px; }
    .CreateDataSetModal_editBtn__1aVtL svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.CreateDataSetModal_modalWrap__1RFZY .CreateDataSetModal_innerWrap__Fn0Q1 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }
  .CreateDataSetModal_modalWrap__1RFZY .CreateDataSetModal_innerWrap__Fn0Q1 .CreateDataSetModal_title__e1BzK {
    padding-bottom: 10px;
    margin-bottom: 5px;
    font-size: 30px; }
  .CreateDataSetModal_modalWrap__1RFZY .CreateDataSetModal_innerWrap__Fn0Q1 form {
    padding: 60px 20px 10px; }
    .CreateDataSetModal_modalWrap__1RFZY .CreateDataSetModal_innerWrap__Fn0Q1 form .CreateDataSetModal_formInput__1KBF_ {
      margin-bottom: 70px; }

.CreateDataSetModal_modalConfirm__3Tkec {
  width: 400px !important; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Home_btn__3eGpI, .Home_btnNo__37Ypn, .Home_btnYes__T5x6N, .Home_dataSetBtn__Ls0vo, .Home_stepsBtn__1uNxD {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Home_btn__3eGpI:hover, .Home_btnNo__37Ypn:hover, .Home_btnYes__T5x6N:hover, .Home_dataSetBtn__Ls0vo:hover, .Home_stepsBtn__1uNxD:hover {
    background: #ed2224;
    color: #ffffff; }
  .Home_btn__3eGpI:active, .Home_btnNo__37Ypn:active, .Home_btnYes__T5x6N:active, .Home_dataSetBtn__Ls0vo:active, .Home_stepsBtn__1uNxD:active {
    background: #DC2426;
    color: #ffffff; }

.Home_btnNo__37Ypn {
  width: 100px; }

.Home_btnYes__T5x6N {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Home_statusWpar__4OMhA {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Home_statusWpar__4OMhA::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Home_statusActive__2O7gT::before {
  background: #28A181; }

.Home_statusInActive__1wUaI::before {
  background: #c8c8c8; }

.Home_arrow__2xE25 {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Home_arrow__2xE25:before, .Home_arrow__2xE25:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Home_arrow__2xE25:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Home_arrow__2xE25:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Home_sectionTitle__4Yv11, .Home_title__1Ywb1 {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Home_popUpTitle__2-IdF, .Home_modalWrap__3f3IC .Home_innerWrap__2b4mj .Home_title__1Ywb1 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Home_popUpWrap__1mftw {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Home_popUpText__2ciMH {
  text-align: center; }
  .Home_popUpText__2ciMH:last-child {
    margin-bottom: 0; }

.Home_visuallyHidden__2CgT3 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Home_actionsBtn__36YfF {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Home_actionsBtn__36YfF svg {
    color: #ed2224; }
  .Home_actionsBtn__36YfF:focus {
    outline: none; }

.Home_actionsBtn__36YfF.Home_actionsBtnDisabled__13yDd {
  background: none;
  opacity: 0.4; }

.Home_blocked__3-nQB td,
.Home_blocked__3-nQB span {
  color: #cccccc !important; }

.Home_blocked__3-nQB .ant-checkbox-input:focus + .ant-checkbox-inner,
.Home_blocked__3-nQB .ant-checkbox-inner {
  border-color: #cccccc; }

.Home_isAdmin__47owt td,
.Home_isAdmin__47owt span,
.Home_isAccountManager__3xMgl td,
.Home_isAccountManager__3xMgl span {
  font-weight: bold !important; }

.Home_ellipsis__19cnM {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Home_formInput__YC-Iq {
  width: 100%;
  margin: 0 10px 0 0; }
  .Home_formInput__YC-Iq input {
    font-size: 14px; }

.Home_inputSection__2gCAY, .Home_companySelectHasValue__1MfjE,
.Home_usersSelectHasValue__28Q0Z,
.Home_datasetHasValues__22tJr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .Home_inputSection__2gCAY .ant-select, .Home_companySelectHasValue__1MfjE .ant-select, .Home_usersSelectHasValue__28Q0Z .ant-select, .Home_datasetHasValues__22tJr .ant-select {
    color: #424142; }
  .Home_inputSection__2gCAY .ant-select-selection__placeholder, .Home_companySelectHasValue__1MfjE .ant-select-selection__placeholder, .Home_usersSelectHasValue__28Q0Z .ant-select-selection__placeholder, .Home_datasetHasValues__22tJr .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .Home_inputSection__2gCAY .ant-select-selection__rendered, .Home_companySelectHasValue__1MfjE .ant-select-selection__rendered, .Home_usersSelectHasValue__28Q0Z .ant-select-selection__rendered, .Home_datasetHasValues__22tJr .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .Home_inputSection__2gCAY .ant-select-selection, .Home_companySelectHasValue__1MfjE .ant-select-selection, .Home_usersSelectHasValue__28Q0Z .ant-select-selection, .Home_datasetHasValues__22tJr .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .Home_inputSection__2gCAY .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Home_companySelectHasValue__1MfjE .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Home_usersSelectHasValue__28Q0Z .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .Home_datasetHasValues__22tJr .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .Home_inputSection__2gCAY .ant-select-focused .ant-select-selection:focus, .Home_companySelectHasValue__1MfjE .ant-select-focused .ant-select-selection:focus, .Home_usersSelectHasValue__28Q0Z .ant-select-focused .ant-select-selection:focus, .Home_datasetHasValues__22tJr .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.Home_addUserPasswordSections__2N3ZW {
  margin-bottom: 30px; }

.Home_companySelectHasValue__1MfjE .ant-select-selection__placeholder,
.Home_usersSelectHasValue__28Q0Z .ant-select-selection__placeholder,
.Home_datasetHasValues__22tJr .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.Home_inputSectionLabel__2-p8M {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.Home_btnWrap__3bDx9, .Home_btnWrapSingle__SkDAx {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .Home_btnWrap__3bDx9 button, .Home_btnWrapSingle__SkDAx button {
    width: 100%; }

.Home_btnWrapSingle__SkDAx button {
  width: 200px; }

.Home_editBtn__1Wi4D {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .Home_editBtn__1Wi4D svg {
    font-size: 14px; }
    .Home_editBtn__1Wi4D svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.Home_wrapper__P7DbZ {
  margin-top: 30px;
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px; }
  .Home_wrapper__P7DbZ .ant-upload {
    width: 100%; }

.Home_title__1Ywb1 {
  margin: 20px 0;
  text-transform: none; }

.Home_titleTwo__hfT-v {
  margin: 75px 0 50px; }

.Home_subTitle__3l-2Z {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 20px; }

.Home_dataSetBtn__Ls0vo {
  position: relative;
  border: 2px solid #ed2224;
  max-width: 200px;
  width: 100%;
  margin: auto 0 22px auto;
  height: 40px;
  -ms-flex-align: center;
      align-items: center; }
  .Home_dataSetBtn__Ls0vo svg {
    margin-right: 14px;
    font-size: 17px;
    color: red; }
  .Home_dataSetBtn__Ls0vo:hover svg {
    color: white;
    /*rect {
        fill: white;
      }*/ }

.Home_modalWrap__3f3IC .Home_innerWrap__2b4mj {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }
  .Home_modalWrap__3f3IC .Home_innerWrap__2b4mj .Home_title__1Ywb1 {
    padding-bottom: 10px;
    margin-bottom: 5px;
    font-size: 30px; }
  .Home_modalWrap__3f3IC .Home_innerWrap__2b4mj form {
    padding: 60px 20px 10px; }
    .Home_modalWrap__3f3IC .Home_innerWrap__2b4mj form .Home_formInput__YC-Iq {
      margin-bottom: 70px; }

.Home_modalConfirm__1XShK {
  width: 400px !important; }

.Home_stepsWrap__lRJeo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between; }
  @media only screen and (max-width: 1024px) {
    .Home_stepsWrap__lRJeo {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }

.Home_stepsWrapItem__23B3H {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  max-width: 400px;
  width: 100%;
  background: #ffffff;
  padding: 30px 49px 50px;
  margin-bottom: 5px;
  border: 1px solid #C8CCCC;
  border-radius: 4px; }
  .Home_stepsWrapItem__23B3H:not(:last-child) {
    margin-right: 5px; }

.Home_stepsTitleWrap__2PAoE {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-bottom: 27px; }

.Home_stepsTitle__2CnRi, .Home_descriptionTitle__2oqw3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-left: 20px;
  color: #ed2224;
  margin-bottom: 0; }

.Home_stepsDescription__3TyZ1, .Home_descriptionText__30kea {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #424142;
  margin-bottom: 30px; }

.Home_stepsBtn__1uNxD {
  position: relative;
  background: #ffffff;
  border: 2px solid #ed2224;
  max-width: 200px;
  width: 100%;
  margin: auto auto 0 auto;
  height: 40px; }

.Home_stepsTime__F0Jv8 {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  margin: 0; }
  .Home_stepsTime__F0Jv8 span {
    margin-left: 5px; }

.Home_descriptionWrap__105r- {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin: 0 40px; }
  @media only screen and (max-width: 1024px) {
    .Home_descriptionWrap__105r- {
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; } }

.Home_descriptionItem__1Rm1_ {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  max-width: 330px;
  width: 100%;
  min-height: 295px;
  padding: 40px 45px 45px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  background: rgba(200, 204, 204, 0.15); }
  .Home_descriptionItem__1Rm1_:not(:last-child) {
    margin-right: 5px; }

.Home_descriptionTitle__2oqw3 {
  margin-top: 45px;
  margin-left: 0; }

.Home_descriptionText__30kea {
  width: 100%;
  margin: 15px 0 50px; }

.Home_refreshImg__ILqKq {
  margin-right: 10px;
  transform: scale(1.6); }
  .Home_refreshImg__ILqKq path {
    fill: #ed2224; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.QueueModal_btn__3HJTf, .QueueModal_btnNo__1kWqW, .QueueModal_btnYes__3gE8f {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .QueueModal_btn__3HJTf:hover, .QueueModal_btnNo__1kWqW:hover, .QueueModal_btnYes__3gE8f:hover {
    background: #ed2224;
    color: #ffffff; }
  .QueueModal_btn__3HJTf:active, .QueueModal_btnNo__1kWqW:active, .QueueModal_btnYes__3gE8f:active {
    background: #DC2426;
    color: #ffffff; }

.QueueModal_btnNo__1kWqW {
  width: 100px; }

.QueueModal_btnYes__3gE8f {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.QueueModal_statusWpar__6FflB {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .QueueModal_statusWpar__6FflB::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.QueueModal_statusActive__t37tM::before {
  background: #28A181; }

.QueueModal_statusInActive__3U9pe::before {
  background: #c8c8c8; }

.QueueModal_arrow__AQrEZ {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .QueueModal_arrow__AQrEZ:before, .QueueModal_arrow__AQrEZ:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .QueueModal_arrow__AQrEZ:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .QueueModal_arrow__AQrEZ:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.QueueModal_sectionTitle__f-dSL {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.QueueModal_popUpTitle__1x850, .QueueModal_modalWrap__29vrx .QueueModal_innerWrap__3_e6o .QueueModal_title__1XYBB {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.QueueModal_popUpWrap__9f0-g {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.QueueModal_popUpText__28oJ4 {
  text-align: center; }
  .QueueModal_popUpText__28oJ4:last-child {
    margin-bottom: 0; }

.QueueModal_visuallyHidden__v2JEl {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.QueueModal_actionsBtn__1I7Ak {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .QueueModal_actionsBtn__1I7Ak svg {
    color: #ed2224; }
  .QueueModal_actionsBtn__1I7Ak:focus {
    outline: none; }

.QueueModal_actionsBtn__1I7Ak.QueueModal_actionsBtnDisabled__1JyU2 {
  background: none;
  opacity: 0.4; }

.QueueModal_blocked__2EoPC td,
.QueueModal_blocked__2EoPC span {
  color: #cccccc !important; }

.QueueModal_blocked__2EoPC .ant-checkbox-input:focus + .ant-checkbox-inner,
.QueueModal_blocked__2EoPC .ant-checkbox-inner {
  border-color: #cccccc; }

.QueueModal_isAdmin__2MlVf td,
.QueueModal_isAdmin__2MlVf span,
.QueueModal_isAccountManager__prERe td,
.QueueModal_isAccountManager__prERe span {
  font-weight: bold !important; }

.QueueModal_ellipsis__1aesB {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.QueueModal_formInput__3F0MK {
  width: 100%;
  margin: 0 10px 0 0; }
  .QueueModal_formInput__3F0MK input {
    font-size: 14px; }

.QueueModal_inputSection__3i-ZQ, .QueueModal_companySelectHasValue__1cSnm,
.QueueModal_usersSelectHasValue__12EOv,
.QueueModal_datasetHasValues__2JQ1L {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .QueueModal_inputSection__3i-ZQ .ant-select, .QueueModal_companySelectHasValue__1cSnm .ant-select, .QueueModal_usersSelectHasValue__12EOv .ant-select, .QueueModal_datasetHasValues__2JQ1L .ant-select {
    color: #424142; }
  .QueueModal_inputSection__3i-ZQ .ant-select-selection__placeholder, .QueueModal_companySelectHasValue__1cSnm .ant-select-selection__placeholder, .QueueModal_usersSelectHasValue__12EOv .ant-select-selection__placeholder, .QueueModal_datasetHasValues__2JQ1L .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .QueueModal_inputSection__3i-ZQ .ant-select-selection__rendered, .QueueModal_companySelectHasValue__1cSnm .ant-select-selection__rendered, .QueueModal_usersSelectHasValue__12EOv .ant-select-selection__rendered, .QueueModal_datasetHasValues__2JQ1L .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .QueueModal_inputSection__3i-ZQ .ant-select-selection, .QueueModal_companySelectHasValue__1cSnm .ant-select-selection, .QueueModal_usersSelectHasValue__12EOv .ant-select-selection, .QueueModal_datasetHasValues__2JQ1L .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .QueueModal_inputSection__3i-ZQ .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .QueueModal_companySelectHasValue__1cSnm .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .QueueModal_usersSelectHasValue__12EOv .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .QueueModal_datasetHasValues__2JQ1L .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .QueueModal_inputSection__3i-ZQ .ant-select-focused .ant-select-selection:focus, .QueueModal_companySelectHasValue__1cSnm .ant-select-focused .ant-select-selection:focus, .QueueModal_usersSelectHasValue__12EOv .ant-select-focused .ant-select-selection:focus, .QueueModal_datasetHasValues__2JQ1L .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.QueueModal_addUserPasswordSections__3bwOI {
  margin-bottom: 30px; }

.QueueModal_companySelectHasValue__1cSnm .ant-select-selection__placeholder,
.QueueModal_usersSelectHasValue__12EOv .ant-select-selection__placeholder,
.QueueModal_datasetHasValues__2JQ1L .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.QueueModal_inputSectionLabel__1somu {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.QueueModal_btnWrap__1cPpN, .QueueModal_btnWrapSingle__J5ae8 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .QueueModal_btnWrap__1cPpN button, .QueueModal_btnWrapSingle__J5ae8 button {
    width: 100%; }

.QueueModal_btnWrapSingle__J5ae8 button {
  width: 200px; }

.QueueModal_editBtn__1AFyj {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .QueueModal_editBtn__1AFyj svg {
    font-size: 14px; }
    .QueueModal_editBtn__1AFyj svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.QueueModal_modalWrap__29vrx .QueueModal_innerWrap__3_e6o {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }
  .QueueModal_modalWrap__29vrx .QueueModal_innerWrap__3_e6o .QueueModal_title__1XYBB {
    padding-bottom: 10px;
    margin-bottom: 5px;
    font-size: 30px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Login_btn__vYapT, .Login_btnNo__1E69O, .Login_btnYes__3Hiaj, .Login_loginBtn__1Jizm {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Login_btn__vYapT:hover, .Login_btnNo__1E69O:hover, .Login_btnYes__3Hiaj:hover, .Login_loginBtn__1Jizm:hover {
    background: #ed2224;
    color: #ffffff; }
  .Login_btn__vYapT:active, .Login_btnNo__1E69O:active, .Login_btnYes__3Hiaj:active, .Login_loginBtn__1Jizm:active {
    background: #DC2426;
    color: #ffffff; }

.Login_btnNo__1E69O {
  width: 100px; }

.Login_btnYes__3Hiaj {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Login_statusWpar__2Juat {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Login_statusWpar__2Juat::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Login_statusActive__22GDM::before {
  background: #28A181; }

.Login_statusInActive__I9gS-::before {
  background: #c8c8c8; }

.Login_arrow__2UfLt {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Login_arrow__2UfLt:before, .Login_arrow__2UfLt:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Login_arrow__2UfLt:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Login_arrow__2UfLt:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Login_sectionTitle__3VlyS {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Login_popUpTitle__2VSWn {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Login_popUpWrap__svEIc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Login_popUpText__19gdU {
  text-align: center; }
  .Login_popUpText__19gdU:last-child {
    margin-bottom: 0; }

.Login_visuallyHidden__xYJXa {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Login_actionsBtn__1kKD3 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Login_actionsBtn__1kKD3 svg {
    color: #ed2224; }
  .Login_actionsBtn__1kKD3:focus {
    outline: none; }

.Login_actionsBtn__1kKD3.Login_actionsBtnDisabled__1hlC9 {
  background: none;
  opacity: 0.4; }

.Login_blocked__2OCpV td,
.Login_blocked__2OCpV span {
  color: #cccccc !important; }

.Login_blocked__2OCpV .ant-checkbox-input:focus + .ant-checkbox-inner,
.Login_blocked__2OCpV .ant-checkbox-inner {
  border-color: #cccccc; }

.Login_isAdmin__cX5DF td,
.Login_isAdmin__cX5DF span,
.Login_isAccountManager__2l5sz td,
.Login_isAccountManager__2l5sz span {
  font-weight: bold !important; }

.Login_ellipsis__2eFru {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Login_wrapper__3vqOW {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.Login_title__2Zedt {
  margin-top: 40px;
  font-size: 26px;
  color: #424142; }

.Login_errorText__2dbF1 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 124px;
  text-align: center;
  font-size: 14px;
  color: #ed2224; }

.Login_inner__TWpPh {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  width: 400px;
  padding: 40px; }

.Login_logoImg__2hd5g {
  width: 205px; }

form {
  width: 100%; }

.Login_loginBtn__1Jizm {
  margin: 40px auto 24px auto;
  width: 130px;
  color: #424142; }
  .Login_loginBtn__1Jizm span {
    margin-right: 10px;
    font-size: 20px; }

.Login_passwordInput__lTi0a {
  margin-bottom: 12px; }

.Login_forgotPassword__3BtcR {
  text-align: center;
  text-decoration: underline; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Confirmation_btn__2PkmC, .Confirmation_btnNo__1LbqX, .Confirmation_btnYes__31ECL, .Confirmation_setPasswordBtn__x2Fhg {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Confirmation_btn__2PkmC:hover, .Confirmation_btnNo__1LbqX:hover, .Confirmation_btnYes__31ECL:hover, .Confirmation_setPasswordBtn__x2Fhg:hover {
    background: #ed2224;
    color: #ffffff; }
  .Confirmation_btn__2PkmC:active, .Confirmation_btnNo__1LbqX:active, .Confirmation_btnYes__31ECL:active, .Confirmation_setPasswordBtn__x2Fhg:active {
    background: #DC2426;
    color: #ffffff; }

.Confirmation_btnNo__1LbqX {
  width: 100px; }

.Confirmation_btnYes__31ECL {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Confirmation_statusWpar__28HVR {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Confirmation_statusWpar__28HVR::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Confirmation_statusActive__3N5Rp::before {
  background: #28A181; }

.Confirmation_statusInActive__2uBsr::before {
  background: #c8c8c8; }

.Confirmation_arrow__1dJZV {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Confirmation_arrow__1dJZV:before, .Confirmation_arrow__1dJZV:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Confirmation_arrow__1dJZV:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Confirmation_arrow__1dJZV:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Confirmation_sectionTitle__V21uU {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Confirmation_popUpTitle__2-jw1 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Confirmation_popUpWrap__dq-C2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Confirmation_popUpText__3AzuU {
  text-align: center; }
  .Confirmation_popUpText__3AzuU:last-child {
    margin-bottom: 0; }

.Confirmation_visuallyHidden__3YnZ9 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Confirmation_actionsBtn__2jGBX {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Confirmation_actionsBtn__2jGBX svg {
    color: #ed2224; }
  .Confirmation_actionsBtn__2jGBX:focus {
    outline: none; }

.Confirmation_actionsBtn__2jGBX.Confirmation_actionsBtnDisabled__DJYgx {
  background: none;
  opacity: 0.4; }

.Confirmation_blocked__1fM_w td,
.Confirmation_blocked__1fM_w span {
  color: #cccccc !important; }

.Confirmation_blocked__1fM_w .ant-checkbox-input:focus + .ant-checkbox-inner,
.Confirmation_blocked__1fM_w .ant-checkbox-inner {
  border-color: #cccccc; }

.Confirmation_isAdmin__3paj2 td,
.Confirmation_isAdmin__3paj2 span,
.Confirmation_isAccountManager__UbK5X td,
.Confirmation_isAccountManager__UbK5X span {
  font-weight: bold !important; }

.Confirmation_ellipsis__j0S8y {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Confirmation_wrapper__tKQ1P {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.Confirmation_title__2-Vfo {
  margin-top: 40px;
  font-size: 26px;
  color: #424142; }

.Confirmation_errorText__2Vyt9 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 81px;
  text-align: center;
  font-size: 14px;
  color: #ed2224; }

.Confirmation_inner__2gkmf {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  width: 400px;
  padding: 40px; }

.Confirmation_logoImg__3FUYb {
  width: 205px; }

form {
  width: 100%; }

.Confirmation_setPasswordBtn__x2Fhg {
  margin: 0 auto;
  width: 160px;
  color: #424142; }
  .Confirmation_setPasswordBtn__x2Fhg span {
    margin-right: 10px;
    font-size: 20px; }

.Confirmation_subTitle__3ytuf {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  text-align: left;
  -ms-flex-item-align: start;
      align-self: flex-start; }
  .Confirmation_subTitle__3ytuf ul {
    margin-left: 20px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.ForgotPassword_btnNo__TD8fi, .ForgotPassword_btnYes__kqurf, .ForgotPassword_btn__1Z4_X {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .ForgotPassword_btnNo__TD8fi:hover, .ForgotPassword_btnYes__kqurf:hover, .ForgotPassword_btn__1Z4_X:hover {
    background: #ed2224;
    color: #ffffff; }
  .ForgotPassword_btnNo__TD8fi:active, .ForgotPassword_btnYes__kqurf:active, .ForgotPassword_btn__1Z4_X:active {
    background: #DC2426;
    color: #ffffff; }

.ForgotPassword_btnNo__TD8fi {
  width: 100px; }

.ForgotPassword_btnYes__kqurf {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.ForgotPassword_statusWpar__3ibJT {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .ForgotPassword_statusWpar__3ibJT::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.ForgotPassword_statusActive__Crfrh::before {
  background: #28A181; }

.ForgotPassword_statusInActive__16Tz0::before {
  background: #c8c8c8; }

.ForgotPassword_arrow__2ytJI {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .ForgotPassword_arrow__2ytJI:before, .ForgotPassword_arrow__2ytJI:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .ForgotPassword_arrow__2ytJI:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .ForgotPassword_arrow__2ytJI:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.ForgotPassword_sectionTitle__33_zE {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.ForgotPassword_popUpTitle__ruO3p {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.ForgotPassword_popUpWrap__2WZpc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.ForgotPassword_popUpText__38bJQ {
  text-align: center; }
  .ForgotPassword_popUpText__38bJQ:last-child {
    margin-bottom: 0; }

.ForgotPassword_visuallyHidden__1oFLi {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.ForgotPassword_actionsBtn__2A7XO {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .ForgotPassword_actionsBtn__2A7XO svg {
    color: #ed2224; }
  .ForgotPassword_actionsBtn__2A7XO:focus {
    outline: none; }

.ForgotPassword_actionsBtn__2A7XO.ForgotPassword_actionsBtnDisabled__1sdET {
  background: none;
  opacity: 0.4; }

.ForgotPassword_blocked__9xE-D td,
.ForgotPassword_blocked__9xE-D span {
  color: #cccccc !important; }

.ForgotPassword_blocked__9xE-D .ant-checkbox-input:focus + .ant-checkbox-inner,
.ForgotPassword_blocked__9xE-D .ant-checkbox-inner {
  border-color: #cccccc; }

.ForgotPassword_isAdmin__2Xygw td,
.ForgotPassword_isAdmin__2Xygw span,
.ForgotPassword_isAccountManager__3yeoc td,
.ForgotPassword_isAccountManager__3yeoc span {
  font-weight: bold !important; }

.ForgotPassword_ellipsis__2VApo {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.ForgotPassword_wrapper__1cxJT {
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex: 1 0 auto;
      flex: 1 0 auto; }

.ForgotPassword_title__O1lxP {
  margin-top: 40px;
  font-size: 26px;
  color: #424142; }

.ForgotPassword_errorText__5fvu_ {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 126px;
  text-align: center;
  font-size: 14px;
  color: #ed2224; }

.ForgotPassword_inner__2LFsz {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  width: 400px;
  padding: 40px; }

.ForgotPassword_logoImg__1ZGh0 {
  width: 205px; }

form {
  width: 100%; }

.ForgotPassword_btnNo__TD8fi, .ForgotPassword_btnYes__kqurf, .ForgotPassword_btn__1Z4_X {
  margin: 40px auto 24px auto;
  width: 160px;
  color: #424142; }
  .ForgotPassword_btnNo__TD8fi span, .ForgotPassword_btnYes__kqurf span, .ForgotPassword_btn__1Z4_X span {
    margin-right: 10px;
    font-size: 20px; }

.ForgotPassword_subTitle__1tymZ {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  text-align: center; }

.ForgotPassword_forgotPassword__4BonI {
  text-align: center;
  text-decoration: underline; }

.ForgotPassword_emailInput__3c_61 {
  margin-bottom: 12px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.DatasetBadges_btn__zM_yN, .DatasetBadges_btnNo__t3bxN, .DatasetBadges_btnYes__RbooF, .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 .DatasetBadges_badgesItem__2xlj7 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .DatasetBadges_btn__zM_yN:hover, .DatasetBadges_btnNo__t3bxN:hover, .DatasetBadges_btnYes__RbooF:hover, .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 .DatasetBadges_badgesItem__2xlj7:hover {
    background: #ed2224;
    color: #ffffff; }
  .DatasetBadges_btn__zM_yN:active, .DatasetBadges_btnNo__t3bxN:active, .DatasetBadges_btnYes__RbooF:active, .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 .DatasetBadges_badgesItem__2xlj7:active {
    background: #DC2426;
    color: #ffffff; }

.DatasetBadges_btnNo__t3bxN {
  width: 100px; }

.DatasetBadges_btnYes__RbooF {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.DatasetBadges_statusWpar__3FtvV {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .DatasetBadges_statusWpar__3FtvV::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.DatasetBadges_statusActive__2J2G4::before {
  background: #28A181; }

.DatasetBadges_statusInActive__BSjEn::before {
  background: #c8c8c8; }

.DatasetBadges_arrow__1uuZZ {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .DatasetBadges_arrow__1uuZZ:before, .DatasetBadges_arrow__1uuZZ:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .DatasetBadges_arrow__1uuZZ:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .DatasetBadges_arrow__1uuZZ:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.DatasetBadges_sectionTitle__3SNq- {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.DatasetBadges_popUpTitle__3f196 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.DatasetBadges_popUpWrap__2c04m {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.DatasetBadges_popUpText__1CWO5 {
  text-align: center; }
  .DatasetBadges_popUpText__1CWO5:last-child {
    margin-bottom: 0; }

.DatasetBadges_visuallyHidden__2EPzX {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.DatasetBadges_actionsBtn__3ssgN {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .DatasetBadges_actionsBtn__3ssgN svg {
    color: #ed2224; }
  .DatasetBadges_actionsBtn__3ssgN:focus {
    outline: none; }

.DatasetBadges_actionsBtn__3ssgN.DatasetBadges_actionsBtnDisabled__369qV {
  background: none;
  opacity: 0.4; }

.DatasetBadges_blocked__US_oo td,
.DatasetBadges_blocked__US_oo span {
  color: #cccccc !important; }

.DatasetBadges_blocked__US_oo .ant-checkbox-input:focus + .ant-checkbox-inner,
.DatasetBadges_blocked__US_oo .ant-checkbox-inner {
  border-color: #cccccc; }

.DatasetBadges_isAdmin__2mkf0 td,
.DatasetBadges_isAdmin__2mkf0 span,
.DatasetBadges_isAccountManager__1xlU7 td,
.DatasetBadges_isAccountManager__1xlU7 span {
  font-weight: bold !important; }

.DatasetBadges_ellipsis__Q1_vs, .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 .DatasetBadges_badgesItem__2xlj7 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

/*@import '../../../../styles/popUpForms';*/
.DatasetBadges_badgesWrap__DS07B {
  padding: 11px 0 17px; }
  .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badgesTitle__pZUYD {
    font-size: 15px;
    line-height: 22px;
    color: #2E384D;
    margin-bottom: 11px; }
  .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    /*margin-right: -45px;*/
    /*overflow: hidden;
    transition: .2s;*/ }
    .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 .DatasetBadges_badgesItem__2xlj7 {
      display: block;
      border-color: #414042;
      margin-right: 45px;
      margin-bottom: 22.5px;
      width: 100px;
      padding: 8px;
      font-size: 14px;
      line-height: 1;
      transition: .2s; }
      .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 .DatasetBadges_badgesItem__2xlj7:hover {
        background: #FAC5C5; }
      .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 .DatasetBadges_badgesItem__2xlj7.DatasetBadges_selected__1UUWk {
        background: #FAC5C5;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-color: red;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25); }
    .DatasetBadges_badgesWrap__DS07B .DatasetBadges_badges__2vu81 .DatasetBadges_showMore__2Qd4H {
      box-shadow: none;
      text-shadow: none;
      background: #fff;
      position: relative;
      border-color: #ed2224; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Perfomance_btn__1UB88, .Perfomance_btnNo__3Z4nS, .Perfomance_btnYes__iVes7, .Perfomance_footerButton__16kob {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Perfomance_btn__1UB88:hover, .Perfomance_btnNo__3Z4nS:hover, .Perfomance_btnYes__iVes7:hover, .Perfomance_footerButton__16kob:hover {
    background: #ed2224;
    color: #ffffff; }
  .Perfomance_btn__1UB88:active, .Perfomance_btnNo__3Z4nS:active, .Perfomance_btnYes__iVes7:active, .Perfomance_footerButton__16kob:active {
    background: #DC2426;
    color: #ffffff; }

.Perfomance_btnNo__3Z4nS {
  width: 100px; }

.Perfomance_btnYes__iVes7 {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Perfomance_statusWpar__2Tx56 {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Perfomance_statusWpar__2Tx56::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Perfomance_statusActive__5fra6::before {
  background: #28A181; }

.Perfomance_statusInActive__D1TUI::before {
  background: #c8c8c8; }

.Perfomance_arrow__1kwoo, .Perfomance_collapseArrow__3EGme {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Perfomance_arrow__1kwoo:before, .Perfomance_collapseArrow__3EGme:before, .Perfomance_arrow__1kwoo:after, .Perfomance_collapseArrow__3EGme:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Perfomance_arrow__1kwoo:before, .Perfomance_collapseArrow__3EGme:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Perfomance_arrow__1kwoo:after, .Perfomance_collapseArrow__3EGme:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Perfomance_sectionTitle__1gDAF {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Perfomance_popUpTitle__1nr0L {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Perfomance_popUpWrap__3bYHr {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Perfomance_popUpText__39jEK {
  text-align: center; }
  .Perfomance_popUpText__39jEK:last-child {
    margin-bottom: 0; }

.Perfomance_visuallyHidden__1DTEy {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Perfomance_actionsBtn__1ygOY, .Perfomance_actionsBtnImage__zBxAT {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Perfomance_actionsBtn__1ygOY svg, .Perfomance_actionsBtnImage__zBxAT svg {
    color: #ed2224; }
  .Perfomance_actionsBtn__1ygOY:focus, .Perfomance_actionsBtnImage__zBxAT:focus {
    outline: none; }

.Perfomance_actionsBtn__1ygOY.Perfomance_actionsBtnDisabled__3I5wK, .Perfomance_actionsBtnDisabled__3I5wK.Perfomance_actionsBtnImage__zBxAT {
  background: none;
  opacity: 0.4; }

.Perfomance_blocked__302Xm td,
.Perfomance_blocked__302Xm span {
  color: #cccccc !important; }

.Perfomance_blocked__302Xm .ant-checkbox-input:focus + .ant-checkbox-inner,
.Perfomance_blocked__302Xm .ant-checkbox-inner {
  border-color: #cccccc; }

.Perfomance_isAdmin__2z3LO td,
.Perfomance_isAdmin__2z3LO span,
.Perfomance_isAccountManager__327Df td,
.Perfomance_isAccountManager__327Df span {
  font-weight: bold !important; }

.Perfomance_ellipsis__2Ue1M, tbody .Perfomance_location__2MrKc, th[colspan='3'] *,
td[colspan='3'] *, .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_boldDivision__2K6Fj td:not(.Perfomance_wordWrap__150T7), .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_bold__3-EK3 td:not(.Perfomance_wordWrap__150T7), .Perfomance_name__3HT0f {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Perfomance_wrapper__3YRXT {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto 0; }
  .Perfomance_wrapper__3YRXT * {
    font-family: 'Roboto', sans-serif; }
  .Perfomance_wrapper__3YRXT .ant-collapse {
    border: 1px solid rgba(46, 56, 77, 0.2); }
    .Perfomance_wrapper__3YRXT .ant-collapse-header {
      background: #ffffff;
      letter-spacing: 1.21333px;
      text-transform: uppercase;
      color: #536070;
      font-size: 16px;
      line-height: 19px;
      padding: 30px !important;
      padding-right: 45px !important;
      border-radius: 4px !important; }
      .Perfomance_wrapper__3YRXT .ant-collapse-header i {
        width: 16px;
        right: 30px !important; }
      .Perfomance_wrapper__3YRXT .ant-collapse-header svg {
        color: red;
        width: 100%;
        height: 100%; }
    .Perfomance_wrapper__3YRXT .ant-collapse-content {
      border-top: none; }
      .Perfomance_wrapper__3YRXT .ant-collapse-content > .ant-collapse-content-box {
        padding: 0 30px 10px 30px; }
    .Perfomance_wrapper__3YRXT .ant-collapse-item {
      border: none; }
    .Perfomance_wrapper__3YRXT .ant-collapse-item-active span.ant-collapse-arrow {
      transform: rotate(90deg) !important; }
  .Perfomance_wrapper__3YRXT .ant-table * {
    text-align: center !important; }
  .Perfomance_wrapper__3YRXT .ant-table .ant-table-header table {
    border-radius: 4px 0 0 0; }
  .Perfomance_wrapper__3YRXT .ant-table-thead tr:nth-child(1)::after {
    content: '';
    width: 99.9%;
    height: 58px;
    background: #526071;
    position: absolute;
    left: 0;
    top: 1px;
    z-index: -1;
    border-bottom: 1px solid #c8cccc;
    border-right: 1px solid #c8cccc;
    border-radius: 4px 4px 0 0; }
  .Perfomance_wrapper__3YRXT .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0; }
  .Perfomance_wrapper__3YRXT .deleteEmloyeeIcon {
    height: 30px; }
  .Perfomance_wrapper__3YRXT .ant-table-content,
  .Perfomance_wrapper__3YRXT .ant-table-fixed,
  .Perfomance_wrapper__3YRXT .ant-table-fixed-left {
    z-index: -100;
    background: #ffffff; }
    .Perfomance_wrapper__3YRXT .ant-table-content tr,
    .Perfomance_wrapper__3YRXT .ant-table-content td,
    .Perfomance_wrapper__3YRXT .ant-table-content th,
    .Perfomance_wrapper__3YRXT .ant-table-fixed tr,
    .Perfomance_wrapper__3YRXT .ant-table-fixed td,
    .Perfomance_wrapper__3YRXT .ant-table-fixed th,
    .Perfomance_wrapper__3YRXT .ant-table-fixed-left tr,
    .Perfomance_wrapper__3YRXT .ant-table-fixed-left td,
    .Perfomance_wrapper__3YRXT .ant-table-fixed-left th {
      border-color: #C8CCCC !important; }
  .Perfomance_wrapper__3YRXT .ant-table-scroll-position-left .ant-table-fixed-left {
    z-index: -100; }
  .Perfomance_wrapper__3YRXT .ant-table-fixed {
    width: -webkit-min-content !important;
    width: -moz-min-content !important;
    width: min-content !important;
    border: 1px solid #C8CCCC !important; }
  .Perfomance_wrapper__3YRXT .ant-table-body table {
    border: 0 solid #C8CCCC !important; }
  .Perfomance_wrapper__3YRXT .ant-table-body tr {
    height: 50px; }
  .Perfomance_wrapper__3YRXT .ant-table-thead tr {
    height: 55px; }
  .Perfomance_wrapper__3YRXT .ant-table-thead tr:first-child {
    text-transform: uppercase;
    background-color: #526071; }
    .Perfomance_wrapper__3YRXT .ant-table-thead tr:first-child th:nth-child(1) {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
      z-index: 100;
      background-color: #526071 !important; }
    .Perfomance_wrapper__3YRXT .ant-table-thead tr:first-child * {
      color: #ffffff; }
  .Perfomance_wrapper__3YRXT .ant-table-thead tr:nth-child(2) {
    background-color: #ffffff; }
    .Perfomance_wrapper__3YRXT .ant-table-thead tr:nth-child(2) th:nth-child(-n + 3) {
      box-sizing: border-box;
      position: -webkit-sticky;
      position: sticky;
      z-index: 100;
      background-color: #ffffff !important;
      border-right-width: 2px; }
    .Perfomance_wrapper__3YRXT .ant-table-thead tr:nth-child(2) th:nth-child(1) {
      left: 0;
      border-left: 1px solid #e8e8e8; }
    .Perfomance_wrapper__3YRXT .ant-table-thead tr:nth-child(2) th:nth-child(2) {
      left: 40px; }
    .Perfomance_wrapper__3YRXT .ant-table-thead tr:nth-child(2) th:nth-child(3) {
      left: 125.5px; }
  .Perfomance_wrapper__3YRXT .ant-table-thead * {
    font-size: 12px;
    color: #424142; }
  .Perfomance_wrapper__3YRXT .ant-table-tbody tr:nth-child(odd) {
    background-color: #F2F2F2; }
  .Perfomance_wrapper__3YRXT .ant-table-tbody tr:nth-child(even) {
    background-color: #fff; }
  .Perfomance_wrapper__3YRXT .ant-table-tbody .ant-table-row-hover > td {
    background: unset !important; }
  .Perfomance_wrapper__3YRXT .ant-table-tbody tr.ant-table-row:hover td {
    background-color: transparent !important; }
  .Perfomance_wrapper__3YRXT .ant-table-tbody td {
    color: #424142;
    font-size: 11px; }

.Perfomance_collapseWrap__DiRRW {
  margin-bottom: 35px; }

.Perfomance_collapseWrapOnlyView__38t2V {
  margin-bottom: 35px;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 4px; }
  .Perfomance_collapseWrapOnlyView__38t2V :focus {
    outline: none; }

.Perfomance_collapseArrow__3EGme {
  top: 44% !important;
  right: 30px !important; }
  .Perfomance_collapseArrow__3EGme:before, .Perfomance_collapseArrow__3EGme:after {
    display: -ms-flexbox !important;
    display: flex !important; }

.Perfomance_checkboxes__3l7oN {
  margin: 10px; }
  .Perfomance_checkboxes__3l7oN span {
    color: #536070; }

.Perfomance_itemTitle__2HS65 {
  display: inline-block;
  margin-right: 50px;
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: #2E384D; }

.Perfomance_actionsBtnImage__zBxAT {
  position: absolute;
  left: 328px;
  top: 0; }
  .Perfomance_actionsBtnImage__zBxAT svg {
    height: 24px;
    width: 24px; }

.Perfomance_footerButton__16kob {
  padding: 11px 30px;
  margin-right: 30px;
  border-radius: 4px; }
  .Perfomance_footerButton__16kob .Perfomance_centeredText__c7ZO8 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
  .Perfomance_footerButton__16kob:hover .Perfomance_buttonImg__2etCj path {
    fill: #ffffff; }

.Perfomance_buttonImg__2etCj {
  width: 24px;
  height: 24px;
  margin-right: 10px; }
  .Perfomance_buttonImg__2etCj path {
    transition: fill 0.5s ease;
    fill: #ed2224; }

.Perfomance_actionsBtn__1ygOY.Perfomance_actionsBtnDisabled__3I5wK, .Perfomance_actionsBtnDisabled__3I5wK.Perfomance_actionsBtnImage__zBxAT {
  background: none;
  opacity: 0.4; }

.Perfomance_itemFooter__1j-XE {
  margin: 10px 0 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: end;
      align-items: flex-end; }

.Perfomance_lastUpdate__SIBRw {
  display: -ms-flexbox;
  display: flex;
  font-weight: 300;
  line-height: 13px; }
  .Perfomance_lastUpdate__SIBRw span {
    color: #000000;
    font-size: 11px; }

.Perfomance_lastUpdateTitle__uWDy9 {
  margin-left: 20px; }

.Perfomance_actualWrap__2eSFz {
  position: relative; }

.Perfomance_toolTipWrap__2uQi2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }

.Perfomance_actualBadge__1Oor9 {
  position: absolute;
  bottom: -7px;
  right: -4px;
  background: #f9d772;
  color: white;
  border-radius: 50%;
  font-size: 9px;
  width: 13px;
  height: 13px; }

.Perfomance_behavior__2Lv2a, .Perfomance_score__2xYgA, .Perfomance_scoreNoData__2MQUo, .Perfomance_scoreNoDataMod__2nYxN {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 9px; }

.Perfomance_score__2xYgA {
  width: 26px;
  height: 26px; }

.Perfomance_scoreNoData__2MQUo, .Perfomance_scoreNoDataMod__2nYxN {
  width: 31px;
  height: 31px;
  border: 1px solid #C8CCCC;
  color: #424142;
  text-align: center; }

.Perfomance_scoreNoDataMod__2nYxN {
  color: #ffffff; }

.Perfomance_headersWithPopover__2derh {
  max-width: 350px; }

thead th.Perfomance_behaviorHeader__2qoOG {
  border-right: none !important; }

thead tr {
  height: 58px !important; }

thead .Perfomance_behaviorHeaderFixed__3gp8j {
  white-space: nowrap; }

.Perfomance_graphImg__ib5fH {
  width: 85%;
  max-height: 30px;
  object-fit: scale-down;
  position: relative;
  z-index: 50;
  transition: all 0.6s ease;
  transform-origin: bottom; }

.Perfomance_graphImgModal__1Dpvl {
  font-size: 13px; }
  .Perfomance_graphImgModal__1Dpvl .Perfomance_graphImg__ib5fH {
    width: 100%;
    min-height: 160px;
    max-height: 100%;
    object-fit: contain; }
  .Perfomance_graphImgModal__1Dpvl div {
    margin: 5px 0; }
  .Perfomance_graphImgModal__1Dpvl b {
    margin: 0 5px; }

tbody tr:first-child .Perfomance_graphImg__ib5fH {
  transform-origin: top; }

tbody tr:first-child .Perfomance_graphImgMod__2m1GY {
  transform-origin: bottom; }

tbody tr {
  height: 50px !important; }

th[colspan='3'],
td[colspan='3'] {
  max-width: 200px !important;
  overflow: hidden; }

.Perfomance_graphContainer__1QRcR {
  position: relative;
  padding: 8px 0 !important; }

.Perfomance_behaviorGreen__2ykiT {
  background: #28A181; }

.Perfomance_behaviorYellow__oYcKi {
  background: #f9d772; }

.Perfomance_behaviorRed__2szyy {
  background: #c8c8c8; }

.Perfomance_behaviorBlack__QeGoS {
  color: black; }

.Perfomance_showArrayOnHover__1rggE {
  list-style-type: none; }

.Perfomance_imgLoader__2capz {
  margin: 0 auto;
  border: 2px solid #ffffff;
  border-top: 2px solid #ed2224;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  -webkit-animation: Perfomance_spin__1ApWC 1.5s linear infinite;
          animation: Perfomance_spin__1ApWC 1.5s linear infinite; }

@-webkit-keyframes Perfomance_spin__1ApWC {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes Perfomance_spin__1ApWC {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_boldDivision__2K6Fj {
  background: #526071 !important; }
  .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_boldDivision__2K6Fj .Perfomance_jobTitle__3oHqB, .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_boldDivision__2K6Fj .Perfomance_jobSupervisor__37fNl {
    font-weight: bold;
    font-size: 14px; }
  .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_boldDivision__2K6Fj td:not(.Perfomance_graphContainer__1QRcR) {
    padding: 2px 4px !important;
    font-size: 9px !important;
    color: #ffffff !important;
    white-space: normal; }
  .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_boldDivision__2K6Fj td:nth-child(1) {
    z-index: 999;
    background: #526071 !important;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    border-right-width: 2px; }

.Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_usial__o5s4E td:nth-child(-n + 3) {
  box-sizing: border-box;
  position: -webkit-sticky;
  position: sticky;
  z-index: 100;
  background: inherit !important;
  border-right-width: 2px; }

.Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_usial__o5s4E td:nth-child(1) {
  left: 0;
  border-left: 1px solid #e8e8e8; }

.Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_usial__o5s4E td:nth-child(2) {
  left: 40px; }

.Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_usial__o5s4E td:nth-child(3) {
  left: 125px; }

.Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_bold__3-EK3 {
  background: #0093c2 !important; }
  .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_bold__3-EK3:hover td {
    background: #0093c2 !important; }
  .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_bold__3-EK3 td:not(.Perfomance_graphContainer__1QRcR) {
    padding: 2px 4px !important;
    font-size: 9px !important;
    color: #ffffff !important;
    white-space: normal; }
  .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_bold__3-EK3 td {
    z-index: 100;
    background: #0093c2 !important;
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
    .Perfomance_wrapper__3YRXT .Perfomance_tableWrap__1wpRb tr.Perfomance_bold__3-EK3 td:nth-child(1) {
      z-index: 1000;
      position: -webkit-sticky;
      position: sticky;
      left: 0px;
      border-right-width: 2px; }

.Perfomance_jobTitleWrap__2mC1k {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.Perfomance_bold__3-EK3 .Perfomance_jobTitle__3oHqB, .Perfomance_bold__3-EK3 .Perfomance_jobSupervisor__37fNl {
  font-weight: bold;
  font-size: 14px; }

.Perfomance_name__3HT0f {
  max-width: 80px; }

.Perfomance_comboMatch__3dsOA .Perfomance_sortImg__2m5i2 {
  display: -ms-flexbox;
  display: flex;
  word-break: normal;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  .Perfomance_comboMatch__3dsOA .Perfomance_sortImg__2m5i2 span {
    font-size: 9px;
    display: -ms-flexbox;
    display: flex; }

.Perfomance_comboMatch__3dsOA .ant-table-column-title {
  font-size: 9px;
  display: -ms-flexbox;
  display: flex; }

.Perfomance_comboMatch__3dsOA span.Perfomance_behavior__2Lv2a, .Perfomance_comboMatch__3dsOA span.Perfomance_score__2xYgA, .Perfomance_comboMatch__3dsOA span.Perfomance_scoreNoData__2MQUo, .Perfomance_comboMatch__3dsOA span.Perfomance_scoreNoDataMod__2nYxN {
  width: 30px;
  height: 30px; }

.Perfomance_tooltipOnButtonLink__3LMqW {
  width: 180px; }

.Perfomance_statusDropdown__2dGRq li {
  font-size: 11px;
  text-transform: lowercase;
  padding: 0 10px; }
  .Perfomance_statusDropdown__2dGRq li:first-letter {
    text-transform: uppercase; }

.Perfomance_selectIcon__17gM2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: #424142 transparent transparent transparent;
  margin-top: 4px; }

.Perfomance_selectFilterIcon__3EaIY {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #536070 transparent transparent transparent;
  transition: 0.3s; }

.Perfomance_actionsWrap__2K7BW {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 50px; }

.Perfomance_actionsButtons__2xP9X {
  margin-left: 0; }

.Perfomance_actionsBtn__1ygOY, .Perfomance_actionsBtnImage__zBxAT {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  color: #000000;
  cursor: pointer; }

.Perfomance_filterSets__2EdBo {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
      align-items: baseline;
  margin: 25px 0; }

.Perfomance_linkToShare__18TDU {
  margin-left: 10px;
  margin-bottom: auto;
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: #2E384D; }

.Perfomance_actionsImg__21Pha {
  margin-right: 5px; }

.Perfomance_sortImg__2m5i2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  word-break: normal; }
  .Perfomance_sortImg__2m5i2 img {
    cursor: pointer;
    margin-left: 5px;
    height: 14px; }

.Perfomance_filterOption__wWk5o {
  display: inline-block; }

.Perfomance_teamFilter__1H7VU {
  position: relative;
  padding: 25px 0 0 25px;
  margin: 0 10px 10px 0;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 5px; }
  .Perfomance_teamFilter__1H7VU > div {
    margin-right: 30px;
    margin-bottom: 30px; }

.Perfomance_teamFilter__1H7VU .ant-select-selection,
.Perfomance_filterSets__2EdBo .ant-select-selection {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #536070;
  box-shadow: none;
  cursor: pointer; }

.Perfomance_teamFilter__1H7VU .ant-select-focused .ant-select-selection,
.Perfomance_teamFilter__1H7VU .ant-select-selection:focus,
.Perfomance_teamFilter__1H7VU .ant-select-selection:active,
.Perfomance_filterSets__2EdBo .ant-select-focused .ant-select-selection,
.Perfomance_filterSets__2EdBo .ant-select-selection:focus,
.Perfomance_filterSets__2EdBo .ant-select-selection:active {
  box-shadow: none; }

.Perfomance_teamFilter__1H7VU .ant-select-selection__choice,
.Perfomance_filterSets__2EdBo .ant-select-selection__choice {
  background: none;
  border-color: #ed2224; }

.Perfomance_teamFilter__1H7VU .ant-select-selection__placeholder,
.Perfomance_filterSets__2EdBo .ant-select-selection__placeholder {
  display: block !important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #536070;
  transition: 0.3s; }

.Perfomance_teamFilter__1H7VU .ant-select-focused .ant-select-selection__placeholder,
.Perfomance_filterSets__2EdBo .ant-select-focused .ant-select-selection__placeholder {
  transform: translate(-10px, -25px); }

.Perfomance_teamFilter__1H7VU .ant-select-open .ant-select-arrow-icon,
.Perfomance_filterSets__2EdBo .ant-select-open .ant-select-arrow-icon {
  transform: rotate(180deg); }

.Perfomance_teamFilterHasValue__1h_Pr .ant-select-selection__placeholder {
  transform: translate(-10px, -25px) !important; }

.Perfomance_teamFilterDropdown__1lCD0 .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.Perfomance_statusDropdown__2dGRq .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: #ed2224 !important;
  color: white; }
  .Perfomance_teamFilterDropdown__1lCD0 .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) svg,
  .Perfomance_statusDropdown__2dGRq .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) svg {
    color: #ffffff; }

.Perfomance_teamFilterDropdown__1lCD0 .ant-select-dropdown-menu-item-selected,
.Perfomance_statusDropdown__2dGRq .ant-select-dropdown-menu-item-selected {
  background: none !important;
  color: #ed2224; }
  .Perfomance_teamFilterDropdown__1lCD0 .ant-select-dropdown-menu-item-selected svg,
  .Perfomance_statusDropdown__2dGRq .ant-select-dropdown-menu-item-selected svg {
    color: #ed2224; }

.Perfomance_tableWrap__1wpRb {
  position: relative; }
  .Perfomance_tableWrap__1wpRb .ant-select {
    display: -ms-flexbox;
    display: flex; }
  .Perfomance_tableWrap__1wpRb .ant-select-selection {
    background: transparent;
    border: none;
    font-size: 11px;
    text-transform: lowercase;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }
    .Perfomance_tableWrap__1wpRb .ant-select-selection div:first-letter {
      text-transform: uppercase; }
    .Perfomance_tableWrap__1wpRb .ant-select-selection .ant-select-arrow {
      display: -ms-flexbox;
      display: flex;
      position: relative;
      top: auto;
      right: auto; }
    .Perfomance_tableWrap__1wpRb .ant-select-selection__rendered {
      margin: 0px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center; }
    .Perfomance_tableWrap__1wpRb .ant-select-selection__placeholder,
    .Perfomance_tableWrap__1wpRb .ant-select-selection .ant-select-selection-selected-value {
      color: #424142;
      text-align: start;
      position: relative;
      display: -ms-flexbox;
      display: flex;
      margin: 0 10px 0 0;
      top: auto;
      right: auto;
      left: auto; }
  .Perfomance_tableWrap__1wpRb .ant-select-focused .ant-select-selection,
  .Perfomance_tableWrap__1wpRb .ant-select-selection:focus,
  .Perfomance_tableWrap__1wpRb .ant-select-selection:active {
    box-shadow: none; }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ffffff;
  border-color: #ed2224;
  border-radius: 4px; }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #ed2224;
    height: 8px; }

.ant-checkbox-inner {
  border-color: #ed2224; }

.ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden; }

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ed2224; }

.ant-modal-body {
  padding: 0; }

.ant-tooltip {
  max-width: 540px !important; }
  .ant-tooltip div {
    text-align: justify; }

.Perfomance_calculatorContainer__1g5gz {
  padding: 2px 10px !important; }

.Perfomance_calculator__3COpg {
  transition: all 0.6s ease;
  transform-origin: bottom; }

.Perfomance_divisionCalculator__2gJHU {
  cursor: pointer; }

.Perfomance_iconTrash__1PFIL {
  height: 20px;
  cursor: pointer; }

.Perfomance_loading-mini__1HuNz {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.CreateDashBoardModal_btn__1qwfM, .CreateDashBoardModal_btnNo__3POho, .CreateDashBoardModal_btnYes__2MnaV {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .CreateDashBoardModal_btn__1qwfM:hover, .CreateDashBoardModal_btnNo__3POho:hover, .CreateDashBoardModal_btnYes__2MnaV:hover {
    background: #ed2224;
    color: #ffffff; }
  .CreateDashBoardModal_btn__1qwfM:active, .CreateDashBoardModal_btnNo__3POho:active, .CreateDashBoardModal_btnYes__2MnaV:active {
    background: #DC2426;
    color: #ffffff; }

.CreateDashBoardModal_btnNo__3POho {
  width: 100px; }

.CreateDashBoardModal_btnYes__2MnaV {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.CreateDashBoardModal_statusWpar__1gJS0 {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .CreateDashBoardModal_statusWpar__1gJS0::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.CreateDashBoardModal_statusActive__1SuXR::before {
  background: #28A181; }

.CreateDashBoardModal_statusInActive__3q59G::before {
  background: #c8c8c8; }

.CreateDashBoardModal_arrow__SYVpI {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .CreateDashBoardModal_arrow__SYVpI:before, .CreateDashBoardModal_arrow__SYVpI:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .CreateDashBoardModal_arrow__SYVpI:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .CreateDashBoardModal_arrow__SYVpI:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.CreateDashBoardModal_sectionTitle__1cGuf {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.CreateDashBoardModal_popUpTitle__3DqdH, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_title__JXNpz {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.CreateDashBoardModal_popUpWrap__1A5YR, .CreateDashBoardModal_modalWrap__5rqKo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.CreateDashBoardModal_popUpText__12rGy {
  text-align: center; }
  .CreateDashBoardModal_popUpText__12rGy:last-child {
    margin-bottom: 0; }

.CreateDashBoardModal_visuallyHidden__1j8Qk {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.CreateDashBoardModal_actionsBtn__1NVjn {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .CreateDashBoardModal_actionsBtn__1NVjn svg {
    color: #ed2224; }
  .CreateDashBoardModal_actionsBtn__1NVjn:focus {
    outline: none; }

.CreateDashBoardModal_actionsBtn__1NVjn.CreateDashBoardModal_actionsBtnDisabled__2DY55 {
  background: none;
  opacity: 0.4; }

.CreateDashBoardModal_blocked__3jlGI td,
.CreateDashBoardModal_blocked__3jlGI span {
  color: #cccccc !important; }

.CreateDashBoardModal_blocked__3jlGI .ant-checkbox-input:focus + .ant-checkbox-inner,
.CreateDashBoardModal_blocked__3jlGI .ant-checkbox-inner {
  border-color: #cccccc; }

.CreateDashBoardModal_isAdmin__1qVfl td,
.CreateDashBoardModal_isAdmin__1qVfl span,
.CreateDashBoardModal_isAccountManager__3wpVY td,
.CreateDashBoardModal_isAccountManager__3wpVY span {
  font-weight: bold !important; }

.CreateDashBoardModal_ellipsis__ieMCR {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.CreateDashBoardModal_formInput__1SgFM {
  width: 100%;
  margin: 0 10px 0 0; }
  .CreateDashBoardModal_formInput__1SgFM input {
    font-size: 14px; }

.CreateDashBoardModal_inputSection__2ULn5, .CreateDashBoardModal_companySelectHasValue__3OhTQ,
.CreateDashBoardModal_usersSelectHasValue__3MOvr,
.CreateDashBoardModal_datasetHasValues__DNZH5 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .CreateDashBoardModal_inputSection__2ULn5 .ant-select, .CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-select, .CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-select, .CreateDashBoardModal_datasetHasValues__DNZH5 .ant-select {
    color: #424142; }
  .CreateDashBoardModal_inputSection__2ULn5 .ant-select-selection__placeholder, .CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-select-selection__placeholder, .CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-select-selection__placeholder, .CreateDashBoardModal_datasetHasValues__DNZH5 .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .CreateDashBoardModal_inputSection__2ULn5 .ant-select-selection__rendered, .CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-select-selection__rendered, .CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-select-selection__rendered, .CreateDashBoardModal_datasetHasValues__DNZH5 .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .CreateDashBoardModal_inputSection__2ULn5 .ant-select-selection, .CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-select-selection, .CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-select-selection, .CreateDashBoardModal_datasetHasValues__DNZH5 .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .CreateDashBoardModal_inputSection__2ULn5 .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .CreateDashBoardModal_datasetHasValues__DNZH5 .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .CreateDashBoardModal_inputSection__2ULn5 .ant-select-focused .ant-select-selection:focus, .CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-select-focused .ant-select-selection:focus, .CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-select-focused .ant-select-selection:focus, .CreateDashBoardModal_datasetHasValues__DNZH5 .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.CreateDashBoardModal_addUserPasswordSections__3Nxpv {
  margin-bottom: 30px; }

.CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-select-selection__placeholder,
.CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-select-selection__placeholder,
.CreateDashBoardModal_datasetHasValues__DNZH5 .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.CreateDashBoardModal_inputSectionLabel__2KfAL {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.CreateDashBoardModal_btnWrap__1ruD_, .CreateDashBoardModal_btnWrapSingle__3QhVY {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .CreateDashBoardModal_btnWrap__1ruD_ button, .CreateDashBoardModal_btnWrapSingle__3QhVY button {
    width: 100%; }

.CreateDashBoardModal_btnWrapSingle__3QhVY button {
  width: 200px; }

.CreateDashBoardModal_editBtn__3r-Y_ {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .CreateDashBoardModal_editBtn__3r-Y_ svg {
    font-size: 14px; }
    .CreateDashBoardModal_editBtn__3r-Y_ svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }
  .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_title__JXNpz {
    padding-bottom: 10px;
    margin-bottom: 5px;
    font-size: 30px; }
  .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ,
  .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr,
  .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 {
    padding: 60px 20px 10px;
    -ms-flex-direction: column;
        flex-direction: column;
    margin-bottom: 0; }
    .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .CreateDashBoardModal_formInput__1SgFM, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .CreateDashBoardModal_formInput__1SgFM, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .CreateDashBoardModal_formInput__1SgFM, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .CreateDashBoardModal_formInput__1SgFM {
      margin: 0 0 25px; }
    .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .CreateDashBoardModal_formRadio__24Cpg, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .CreateDashBoardModal_formRadio__24Cpg, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .CreateDashBoardModal_formRadio__24Cpg, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .CreateDashBoardModal_formRadio__24Cpg {
      margin-bottom: 25px;
      -ms-flex-item-align: baseline;
          align-self: baseline; }
      .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .CreateDashBoardModal_formRadio__24Cpg .CreateDashBoardModal_formRadioLabel__37QKD, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .CreateDashBoardModal_formRadio__24Cpg .CreateDashBoardModal_formRadioLabel__37QKD, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .CreateDashBoardModal_formRadio__24Cpg .CreateDashBoardModal_formRadioLabel__37QKD, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .CreateDashBoardModal_formRadio__24Cpg .CreateDashBoardModal_formRadioLabel__37QKD {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 0.5em;
        display: block;
        color: rgba(0, 0, 0, 0.85); }
    .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .ant-radio + span, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-radio + span, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-radio + span, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .ant-radio + span {
      padding-left: 15px;
      color: rgba(0, 0, 0, 0.65); }
    .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .ant-radio-wrapper, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .ant-radio-wrapper, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .ant-radio-wrapper, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .ant-radio-wrapper {
      margin-bottom: 0.5em; }
    .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .CreateDashBoardModal_formSelect__3mZkw, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .CreateDashBoardModal_formSelect__3mZkw, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .CreateDashBoardModal_formSelect__3mZkw, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .CreateDashBoardModal_formSelect__3mZkw {
      margin-bottom: 40px;
      padding: 0;
      width: 100%; }
      .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .CreateDashBoardModal_formSelect__3mZkw .CreateDashBoardModal_selectIcon__3kqsa, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .CreateDashBoardModal_formSelect__3mZkw .CreateDashBoardModal_selectIcon__3kqsa, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .CreateDashBoardModal_formSelect__3mZkw .CreateDashBoardModal_selectIcon__3kqsa, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .CreateDashBoardModal_formSelect__3mZkw .CreateDashBoardModal_selectIcon__3kqsa {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #424142 transparent transparent transparent;
        margin-top: 4px; }
    .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .CreateDashBoardModal_btnWrap__1ruD_, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .CreateDashBoardModal_btnWrap__1ruD_, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .CreateDashBoardModal_btnWrap__1ruD_, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .CreateDashBoardModal_btnWrap__1ruD_, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_inputSection__2ULn5 .CreateDashBoardModal_btnWrapSingle__3QhVY, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_companySelectHasValue__3OhTQ .CreateDashBoardModal_btnWrapSingle__3QhVY, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_usersSelectHasValue__3MOvr .CreateDashBoardModal_btnWrapSingle__3QhVY, .CreateDashBoardModal_modalWrap__5rqKo .CreateDashBoardModal_innerWrap__35aUc .CreateDashBoardModal_datasetHasValues__DNZH5 .CreateDashBoardModal_btnWrapSingle__3QhVY {
      width: 100%;
      padding: 0 30px; }

.CreateDashBoardModal_modalConfirm__1H37x {
  width: 400px !important; }

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
  box-shadow: none; }

.ant-select-selection:hover {
  box-shadow: none;
  border-color: #C8CCCC; }

.ant-select-selection--single .ant-select-selection__rendered {
  color: #424142;
  overflow: hidden; }

.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background: #ed2224 !important;
  color: white; }

.ant-select-dropdown-menu-item-selected {
  background: none !important;
  color: #ed2224; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.UserManagement_btn__1bZoX, .UserManagement_btnNo__S-G2q, .UserManagement_btnYes__3wyUp {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .UserManagement_btn__1bZoX:hover, .UserManagement_btnNo__S-G2q:hover, .UserManagement_btnYes__3wyUp:hover {
    background: #ed2224;
    color: #ffffff; }
  .UserManagement_btn__1bZoX:active, .UserManagement_btnNo__S-G2q:active, .UserManagement_btnYes__3wyUp:active {
    background: #DC2426;
    color: #ffffff; }

.UserManagement_btnNo__S-G2q {
  width: 100px; }

.UserManagement_btnYes__3wyUp {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.UserManagement_statusWpar__2VWrh {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .UserManagement_statusWpar__2VWrh::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.UserManagement_statusActive__CB5Fx::before {
  background: #28A181; }

.UserManagement_statusInActive__2Z8jI::before {
  background: #c8c8c8; }

.UserManagement_arrow__3LPx_ {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .UserManagement_arrow__3LPx_:before, .UserManagement_arrow__3LPx_:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .UserManagement_arrow__3LPx_:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .UserManagement_arrow__3LPx_:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.UserManagement_sectionTitle__2i8b8, .UserManagement_title__1qTlf {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.UserManagement_popUpTitle__1tmFl {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.UserManagement_popUpWrap__1pLFi {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.UserManagement_popUpText__PrOJl {
  text-align: center; }
  .UserManagement_popUpText__PrOJl:last-child {
    margin-bottom: 0; }

.UserManagement_visuallyHidden__17uoF {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.UserManagement_actionsBtn__3SGli {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .UserManagement_actionsBtn__3SGli svg {
    color: #ed2224; }
  .UserManagement_actionsBtn__3SGli:focus {
    outline: none; }

.UserManagement_actionsBtn__3SGli.UserManagement_actionsBtnDisabled__2iXSx {
  background: none;
  opacity: 0.4; }

.UserManagement_blocked__KP7VI td,
.UserManagement_blocked__KP7VI span {
  color: #cccccc !important; }

.UserManagement_blocked__KP7VI .ant-checkbox-input:focus + .ant-checkbox-inner,
.UserManagement_blocked__KP7VI .ant-checkbox-inner {
  border-color: #cccccc; }

.UserManagement_isAdmin__unngo td,
.UserManagement_isAdmin__unngo span,
.UserManagement_isAccountManager__2ClHA td,
.UserManagement_isAccountManager__2ClHA span {
  font-weight: bold !important; }

.UserManagement_ellipsis__208UI {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.UserManagement_wrapper__1RVew {
  min-height: 100vh;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto 50px; }

.UserManagement_title__1qTlf {
  margin-top: 20px; }

.UserManagement_tableWrap__WM0pb .ant-table-wrapper {
  background: #ffffff; }
  .UserManagement_tableWrap__WM0pb .ant-table-wrapper table,
  .UserManagement_tableWrap__WM0pb .ant-table-wrapper tr,
  .UserManagement_tableWrap__WM0pb .ant-table-wrapper th,
  .UserManagement_tableWrap__WM0pb .ant-table-wrapper td {
    border-color: #C8CCCC !important; }
  .UserManagement_tableWrap__WM0pb .ant-table-wrapper th {
    border-radius: 0 !important;
    text-align: center; }

.UserManagement_tableWrap__WM0pb .ant-table-thead th {
  background: #666261;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  height: 40px;
  padding: 10px 20px; }

.UserManagement_tableWrap__WM0pb .ant-table-thead .ant-table-selection-column span {
  display: none; }

.UserManagement_tableWrap__WM0pb .ant-table-tbody tr:nth-child(even) {
  background: #F2F2F2; }

.UserManagement_tableWrap__WM0pb .ant-table-tbody tr:hover:not(.ant-table-row-selected) > td {
  background: transparent !important; }

.UserManagement_tableWrap__WM0pb .ant-table-tbody td {
  color: #424142;
  font-size: 14px; }

.UserManagement_tableWrap__WM0pb .ant-table-tbody tr.ant-table-row-selected td {
  background: #ff9091;
  color: #ffffff; }
  .UserManagement_tableWrap__WM0pb .ant-table-tbody tr.ant-table-row-selected td span {
    color: #ffffff; }

.UserManagement_tableWrap__WM0pb .ant-table-pagination {
  margin-right: 30px; }

.UserManagement_tableWrap__WM0pb .ant-pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 40px;
  float: none; }
  .UserManagement_tableWrap__WM0pb .ant-pagination div,
  .UserManagement_tableWrap__WM0pb .ant-pagination a {
    border: none; }
  .UserManagement_tableWrap__WM0pb .ant-pagination * {
    color: #424142; }

.UserManagement_tableWrap__WM0pb .ant-pagination-item {
  display: none; }

.UserManagement_tableWrap__WM0pb .ant-pagination-options-size-changer {
  border-bottom: 1px solid #424142 !important; }

.UserManagement_tableWrap__WM0pb .ant-pagination-options {
  -ms-flex-order: -1;
      order: -1;
  position: relative; }
  .UserManagement_tableWrap__WM0pb .ant-pagination-options:before {
    position: absolute;
    content: 'Item per page:';
    display: -ms-flexbox;
    display: flex;
    top: 4px;
    left: -105px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }

.UserManagement_tableWrap__WM0pb .ant-pagination-total-text {
  margin: 0 35px; }

.UserManagement_tableWrap__WM0pb .ant-select-arrow {
  right: 2px;
  top: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #424142 transparent transparent transparent; }
  .UserManagement_tableWrap__WM0pb .ant-select-arrow * {
    display: none; }

.UserManagement_tableWrap__WM0pb .ant-select-focused .ant-select-selection,
.UserManagement_tableWrap__WM0pb .ant-select-selection:focus,
.UserManagement_tableWrap__WM0pb .ant-select-selection:active {
  box-shadow: none; }

.UserManagement_tableWrap__WM0pb .ant-select-dropdown-menu-item-active {
  background: #ed2224 !important;
  color: #ffffff; }

.UserManagement_tableWrap__WM0pb .ant-select-selection--single .ant-select-selection__rendered {
  margin: 0 15px 0 5px; }

.UserManagement_tableWrap__WM0pb .ant-select-dropdown-menu-item {
  padding: 5px 10px; }

.UserManagement_listOfRoles__1ZFk9 p {
  margin: 0; }

.UserManagement_amount__STSTI {
  padding: 10px 5px !important; }

.UserManagement_optionsWrap__1pdG5 {
  margin: 45px 0 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center; }

.UserManagement_actionsTableCol__3kX89 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }
  .UserManagement_actionsTableCol__3kX89 svg {
    width: 26px;
    height: 26px;
    font-size: 20px; }

.UserManagement_searchWrap__3Acfm {
  position: relative;
  -ms-flex-positive: 1;
      flex-grow: 1;
  width: 280px;
  height: 40px;
  margin-right: 30px; }
  .UserManagement_searchWrap__3Acfm input {
    width: 100%;
    height: 100%;
    max-width: 390px;
    padding: 10px 10px 10px 60px;
    background: #F6F6F6;
    border: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }
  .UserManagement_searchWrap__3Acfm:before {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    top: 13px;
    left: 20px;
    content: '';
    background: url(/static/media/search.d5aad510.svg) no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px; }

.UserManagement_details__3RE1R {
  color: #ed2224;
  cursor: pointer;
  text-decoration: underline; }

.UserManagement_actionsWrap__JpTaW {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .UserManagement_actionsWrap__JpTaW button:last-child {
    margin-right: 5px; }

.UserManagement_actionsBtnWrap__30m9e {
  margin-left: 12px; }

.UserManagement_actionsBtnWrapDisabled__zR5V3 {
  cursor: not-allowed;
  margin-left: 12px; }

.UserManagement_actionsBtnDisabled__2iXSx {
  pointer-events: none; }

.UserManagement_sortImg__3VvNN {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  word-break: normal; }
  .UserManagement_sortImg__3VvNN img {
    cursor: pointer;
    margin-left: 5px;
    height: 14px; }

button:disabled {
  border: none; }

.UserManagement_searchedWord__v7mog {
  background-color: #ed2224;
  color: #ffffff;
  padding: 0 3px 1.5px;
  border-radius: 10px;
  font-weight: 600; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.EditCompanySurvey_btn__3G3KJ, .EditCompanySurvey_btnNo__3vNga, .EditCompanySurvey_btnYes__3enXV, .EditCompanySurvey_btnSave__UCANf, .EditCompanySurvey_btnGet__rhVKM {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .EditCompanySurvey_btn__3G3KJ:hover, .EditCompanySurvey_btnNo__3vNga:hover, .EditCompanySurvey_btnYes__3enXV:hover, .EditCompanySurvey_btnSave__UCANf:hover, .EditCompanySurvey_btnGet__rhVKM:hover {
    background: #ed2224;
    color: #ffffff; }
  .EditCompanySurvey_btn__3G3KJ:active, .EditCompanySurvey_btnNo__3vNga:active, .EditCompanySurvey_btnYes__3enXV:active, .EditCompanySurvey_btnSave__UCANf:active, .EditCompanySurvey_btnGet__rhVKM:active {
    background: #DC2426;
    color: #ffffff; }

.EditCompanySurvey_btnNo__3vNga {
  width: 100px; }

.EditCompanySurvey_btnYes__3enXV, .EditCompanySurvey_btnSave__UCANf {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.EditCompanySurvey_statusWpar__1PaqD {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .EditCompanySurvey_statusWpar__1PaqD::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.EditCompanySurvey_statusActive__3CpWK::before {
  background: #28A181; }

.EditCompanySurvey_statusInActive__2V2kf::before {
  background: #c8c8c8; }

.EditCompanySurvey_arrow__1I3QH {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .EditCompanySurvey_arrow__1I3QH:before, .EditCompanySurvey_arrow__1I3QH:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .EditCompanySurvey_arrow__1I3QH:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .EditCompanySurvey_arrow__1I3QH:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.EditCompanySurvey_sectionTitle__pTIeh {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.EditCompanySurvey_popUpTitle__2frs6, .EditCompanySurvey_title__1guEI {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.EditCompanySurvey_popUpWrap__1N6Mt, .EditCompanySurvey_wrapper__PDYAS {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.EditCompanySurvey_popUpText__3eFTo {
  text-align: center; }
  .EditCompanySurvey_popUpText__3eFTo:last-child {
    margin-bottom: 0; }

.EditCompanySurvey_visuallyHidden__52oP8 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.EditCompanySurvey_actionsBtn__3K1Zb {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .EditCompanySurvey_actionsBtn__3K1Zb svg {
    color: #ed2224; }
  .EditCompanySurvey_actionsBtn__3K1Zb:focus {
    outline: none; }

.EditCompanySurvey_actionsBtn__3K1Zb.EditCompanySurvey_actionsBtnDisabled__s8P_b {
  background: none;
  opacity: 0.4; }

.EditCompanySurvey_blocked__3hSvD td,
.EditCompanySurvey_blocked__3hSvD span {
  color: #cccccc !important; }

.EditCompanySurvey_blocked__3hSvD .ant-checkbox-input:focus + .ant-checkbox-inner,
.EditCompanySurvey_blocked__3hSvD .ant-checkbox-inner {
  border-color: #cccccc; }

.EditCompanySurvey_isAdmin__1NH_m td,
.EditCompanySurvey_isAdmin__1NH_m span,
.EditCompanySurvey_isAccountManager__3nHFT td,
.EditCompanySurvey_isAccountManager__3nHFT span {
  font-weight: bold !important; }

.EditCompanySurvey_ellipsis__3oI8M {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.EditCompanySurvey_formInput__1FR6V {
  width: 100%;
  margin: 0 10px 0 0; }
  .EditCompanySurvey_formInput__1FR6V input {
    font-size: 14px; }

.EditCompanySurvey_inputSection__2yt8G, .EditCompanySurvey_companySelectHasValue__2xQyi,
.EditCompanySurvey_usersSelectHasValue__2g7Ys,
.EditCompanySurvey_datasetHasValues__39JWc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 5px;
  margin-bottom: 25px; }
  .EditCompanySurvey_inputSection__2yt8G .ant-select, .EditCompanySurvey_companySelectHasValue__2xQyi .ant-select, .EditCompanySurvey_usersSelectHasValue__2g7Ys .ant-select, .EditCompanySurvey_datasetHasValues__39JWc .ant-select {
    color: #424142; }
  .EditCompanySurvey_inputSection__2yt8G .ant-select-selection__placeholder, .EditCompanySurvey_companySelectHasValue__2xQyi .ant-select-selection__placeholder, .EditCompanySurvey_usersSelectHasValue__2g7Ys .ant-select-selection__placeholder, .EditCompanySurvey_datasetHasValues__39JWc .ant-select-selection__placeholder {
    color: #536070;
    font-size: 16px;
    transition: transform 150ms ease-out, font-size 150ms ease-out, top 150ms ease-out; }
  .EditCompanySurvey_inputSection__2yt8G .ant-select-selection__rendered, .EditCompanySurvey_companySelectHasValue__2xQyi .ant-select-selection__rendered, .EditCompanySurvey_usersSelectHasValue__2g7Ys .ant-select-selection__rendered, .EditCompanySurvey_datasetHasValues__39JWc .ant-select-selection__rendered {
    margin-left: 0;
    line-height: 40px; }
  .EditCompanySurvey_inputSection__2yt8G .ant-select-selection, .EditCompanySurvey_companySelectHasValue__2xQyi .ant-select-selection, .EditCompanySurvey_usersSelectHasValue__2g7Ys .ant-select-selection, .EditCompanySurvey_datasetHasValues__39JWc .ant-select-selection {
    box-shadow: none;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #C8CCCC; }
  .EditCompanySurvey_inputSection__2yt8G .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .EditCompanySurvey_companySelectHasValue__2xQyi .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .EditCompanySurvey_usersSelectHasValue__2g7Ys .ant-select-focused.ant-select-open .ant-select-selection__placeholder, .EditCompanySurvey_datasetHasValues__39JWc .ant-select-focused.ant-select-open .ant-select-selection__placeholder {
    transform: translateY(-238%);
    font-size: 10px;
    color: #817B7A;
    top: 130%; }
  .EditCompanySurvey_inputSection__2yt8G .ant-select-focused .ant-select-selection:focus, .EditCompanySurvey_companySelectHasValue__2xQyi .ant-select-focused .ant-select-selection:focus, .EditCompanySurvey_usersSelectHasValue__2g7Ys .ant-select-focused .ant-select-selection:focus, .EditCompanySurvey_datasetHasValues__39JWc .ant-select-focused .ant-select-selection:focus {
    box-shadow: none;
    border-bottom: 1px solid #C8CCCC; }

.EditCompanySurvey_addUserPasswordSections__3Pj7L {
  margin-bottom: 30px; }

.EditCompanySurvey_companySelectHasValue__2xQyi .ant-select-selection__placeholder,
.EditCompanySurvey_usersSelectHasValue__2g7Ys .ant-select-selection__placeholder,
.EditCompanySurvey_datasetHasValues__39JWc .ant-select-selection__placeholder {
  display: block !important;
  transform: translateY(-238%) !important;
  font-size: 10px;
  color: #817B7A;
  top: 130%; }

.EditCompanySurvey_inputSectionLabel__2aihp {
  min-width: 170px;
  width: 170px;
  -ms-flex: 1 1;
      flex: 1 1;
  margin-right: 15px;
  color: #424142; }

.EditCompanySurvey_btnWrap__3tVKP, .EditCompanySurvey_btnWrapSingle__x83ug {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  margin-top: 30px;
  padding: 0 50px; }
  .EditCompanySurvey_btnWrap__3tVKP button, .EditCompanySurvey_btnWrapSingle__x83ug button {
    width: 100%; }

.EditCompanySurvey_btnWrapSingle__x83ug button {
  width: 200px; }

.EditCompanySurvey_editBtn__3t7Np {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer; }
  .EditCompanySurvey_editBtn__3t7Np svg {
    font-size: 14px; }
    .EditCompanySurvey_editBtn__3t7Np svg * {
      color: #ed2224; }

.ant-select-selection__clear {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  opacity: 1; }

.EditCompanySurvey_title__1guEI {
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px; }

.EditCompanySurvey_titleSection__2POy- {
  margin: 10px 0 20px 0;
  color: #424142; }

.EditCompanySurvey_generalItem__26H4v {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  margin-bottom: 5px; }

.EditCompanySurvey_sectionWrap__1yCz7 {
  padding: 5px 0px; }

.EditCompanySurvey_btnGet__rhVKM {
  margin-right: 20px; }

.EditCompanySurvey_inputSectionAdditionalMargins__3yJQd {
  margin: 0 10px; }

.EditCompanySurvey_buttonsRow__3XiT8 {
  display: -ms-flexbox;
  display: flex;
  margin: 10px 10px;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.EditCompanySurvey_surveysBlock__1h4Ry {
  display: -ms-flexbox;
  display: flex;
  margin: 10px 10px;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 300px;
  overflow-y: auto; }
  .EditCompanySurvey_surveysBlock__1h4Ry .EditCompanySurvey_surveysCheckBox__20UN- {
    margin: 5px 5px; }

.EditCompanySurvey_noDataBlock__1yqIT {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 200px;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .EditCompanySurvey_noDataBlock__1yqIT p {
    font-size: 20px;
    margin: 0; }
  .EditCompanySurvey_noDataBlock__1yqIT svg {
    font-size: 26px;
    margin-right: 10px; }
    .EditCompanySurvey_noDataBlock__1yqIT svg * {
      color: #C8CCCC; }

.EditCompanySurvey_boldSpan__3ZDQz {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8); }

.EditCompanySurvey_inputDisabled__3Z2Ip {
  border: none; }

.EditCompanySurvey_inputDisabledNew__wfK3J {
  width: 200px; }

.EditCompanySurvey_modalWrap__3o4AO {
  margin: 0 auto;
  width: 400px !important; }

.EditCompanySurvey_modalSection__1N6Iz {
  padding: 20px;
  text-align: center; }

.EditCompanySurvey_btnModalWrap__30B8o {
  margin-top: 30px; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.CompanyManagement_btn__2UbUT, .CompanyManagement_btnNo__12Xdo, .CompanyManagement_btnYes__2GXYK {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .CompanyManagement_btn__2UbUT:hover, .CompanyManagement_btnNo__12Xdo:hover, .CompanyManagement_btnYes__2GXYK:hover {
    background: #ed2224;
    color: #ffffff; }
  .CompanyManagement_btn__2UbUT:active, .CompanyManagement_btnNo__12Xdo:active, .CompanyManagement_btnYes__2GXYK:active {
    background: #DC2426;
    color: #ffffff; }

.CompanyManagement_btnNo__12Xdo {
  width: 100px; }

.CompanyManagement_btnYes__2GXYK {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.CompanyManagement_statusWpar___7BeN {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .CompanyManagement_statusWpar___7BeN::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.CompanyManagement_statusActive__1X5B_::before {
  background: #28A181; }

.CompanyManagement_statusInActive__3Upjy::before {
  background: #c8c8c8; }

.CompanyManagement_arrow__1j8YS {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .CompanyManagement_arrow__1j8YS:before, .CompanyManagement_arrow__1j8YS:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .CompanyManagement_arrow__1j8YS:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .CompanyManagement_arrow__1j8YS:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.CompanyManagement_sectionTitle__1gave, .CompanyManagement_title__3jzC- {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.CompanyManagement_popUpTitle__2NKyX {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.CompanyManagement_popUpWrap__jSKl8 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.CompanyManagement_popUpText__1CC_d {
  text-align: center; }
  .CompanyManagement_popUpText__1CC_d:last-child {
    margin-bottom: 0; }

.CompanyManagement_visuallyHidden__11T73 {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.CompanyManagement_actionsBtn__28TuD {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .CompanyManagement_actionsBtn__28TuD svg {
    color: #ed2224; }
  .CompanyManagement_actionsBtn__28TuD:focus {
    outline: none; }

.CompanyManagement_actionsBtn__28TuD.CompanyManagement_actionsBtnDisabled__13wRn {
  background: none;
  opacity: 0.4; }

.CompanyManagement_blocked__1cqJU td,
.CompanyManagement_blocked__1cqJU span {
  color: #cccccc !important; }

.CompanyManagement_blocked__1cqJU .ant-checkbox-input:focus + .ant-checkbox-inner,
.CompanyManagement_blocked__1cqJU .ant-checkbox-inner {
  border-color: #cccccc; }

.CompanyManagement_isAdmin__m1M2N td,
.CompanyManagement_isAdmin__m1M2N span,
.CompanyManagement_isAccountManager__3mNeb td,
.CompanyManagement_isAccountManager__3mNeb span {
  font-weight: bold !important; }

.CompanyManagement_ellipsis__1k4tZ {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.CompanyManagement_wrapper__AOQuV {
  min-height: 100vh;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto 50px; }

.CompanyManagement_actionsBtnDisabled__13wRn > button[disabled] {
  background-color: transparent; }

.CompanyManagement_actionsBtnWrap__1PrUU {
  margin-left: 12px; }

.CompanyManagement_actionsBtnWrapDisabled__3oG0l {
  cursor: not-allowed;
  margin-left: 12px; }

.CompanyManagement_actionsBtnDisabled__13wRn {
  pointer-events: none; }

.CompanyManagement_title__3jzC- {
  margin-top: 20px; }

.CompanyManagement_tableWrap__1pHL6 .ant-table-wrapper {
  background: #ffffff; }
  .CompanyManagement_tableWrap__1pHL6 .ant-table-wrapper table,
  .CompanyManagement_tableWrap__1pHL6 .ant-table-wrapper tr,
  .CompanyManagement_tableWrap__1pHL6 .ant-table-wrapper th,
  .CompanyManagement_tableWrap__1pHL6 .ant-table-wrapper td {
    border-color: #C8CCCC !important; }
  .CompanyManagement_tableWrap__1pHL6 .ant-table-wrapper th {
    border-radius: 0 !important;
    text-align: center; }

.CompanyManagement_tableWrap__1pHL6 .ant-table-thead th {
  background: #666261;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  height: 40px;
  padding: 10px 20px; }

.CompanyManagement_tableWrap__1pHL6 .ant-table-thead .ant-table-selection-column span {
  display: none; }

.CompanyManagement_tableWrap__1pHL6 .ant-table-tbody tr:nth-child(even) {
  background: #F2F2F2; }

.CompanyManagement_tableWrap__1pHL6 .ant-table-tbody tr:hover:not(.ant-table-row-selected) > td {
  background: transparent !important; }

.CompanyManagement_tableWrap__1pHL6 .ant-table-tbody td {
  color: #424142;
  font-size: 14px; }

.CompanyManagement_tableWrap__1pHL6 .ant-table-tbody tr.ant-table-row-selected td {
  background: #ff9091;
  color: #ffffff; }
  .CompanyManagement_tableWrap__1pHL6 .ant-table-tbody tr.ant-table-row-selected td span {
    color: #ffffff; }

.CompanyManagement_tableWrap__1pHL6 .ant-table-pagination {
  margin-right: 30px; }

.CompanyManagement_tableWrap__1pHL6 .ant-pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 40px;
  float: none; }
  .CompanyManagement_tableWrap__1pHL6 .ant-pagination div,
  .CompanyManagement_tableWrap__1pHL6 .ant-pagination a {
    border: none; }
  .CompanyManagement_tableWrap__1pHL6 .ant-pagination * {
    color: #424142; }

.CompanyManagement_tableWrap__1pHL6 .ant-pagination-item {
  display: none; }

.CompanyManagement_tableWrap__1pHL6 .ant-pagination-options-size-changer {
  border-bottom: 1px solid #424142 !important; }

.CompanyManagement_tableWrap__1pHL6 .ant-pagination-options {
  -ms-flex-order: -1;
      order: -1;
  position: relative; }
  .CompanyManagement_tableWrap__1pHL6 .ant-pagination-options:before {
    position: absolute;
    content: 'Item per page:';
    display: -ms-flexbox;
    display: flex;
    top: 4px;
    left: -105px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }

.CompanyManagement_tableWrap__1pHL6 .ant-pagination-total-text {
  margin: 0 35px; }

.CompanyManagement_tableWrap__1pHL6 .ant-select-arrow {
  right: 2px;
  top: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #424142 transparent transparent transparent; }
  .CompanyManagement_tableWrap__1pHL6 .ant-select-arrow * {
    display: none; }

.CompanyManagement_tableWrap__1pHL6 .ant-select-focused .ant-select-selection,
.CompanyManagement_tableWrap__1pHL6 .ant-select-selection:focus,
.CompanyManagement_tableWrap__1pHL6 .ant-select-selection:active {
  box-shadow: none; }

.CompanyManagement_tableWrap__1pHL6 .ant-select-dropdown-menu-item-active {
  background: #ed2224 !important;
  color: #ffffff; }

.CompanyManagement_tableWrap__1pHL6 .ant-select-selection--single .ant-select-selection__rendered {
  margin: 0 15px 0 5px; }

.CompanyManagement_tableWrap__1pHL6 .ant-select-dropdown-menu-item {
  padding: 5px 10px; }

@media (max-width: 992px) {
  .CompanyManagement_tableWrap__1pHL6 .ant-table-thead > tr > th,
  .CompanyManagement_tableWrap__1pHL6 .ant-table-tbody > tr > td {
    padding: 16px 10px; } }

.CompanyManagement_amount__3w-0w {
  padding: 10px 5px !important; }

.CompanyManagement_optionsWrap__2r3eI {
  margin: 45px 0 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center; }

.CompanyManagement_searchWrap__ET73H {
  position: relative;
  -ms-flex-positive: 1;
      flex-grow: 1;
  width: 280px;
  height: 40px;
  margin-right: 30px; }
  .CompanyManagement_searchWrap__ET73H input {
    width: 100%;
    height: 100%;
    max-width: 390px;
    padding: 10px 10px 10px 60px;
    background: #F6F6F6;
    border: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }
  .CompanyManagement_searchWrap__ET73H:before {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    top: 13px;
    left: 20px;
    content: '';
    background: url(/static/media/search.d5aad510.svg) no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px; }

.CompanyManagement_details__252m7 {
  color: #ed2224;
  cursor: pointer;
  text-decoration: underline;
  border: 0;
  background: transparent; }
  .CompanyManagement_details__252m7 svg {
    font-size: 20px; }

.CompanyManagement_actionsWrap__3z8lY {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .CompanyManagement_actionsWrap__3z8lY button:last-child {
    margin-right: 5px; }

.CompanyManagement_actionsTableCol__2uC1r {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center; }
  .CompanyManagement_actionsTableCol__2uC1r svg {
    fill: #ed2224;
    width: 26px;
    height: 26px; }

.CompanyManagement_sortImg__2i7NF {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  word-break: normal; }
  .CompanyManagement_sortImg__2i7NF img {
    cursor: pointer;
    margin-left: 5px;
    height: 14px; }

.CompanyManagement_tdCenter__3bA1G {
  text-align: center; }

.CompanyManagement_searchedWord__19dfE {
  background-color: #ed2224;
  color: #ffffff;
  padding: 0 3px 1.5px;
  border-radius: 10px;
  font-weight: 600; }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.CompanyDetails_btn__tu7Hm, .CompanyDetails_btnNo__1tWic, .CompanyDetails_btnYes__2I3HN {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .CompanyDetails_btn__tu7Hm:hover, .CompanyDetails_btnNo__1tWic:hover, .CompanyDetails_btnYes__2I3HN:hover {
    background: #ed2224;
    color: #ffffff; }
  .CompanyDetails_btn__tu7Hm:active, .CompanyDetails_btnNo__1tWic:active, .CompanyDetails_btnYes__2I3HN:active {
    background: #DC2426;
    color: #ffffff; }

.CompanyDetails_btnNo__1tWic {
  width: 100px; }

.CompanyDetails_btnYes__2I3HN {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.CompanyDetails_statusWpar__Xs8mH {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .CompanyDetails_statusWpar__Xs8mH::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.CompanyDetails_statusActive__3OI1-::before {
  background: #28A181; }

.CompanyDetails_statusInActive__PwmGr::before {
  background: #c8c8c8; }

.CompanyDetails_arrow__j3sM3 {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .CompanyDetails_arrow__j3sM3:before, .CompanyDetails_arrow__j3sM3:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .CompanyDetails_arrow__j3sM3:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .CompanyDetails_arrow__j3sM3:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.CompanyDetails_sectionTitle__1N4T8, .CompanyDetails_title__2ZYFa {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.CompanyDetails_popUpTitle__2ryI9 {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.CompanyDetails_popUpWrap__22RH8 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.CompanyDetails_popUpText__3fo52 {
  text-align: center; }
  .CompanyDetails_popUpText__3fo52:last-child {
    margin-bottom: 0; }

.CompanyDetails_visuallyHidden__2WDTj {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.CompanyDetails_actionsBtn__Ve5Q7, .CompanyDetails_actionsBtnImage__3VsZj {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .CompanyDetails_actionsBtn__Ve5Q7 svg, .CompanyDetails_actionsBtnImage__3VsZj svg {
    color: #ed2224; }
  .CompanyDetails_actionsBtn__Ve5Q7:focus, .CompanyDetails_actionsBtnImage__3VsZj:focus {
    outline: none; }

.CompanyDetails_actionsBtn__Ve5Q7.CompanyDetails_actionsBtnDisabled__1YLN9, .CompanyDetails_actionsBtnDisabled__1YLN9.CompanyDetails_actionsBtnImage__3VsZj {
  background: none;
  opacity: 0.4; }

.CompanyDetails_blocked__2M8TC td,
.CompanyDetails_blocked__2M8TC span {
  color: #cccccc !important; }

.CompanyDetails_blocked__2M8TC .ant-checkbox-input:focus + .ant-checkbox-inner,
.CompanyDetails_blocked__2M8TC .ant-checkbox-inner {
  border-color: #cccccc; }

.CompanyDetails_isAdmin__2_9LL td,
.CompanyDetails_isAdmin__2_9LL span,
.CompanyDetails_isAccountManager__1kLr4 td,
.CompanyDetails_isAccountManager__1kLr4 span {
  font-weight: bold !important; }

.CompanyDetails_ellipsis__1Pdzc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.CompanyDetails_wrapper__3YRUd {
  min-height: 100vh;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto 50px; }

.CompanyDetails_title__2ZYFa {
  margin-top: 20px; }

.CompanyDetails_tableWrap__2uMqs .ant-table-wrapper {
  background: #ffffff; }
  .CompanyDetails_tableWrap__2uMqs .ant-table-wrapper table, .CompanyDetails_tableWrap__2uMqs .ant-table-wrapper tr, .CompanyDetails_tableWrap__2uMqs .ant-table-wrapper th, .CompanyDetails_tableWrap__2uMqs .ant-table-wrapper td {
    border-color: #C8CCCC !important; }
  .CompanyDetails_tableWrap__2uMqs .ant-table-wrapper th {
    border-radius: 0 !important;
    text-align: center; }

.CompanyDetails_tableWrap__2uMqs .ant-table-thead th {
  background: #666261;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  height: 40px;
  padding: 10px 20px; }

.CompanyDetails_tableWrap__2uMqs .ant-table-thead .ant-table-selection-column span {
  display: none; }

.CompanyDetails_tableWrap__2uMqs .ant-table-tbody tr:nth-child(even) {
  background: #F2F2F2; }

.CompanyDetails_tableWrap__2uMqs .ant-table-tbody tr:hover:not(.ant-table-row-selected) > td {
  background: transparent !important; }

.CompanyDetails_tableWrap__2uMqs .ant-table-tbody td {
  color: #424142;
  font-size: 14px; }

.CompanyDetails_tableWrap__2uMqs .ant-table-tbody tr.ant-table-row-selected td {
  background: #ff9091;
  color: #ffffff; }
  .CompanyDetails_tableWrap__2uMqs .ant-table-tbody tr.ant-table-row-selected td span {
    color: #ffffff; }

.CompanyDetails_tableWrap__2uMqs .ant-table-pagination {
  margin-right: 30px; }

.CompanyDetails_tableWrap__2uMqs .ant-pagination {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 40px;
  float: none; }
  .CompanyDetails_tableWrap__2uMqs .ant-pagination div, .CompanyDetails_tableWrap__2uMqs .ant-pagination a {
    border: none; }
  .CompanyDetails_tableWrap__2uMqs .ant-pagination * {
    color: #424142; }

.CompanyDetails_tableWrap__2uMqs .ant-pagination-item {
  display: none; }

.CompanyDetails_tableWrap__2uMqs .ant-pagination-options-size-changer {
  border-bottom: 1px solid #424142 !important; }

.CompanyDetails_tableWrap__2uMqs .ant-pagination-options {
  -ms-flex-order: -1;
      order: -1;
  position: relative; }
  .CompanyDetails_tableWrap__2uMqs .ant-pagination-options:before {
    position: absolute;
    content: 'Item per page:';
    display: -ms-flexbox;
    display: flex;
    top: 4px;
    left: -105px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }

.CompanyDetails_tableWrap__2uMqs .ant-pagination-total-text {
  margin: 0 35px; }

.CompanyDetails_tableWrap__2uMqs .ant-select-arrow {
  right: 2px;
  top: 20px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  border-color: #424142 transparent transparent transparent; }
  .CompanyDetails_tableWrap__2uMqs .ant-select-arrow * {
    display: none; }

.CompanyDetails_tableWrap__2uMqs .ant-select-focused .ant-select-selection,
.CompanyDetails_tableWrap__2uMqs .ant-select-selection:focus,
.CompanyDetails_tableWrap__2uMqs .ant-select-selection:active {
  box-shadow: none; }

.CompanyDetails_tableWrap__2uMqs .ant-select-dropdown-menu-item-active {
  background: #ed2224 !important;
  color: #ffffff; }

.CompanyDetails_tableWrap__2uMqs .ant-select-selection--single .ant-select-selection__rendered {
  margin: 0 15px 0 5px; }

.CompanyDetails_tableWrap__2uMqs .ant-select-dropdown-menu-item {
  padding: 5px 10px; }

.CompanyDetails_amount__1Jknm {
  padding: 10px 5px !important; }

.CompanyDetails_optionsWrap__eRyhV {
  margin: 45px 35px 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center; }

.CompanyDetails_searchWrap__1IgEe {
  position: relative;
  width: 280px;
  height: 40px; }
  .CompanyDetails_searchWrap__1IgEe input {
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 60px;
    background: #F6F6F6;
    border: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px; }
  .CompanyDetails_searchWrap__1IgEe:before {
    position: absolute;
    display: -ms-flexbox;
    display: flex;
    top: 13px;
    left: 20px;
    content: '';
    background: url(/static/media/search.d5aad510.svg) no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px; }

.CompanyDetails_wrapper9Box__3umJ0 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: justify;
      justify-content: space-between;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  cursor: pointer; }

.CompanyDetails_modalWrap__KX5T_ {
  max-width: 800px; }

.CompanyDetails_round__1eMUP {
  display: -ms-flexbox;
  display: flex;
  width: 7px;
  height: 7px; }
  .CompanyDetails_round__1eMUP svg {
    width: 100%;
    height: 100%; }

.CompanyDetails_details__108vI {
  color: #ed2224;
  cursor: pointer;
  text-decoration: underline; }

.CompanyDetails_actionsWrap__cPfqY {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .CompanyDetails_actionsWrap__cPfqY button:last-child {
    margin-right: 5px; }

.CompanyDetails_actionsTableCol__3DhOx {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }
  .CompanyDetails_actionsTableCol__3DhOx span:first-child {
    margin-right: 12px; }
  .CompanyDetails_actionsTableCol__3DhOx svg {
    width: 26px;
    height: 26px; }

.CompanyDetails_actionsBtnImage__3VsZj img {
  height: 25px; }


a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.DashboardsMenu_btn__2PthD, .DashboardsMenu_btnNo__2vLmI, .DashboardsMenu_btnYes__3fpKP, .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsBtn__3pUNS {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .DashboardsMenu_btn__2PthD:hover, .DashboardsMenu_btnNo__2vLmI:hover, .DashboardsMenu_btnYes__3fpKP:hover, .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsBtn__3pUNS:hover {
    background: #ed2224;
    color: #ffffff; }
  .DashboardsMenu_btn__2PthD:active, .DashboardsMenu_btnNo__2vLmI:active, .DashboardsMenu_btnYes__3fpKP:active, .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsBtn__3pUNS:active {
    background: #DC2426;
    color: #ffffff; }

.DashboardsMenu_btnNo__2vLmI {
  width: 100px; }

.DashboardsMenu_btnYes__3fpKP {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.DashboardsMenu_statusWpar__2WUFC {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .DashboardsMenu_statusWpar__2WUFC::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.DashboardsMenu_statusActive__2Y_Pm::before {
  background: #28A181; }

.DashboardsMenu_statusInActive__DMuVF::before {
  background: #c8c8c8; }

.DashboardsMenu_arrow__3Ug_f {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .DashboardsMenu_arrow__3Ug_f:before, .DashboardsMenu_arrow__3Ug_f:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .DashboardsMenu_arrow__3Ug_f:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .DashboardsMenu_arrow__3Ug_f:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.DashboardsMenu_sectionTitle__39hgG {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.DashboardsMenu_popUpTitle__xcEyO {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.DashboardsMenu_popUpWrap__3wyHL {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.DashboardsMenu_popUpText__3BlSV {
  text-align: center; }
  .DashboardsMenu_popUpText__3BlSV:last-child {
    margin-bottom: 0; }

.DashboardsMenu_visuallyHidden__l_0OM {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.DashboardsMenu_actionsBtn__12plw {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .DashboardsMenu_actionsBtn__12plw svg {
    color: #ed2224; }
  .DashboardsMenu_actionsBtn__12plw:focus {
    outline: none; }

.DashboardsMenu_actionsBtn__12plw.DashboardsMenu_actionsBtnDisabled__3et0t {
  background: none;
  opacity: 0.4; }

.DashboardsMenu_blocked__3kERG td,
.DashboardsMenu_blocked__3kERG span {
  color: #cccccc !important; }

.DashboardsMenu_blocked__3kERG .ant-checkbox-input:focus + .ant-checkbox-inner,
.DashboardsMenu_blocked__3kERG .ant-checkbox-inner {
  border-color: #cccccc; }

.DashboardsMenu_isAdmin__2Csaa td,
.DashboardsMenu_isAdmin__2Csaa span,
.DashboardsMenu_isAccountManager__3kQhp td,
.DashboardsMenu_isAccountManager__3kQhp span {
  font-weight: bold !important; }

.DashboardsMenu_ellipsis__3EvnN {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.DashboardsMenu_menu__nUbE2 {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none; }
  .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsTitle__wrCJ1 {
    color: #ed2224;
    letter-spacing: 1.21333px;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    padding: 12px 8px 24px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsTitle__wrCJ1 .DashboardsMenu_title__C8zqP {
      transition: .1s;
      transform: scale(1); }
    .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsTitle__wrCJ1 svg {
      transition: .1s;
      cursor: pointer; }
      .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsTitle__wrCJ1 svg:hover path {
        fill: rgba(237, 34, 36, 0.8); }
  .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsBtn__3pUNS {
    width: calc(100% - 16px);
    margin: 0 8px 8px;
    height: 40px;
    -ms-flex-align: center;
        align-items: center;
    transition: .1s; }
    .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsBtn__3pUNS svg {
      color: red;
      margin-right: 14px; }
    .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsBtn__3pUNS:hover svg {
      color: white; }
      .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsBtn__3pUNS:hover svg rect {
        fill: white; }
  .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsIcon__3W4ow {
    border-radius: 50%;
    background: rgba(200, 204, 204, 0.61);
    width: 22px;
    height: 22px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.21333px;
    color: #424142;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-direction: column;
        flex-direction: column;
    text-align: center;
    margin-right: 6px; }
  .DashboardsMenu_menu__nUbE2 .DashboardsMenu_dashboardsItem__1yIez {
    width: calc(100% - 28px);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: .1s; }
  .DashboardsMenu_menu__nUbE2 .ant-layout {
    background: #fff; }
  .DashboardsMenu_menu__nUbE2 .ant-menu-submenu-title {
    transition: .1s; }
  .DashboardsMenu_menu__nUbE2 .ant-menu-item {
    transition: .1s; }
  .DashboardsMenu_menu__nUbE2 .ant-menu-inline {
    border-right: none; }
  .DashboardsMenu_menu__nUbE2 .ant-layout-sider {
    background: #fff;
    border: 1px solid #C8CCCC;
    border-radius: 4px;
    margin-right: 8px;
    width: 200px !important;
    min-width: unset !important;
    max-width: unset !important;
    transition: .1s; }
  .DashboardsMenu_menu__nUbE2 .ant-layout-sider-trigger {
    display: none; }
  .DashboardsMenu_menu__nUbE2 .ant-menu-submenu-title {
    height: unset;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px !important;
    color: #424142;
    background: rgba(221, 224, 224, 0.4);
    border-radius: 8px;
    padding: 11px 7px !important; }
  .DashboardsMenu_menu__nUbE2 .ant-menu-submenu .ant-menu-item {
    padding: 9px 8px !important;
    height: unset !important;
    margin: 6px 0;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.21333px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center; }
    .DashboardsMenu_menu__nUbE2 .ant-menu-submenu .ant-menu-item:hover {
      color: #ed2224; }
    .DashboardsMenu_menu__nUbE2 .ant-menu-submenu .ant-menu-item.isOwned {
      font-weight: bold; }
  .DashboardsMenu_menu__nUbE2 .ant-menu-submenu .ant-menu-submenu-arrow:before, .DashboardsMenu_menu__nUbE2 .ant-menu-submenu .ant-menu-submenu-arrow:after {
    background: #ed2224 !important; }
  .DashboardsMenu_menu__nUbE2 .DashboardsMenu_selected__2rp3s {
    background: rgba(244, 209, 209, 0.5) !important;
    color: #424142 !important; }
    .DashboardsMenu_menu__nUbE2 .DashboardsMenu_selected__2rp3s .DashboardsMenu_dashboardsIcon__3W4ow {
      color: #FF0000;
      background: #FFAEAE; }
    .DashboardsMenu_menu__nUbE2 .DashboardsMenu_selected__2rp3s:after {
      height: 55%;
      border-color: #FF0000;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      top: 23%; }
  .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .ant-layout-sider {
    width: 68px !important; }
  .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .ant-menu-submenu-title {
    padding: 8px 12px !important; }
    .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .ant-menu-submenu-title svg {
      width: 24px;
      height: 24px; }
  .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .ant-menu-submenu-arrow {
    transform: translate3d(6px, -2px, 0) !important; }
  .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .ant-menu-item {
    -ms-flex-pack: center;
        justify-content: center; }
  .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .DashboardsMenu_dashboardsTitle__wrCJ1 {
    padding-top: 18px;
    -ms-flex-pack: center;
        justify-content: center; }
    .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .DashboardsMenu_dashboardsTitle__wrCJ1 .DashboardsMenu_title__C8zqP {
      transform: scale(0);
      display: none; }
    .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .DashboardsMenu_dashboardsTitle__wrCJ1 svg {
      transform: rotateY(180deg); }
  .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .DashboardsMenu_dashboardsBtn__3pUNS svg {
    margin: 0; }
  .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .DashboardsMenu_dashboardsBtn__3pUNS:before {
    left: unset; }
  .DashboardsMenu_menu__nUbE2.DashboardsMenu_collapsed__1iKg_ .DashboardsMenu_dashboardsItem__1yIez {
    width: 0; }

.DashboardsMenu_menu__nUbE2::-webkit-scrollbar {
  width: 10px; }

.DashboardsMenu_menu__nUbE2::-webkit-scrollbar-track {
  background-color: #f1f1f1; }

.DashboardsMenu_menu__nUbE2::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  box-shadow: inset 2px 2px 5px 0 rgba(255, 255, 255, 0.5); }

a:hover {
  color: #ed2224; }

button[disabled] {
  background: #ffffff;
  color: #C8CCCC;
  cursor: no-drop;
  border: 2px solid transparent; }
  button[disabled]:hover {
    background: inherit;
    color: #C8CCCC; }

button:focus {
  outline: none; }

.Dashboards_btn__31NAy, .Dashboards_btnNo__2tOm8, .Dashboards_btnYes__3hqq2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  border-radius: 6px;
  background: #ffffff;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid #ed2224;
  cursor: pointer;
  transition: 0.3s; }
  .Dashboards_btn__31NAy:hover, .Dashboards_btnNo__2tOm8:hover, .Dashboards_btnYes__3hqq2:hover {
    background: #ed2224;
    color: #ffffff; }
  .Dashboards_btn__31NAy:active, .Dashboards_btnNo__2tOm8:active, .Dashboards_btnYes__3hqq2:active {
    background: #DC2426;
    color: #ffffff; }

.Dashboards_btnNo__2tOm8 {
  width: 100px; }

.Dashboards_btnYes__3hqq2 {
  width: 100px;
  margin-right: 20px;
  background: #ed2224;
  color: #ffffff; }

.Dashboards_statusWpar__2-PTH {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #817B7A;
  border-radius: 50%;
  cursor: pointer; }
  .Dashboards_statusWpar__2-PTH::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: #f9d772;
    border-radius: 50%; }

.Dashboards_statusActive__2rdnp::before {
  background: #28A181; }

.Dashboards_statusInActive__3sU8Z::before {
  background: #c8c8c8; }

.Dashboards_arrow__2JhuV {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  -ms-flex-item-align: center;
      align-self: center;
  margin: 5px;
  transition: 0.5s; }
  .Dashboards_arrow__2JhuV:before, .Dashboards_arrow__2JhuV:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: #ed2224;
    border-radius: 10px; }
  .Dashboards_arrow__2JhuV:before {
    transform: rotate(45deg) translate(2px, 3px); }
  .Dashboards_arrow__2JhuV:after {
    transform: rotate(-45deg) translate(-8px, 7px); }

.Dashboards_sectionTitle__3RDJY {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: #424142; }

.Dashboards_popUpTitle__1iCGH {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid #ed2224;
  color: #424142; }

.Dashboards_popUpWrap__VJmwL {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px; }

.Dashboards_popUpText__14-2V {
  text-align: center; }
  .Dashboards_popUpText__14-2V:last-child {
    margin-bottom: 0; }

.Dashboards_visuallyHidden__1QscR {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px; }

.Dashboards_actionsBtn__1jlB4 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
      align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: #000000;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/ }
  .Dashboards_actionsBtn__1jlB4 svg {
    color: #ed2224; }
  .Dashboards_actionsBtn__1jlB4:focus {
    outline: none; }

.Dashboards_actionsBtn__1jlB4.Dashboards_actionsBtnDisabled__SytvK {
  background: none;
  opacity: 0.4; }

.Dashboards_blocked__1ddNx td,
.Dashboards_blocked__1ddNx span {
  color: #cccccc !important; }

.Dashboards_blocked__1ddNx .ant-checkbox-input:focus + .ant-checkbox-inner,
.Dashboards_blocked__1ddNx .ant-checkbox-inner {
  border-color: #cccccc; }

.Dashboards_isAdmin__81_YR td,
.Dashboards_isAdmin__81_YR span,
.Dashboards_isAccountManager__2ZypZ td,
.Dashboards_isAccountManager__2ZypZ span {
  font-weight: bold !important; }

.Dashboards_ellipsis__27zzM {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap; }

.Dashboards_dashboards__e8P6n {
  background: #fff; }

.Dashboards_layout__17C_r {
  background: transparent;
  overflow-y: auto; }

.Dashboards_empty__4UWuu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  height: 100%;
  -ms-flex-align: center;
      align-items: center;
  color: #414042;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px; }

