@import '../../styles/variables';
@import '../../styles/common';
@import '../../styles/popUpForms';

header {
  position: sticky;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  margin-bottom: 10px;
  padding: 0 60px;
  background: $mainBg;
  z-index: 200;

  .refresh {
    width: 100%;
    padding: 10px 16px 20px;
    border: 1px solid #C8CCCC;
    border-radius: 4px;
    background: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .refreshText {
      color: $red;
      font-size: 18px;
      font-weight: bold;

      .refreshImg {
        margin-right: 16px;
        transform: scale(1.35);

        path {
          fill: $red
        }
      }
    }

    .refreshDesc {
      color: $dark;
      font-size: 13px;
    }

    .stepsBtn {
      padding: 1.5px 7px;
      height: unset;
      width: auto;
      margin: unset;
    }

    .refreshTime {
      position: absolute;
      bottom: 3px;
      right: 16px;
      font-size: 10px;
    }
  }
}

.infoWhenShared {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: calc(100% - 120px);
  height: 75px;
  border: 1px solid rgb(262, 14, 14);
  margin: 0 auto 10px;
  border-radius: 4px;
  background: #eecece;
  font-weight: bold;
  font-size: 1.2rem;
  max-width: 1920px;

  .companyName {
    margin-left: 15px;
  }

  .footerButton {
    @extend .btn;

    .centeredText {
      display: flex;
      align-items: center;
    }

    margin: 15px;
    padding: 11px 30px;
    border-radius: 4px;

    &:hover {
      .buttonImg {
        path {
          fill: $white;
        }
      }
    }
  }
}

.logo {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: $dark;
  }
}

nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
}

.links {
  display: flex;
}

.link {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 1.21333px;
  color: $dark;
  text-decoration: none;
  margin-right: 50px;
  text-transform: uppercase;
  padding: 5px 0;
  flex-shrink: 0;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: $red;
  }
}

.linkMenu {
  margin-right: 0px;
  width: 100%;
  text-align: left;
}

.logout {
  background: none;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
}

.linkActive {
  color: $mainBtn;
}

:global {
  .ant-dropdown-menu-item {
    & > a {
      display: inline-block;
    }
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
  }
}

$width: 500px;
$height: 600px;

.modalWrap {
  width: $width !important;

  .wrapper {
    height: $height;
    padding: 25px;
  }

  .title {
    @extend .popUpTitle;
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-size: 30px;
  }

  .innerWrap {
    height: 100%;
    width: 100%;
  }

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-height: $height;
    width: 100%;
    padding: 25px 35px;

    .text {
      margin: 15px 10px 20px 10px;
      font-size: 10px;
    }

    :global {
      .ant-input:focus {
        border-color: $mainBtn;
        --antd-wave-shadow-color: $mainBtn;
        box-shadow: 0 0 0 2px #e76566;
      }

      .ant-input:hover {
        border-color: $mainBtn;
      }
    }
  }

  .btnWrapper {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .stepsBtn {
    @extend .btn;
    position: relative;
    background: $white;
    border: 2px solid $mainBtn;
    max-width: 200px;
    width: 100%;
    margin: auto 10px 0 10px;
    height: 40px;
  }

  .stepsBtnFill {
    @extend .btn;
    position: relative;
    background: $mainBtn;
    border: 2px solid $mainBtn;
    max-width: 200px;
    width: 100%;
    margin: auto 10px 0 10px;
    height: 40px;
    color: $white;
  }

  .pointer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    cursor: pointer;
  }

  .noWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .textQuestion {
    margin: 0 10px 20px 10px;
    font-size: 16px;
  }

  .iconPencil {
    position: absolute;
    height: 10px;
    width: 10px;
    top: -5px;
    left: 73px;
  }

  .link {
    font-weight: bold;
    color: #2944ae;
    text-decoration: underline;
  }

  .errorText {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 65px;
    text-align: center;
    font-size: 14px;
    color: $error;
  }

  .subTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: $black;
    text-align: left;
    align-self: flex-start;

    ul {
      margin-left: 20px;
    }
  }
}

.stepsBtn {
  @extend .btn;
  position: relative;
  background: $white;
  border: 2px solid $mainBtn;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px;
}

.wrapper {
  max-width: 335px;
  width: 100%;
}

.wrapperTitle {
  margin-bottom: 30px !important;
}

.menuIcon {
  margin-left: 6px;
}

.radio {
  margin: 0 5px;
}

.radioWrap {
  margin-bottom: 30px;
}

.radioItemWrap {
  position: relative;
  display: flex;
  align-items: center;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 180%;
    color: rgba(0, 0, 0, 0.75);

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.logoCopyright {
  margin-top: 5px;
  color: $darkThree;
  font-size: 10px;
}
