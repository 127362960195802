@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

.wrapper {
  @extend .popUpWrap;
}

.title {
  @extend .popUpTitle;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px;
}

.titleSection {
  margin: 10px 0 20px 0;
  color: $dark;
}

.shareAccessBlock {
  margin-left: 25px;

  .shareAccessContainer {
    display: flex;
    align-items: center;
    .shareAccessTextContainer {
      max-width: 700px;
    }
  }

  .shareAccessDefText {
    font-size: 1.1rem;
    font-weight: bold;
    max-width: 800px;
  }

  .shareAccessText {
    margin: 0 10px 0 20px;
  }
}

.notModal {
  display: flex;

  .notModalBlock {
    width: 100%;
  }
}

.generalItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sectionWrap {
  padding: 10px 20px;
}

.btnSave {
  @extend .btnYes;
}

.inputDisabled {
  border: none;
}

.inputDisabledNew {
  width: 200px;
}

.modalWrap {
  margin: 0 auto;
  width: 400px !important;
}

.modalSection {
  padding: 20px;
  text-align: center;
}

.btnModalWrap {
  margin-top: 30px;
}

.selectIcon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: $dark transparent transparent transparent;
  margin-top: 4px;
}

.selectDropdown {
  li {
    font-size: 14px;
    padding: 0 10px 5px;
  }

  :global {
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background: $mainBtn !important;
      color: white;
    }

    .ant-select-dropdown-menu-item-selected {
      background: none !important;
      color: $mainBtn;
    }
  }
}
