@import '../../styles/forms';
@import '../../styles/variables';

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='password'] {
  font: large Verdana, sans-serif;
  -webkit-appearance: none;

  &::-ms-reveal,
  &::-ms-clear {
    display: none;
  }
}

.labelWrapper {
  @extend .labelWrapper;
  border-bottom: 1px solid $grayLight;
}

.inputWrapper {
  position: relative;
  z-index: 5;
  input {
    padding-right: 25px;
    box-sizing: border-box;
  }
}

.inputActive {
  border-bottom: 1px solid $mainBtn;
}

.inputTxt {
  @extend .inputTxt;
  padding: 5px 0;

  &::-ms-clear {
    height: 31px;
  }
}

.passwordInputWrapper {
  position: relative;

  .warningIcon {
    right: 30px;
  }
}

.viewIconBtn {
  padding: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 20px;
}

.viewPasswordIcon {
  color: $mainGray;
  font-size: 16px;
}
