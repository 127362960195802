@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

.wrapper {
  @extend .popUpWrap;
}

.title {
  @extend .popUpTitle;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px;
}

.titleSection {
  margin: 10px 0 20px 0;
  color: $dark;
}

.titleSectionBig {
  margin: 10px 0 20px 0;
  font-size: 25px;
  color: $dark;
  text-align: center;
}

.generalItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sectionWrap {
  padding: 5px 0px;
}

.btnSave {
  @extend .btnYes;
}
.btnGet {
  @extend .btn;
  margin-right: 20px;
}

.inputSectionAdditionalMargins {
  margin: 35px 10px;
}

.valuesSetting, .orientationLogic {
  display: flex;
}

.btnsChangeOrientation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .btnWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;

    .btnLabel {
      font-size: 1.1rem;
      font-weight: bold;
    }

    button {
      margin-left: 10px;
      width: 125px;

      &:hover {
        svg {
          fill: white;
        }
      }
    }

  }
}

.orientationMatrix {
  position: relative;
  display: flex;
  margin: 20px 0;
  width: 100%;

  .label1 {
    position: absolute;
    transform: rotate(-90deg);
    left: -91px;
    top: 65px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .label2 {
    position: absolute;
    top: 160px;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
}

.matrixBlock {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  margin: 0 auto;
  height: 151px;
  width: 152px;
  border-right: 1px solid red;
  border-bottom: 1px solid red;

  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    box-sizing: border-box;
    border-left: 1px solid red;
    border-top: 1px solid red;
    font-size: 12px;
  }
}

.container9boxValues {
  display: flex;
}

.buttonsRow {
  display: flex;
  margin: 10px 10px;
  justify-content: space-between;
}

.surveysBlock {
  display: flex;
  margin: 10px 10px;
  justify-content: space-between;
  flex-direction: column;
  height: 300px;
  overflow-y: auto;
  & .surveysCheckBox {
    margin: 5px 5px;
  }
}

.noDataBlock {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  p {
    font-size: 20px;
    margin: 0;
  }

  svg {
    font-size: 26px;
    margin-right: 10px;
    * {
      color: $grayLight;
    }
  }
}

.boldText {
  font-weight: bold;
  font-size: 1.5rem;

  input {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.boldSpan{
  font-weight: bold;
  color: rgba(0,0,0, 0.8)
}

.inputDisabled {
  border: none;
}

.inputDisabledNew {
  width: 200px;
}

.modalWrap {
  margin: 0 auto;
  width: 400px!important;
}

.modalSection {
  padding: 20px;
  text-align: center;
}

.btnModalWrap {
  margin-top: 30px;
}


