@import '../../../styles/variables';
@import '../../../styles/common';

.wrapper {
  min-height: 100vh;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto 50px;

  //*:focus {
  //  outline: 1px solid rgba($color: $mainBtn, $alpha: 0.5)!important;
  //  outline-offset: 3px!important;
  //}
}

.title {
  @extend .sectionTitle;

  margin-top: 20px;
}

.tableWrap {

  :global {
    .ant-table-wrapper {
      background: $white;

      table, tr, th, td {
        border-color: $grayLight!important;
      }

      th {
        border-radius: 0!important;
        text-align: center;
      }
    }

    .ant-table-thead {

      th {
        background: $darkTwo;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        height: 40px;
        padding: 10px 20px;
      }

      .ant-table-selection-column {
        span {
          display: none;
        }
      }
    }

    .ant-table-tbody {
      tr:nth-child(even) {
        background: $grayLightTwo;
      }

      tr:hover:not(.ant-table-row-selected) > td {
        background: transparent!important;
      }

      td {
        color: $dark;
        font-size: 14px;
      }

      tr.ant-table-row-selected td {
        background: #ff9091;
        color: $white;

        span {
          color: $white;
        }
      }
    }

    .ant-table-pagination {
      margin-right: 30px;
    }

    .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      float: none;

      div, a {
        border: none;
      }

      * {
        color: $dark;
      }
    }

    .ant-pagination-item {
      display: none;
    }

    .ant-pagination-options-size-changer {
      border-bottom: 1px solid $dark!important;
    }

    .ant-pagination-options {
      order: -1;
      position: relative;

      &:before {
        position: absolute;
        content: 'Item per page:';
        display: flex;
        top: 4px;
        left: -105px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .ant-pagination-total-text {
      margin: 0 35px;
    }

    .ant-select-arrow {
      right: 2px;
      top: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: $dark transparent transparent transparent;

      * {
        display: none;
      }
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }

    .ant-select-dropdown-menu-item-active {
      background: $mainBtn!important;
      color: $white;
    }

    .ant-select-selection--single .ant-select-selection__rendered {
      margin: 0 15px 0 5px;
    }

    .ant-select-dropdown-menu-item{
      padding: 5px 10px;
    }
  }
}

.amount {
  padding: 10px 5px!important;
}

.optionsWrap {
  margin: 45px 35px 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.searchWrap {
  position: relative;
  width: 280px;
  height: 40px;

  input {
    width: 100%;
    height: 100%;
    padding: 10px 10px 10px 60px;
    background: $grayInput;
    border: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }

  &:before {
    position: absolute;
    display: flex;
    top: 13px;
    left: 20px;
    content: '';
    background: url('../../../images//icons/search.svg') no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px;
  }
}

.wrapper9Box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 25px;
  height: 25px;
  margin-right: 15px;
  cursor: pointer;
}

.modalWrap {
  max-width: 800px;
}

.round {
  display: flex;
  width: 7px;
  height: 7px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.details {
  color: $mainBtn;
  cursor: pointer;
  text-decoration: underline;
}

.actionsWrap {
  display: flex;
  align-items: center;
  button:last-child {
    margin-right: 5px;
  }
}

.actionsTableCol {
  display: flex;
  justify-content: center;
  span:first-child {
    margin-right: 12px;
  }
  svg {
    width: 26px;
    height: 26px;
  }
}

.actionsBtnImage {
  @extend .actionsBtn;
  img {
    height: 25px;
  }
}
