@import '../../../styles/variables';
@import '../../../styles/common';
@import '../../../styles/popUpForms';

.wrapper {
  margin-top: 30px;
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;

  :global {
    .ant-upload {
      width: 100%;
    }
  }
}

.title {
  @extend .sectionTitle;

  margin: 20px 0;
  text-transform: none;
}

.titleTwo {
  margin: 75px 0 50px;
}

.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: $black;
  margin-bottom: 20px;
}

.dataSetBtn {
  @extend .btn;
  position: relative;
  border: 2px solid $mainBtn;
  max-width: 200px;
  width: 100%;
  margin: auto 0 22px auto;
  height: 40px;
  align-items: center;

  svg {
    margin-right: 14px;
    font-size: 17px;
    color: red;
  }

  &:hover {
    svg {
      color: white;
      /*rect {
        fill: white;
      }*/
    }
  }
}

.modalWrap {
  .innerWrap {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 25px 35px;

    .title {
      @extend .popUpTitle;
      padding-bottom: 10px;
      margin-bottom: 5px;
      font-size: 30px;
    }

    form {
      padding: 60px 20px 10px;

      .formInput {
        margin-bottom: 70px;
      }
    }
  }
}

.modalConfirm {
  width: 400px !important;
}

.stepsWrap {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: $media-xl) {
    flex-wrap: wrap;
  }
}

.stepsWrapItem {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  background: $white;
  padding: 30px 49px 50px;
  margin-bottom: 5px;
  border: 1px solid $grayLight;
  border-radius: 4px;

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.stepsTitleWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 27px;
}

.stepsTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  margin-left: 20px;
  color: $mainBtn;
  margin-bottom: 0;
}

.stepsDescription {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: $dark;
  margin-bottom: 30px;
}

.stepsBtn {
  @extend .btn;
  position: relative;
  background: $white;
  border: 2px solid $mainBtn;
  max-width: 200px;
  width: 100%;
  margin: auto auto 0 auto;
  height: 40px;
}

.stepsTime {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;
  margin: 0;

  span {
    margin-left: 5px;
  }
}

.descriptionWrap {
  display: flex;
  justify-content: space-between;
  margin: 0 40px;

  @media only screen and (max-width: $media-xl) {
    flex-wrap: wrap;
  }
}

.descriptionItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 330px;
  width: 100%;
  min-height: 295px;
  padding: 40px 45px 45px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  background: rgba(200, 204, 204, 0.15);

  &:not(:last-child) {
    margin-right: 5px;
  }
}

.descriptionTitle {
  @extend .stepsTitle;
  margin-top: 45px;
  margin-left: 0;
}

.descriptionText {
  @extend .stepsDescription;
  width: 100%;
  margin: 15px 0 50px;
}

.refreshImg {
  margin-right: 10px;
  transform: scale(1.6);

  path {
    fill: $mainBtn;
  }
}
