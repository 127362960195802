@import './variables';

.inputLabeled {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: $mainGray;
  font-size: 16px;
  z-index: -1;
  background: transparent;
  transition: transform 150ms ease-out, font-size 150ms ease-out;
  cursor: text;

  &.fieldActive {
    transform: translateY(-215%);
    font-size: 10px;
    color: $grayDark;
    top: 55%;
  }

}

.inputTxt {
  display: block;
  width: 100%;
  min-height: 33px;
  border: none;
  background: none;
  color: $dark;
  font-size: 16px;
  line-height: 19px;

    &:focus {
    outline: none;
  }
}
.inputTxt.passwordStyle {
  font-family: password-mask;
  letter-spacing: 1.6px;
  font-size: 16px;
  text-security:disc;
  -webkit-text-security:disc;
  -mox-text-security:disc;
}

.messageError {
  position: absolute;
  top: 100%;
  color: $error;
  font-size: 12px;
  min-height: 14px;
  z-index: 20;
}

.warningIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.labelWrapper {
  position: relative;
  margin: 16px 0 45px 0;

  &.error {
    margin-bottom: 26px;
  }
}
