@import '../../styles/variables';
@import '../../styles/common';

.wrap {
  color: $white;
  background-color: $red;
}

.inner {
  min-height: 100%;
  height: 100%;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
}

.section {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
}

.sectionDesc {
  flex-grow: 1;
}

.sectionActions {
  flex-shrink: 0;
}

.button {
  @extend .btn;

  padding: 10px 30px;
  margin-left: 20px;
  color: $white;
  border-color: $white;
  background: transparent;
}
