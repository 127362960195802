@import './variables';

a:hover {
  color: $mainBtn;
}

button[disabled] {
  background: $white;
  color: $grayLight;
  cursor: no-drop;
  border: 2px solid transparent;

  &:hover {
    background: inherit;
    color: $grayLight;
  }
}

button:focus {
  outline: none;
}

.btn {
  display: flex;
  justify-content: center;
  border-radius: 6px;
  background: $white;
  color: $darkThree;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 10px;
  border: 2px solid $mainBtn;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: $mainBtn;
    color: $white;
  }

  &:active {
    background: $redTwo;
    color: $white;
  }
}

.btnNo {
  @extend .btn;
  width: 100px;
}

.btnYes {
  @extend .btn;
  width: 100px;
  margin-right: 20px;
  background: $mainBtn;
  color: $white;
}

.statusWpar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: $white;
  border: 1px solid $grayTable;
  border-radius: 50%;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: $yellow;
    border-radius: 50%;
  }
}

.statusActive {
  &::before {
    background: $green;
  }
}

.statusInActive {
  &::before {
    background: #c8c8c8;
  }
}

.arrow {
  position: relative;
  height: 16px;
  min-width: 9px;
  width: 9px;
  align-self: center;
  margin: 5px;
  transition: 0.5s;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 3px;
    background: $mainBtn;
    border-radius: 10px;
  }

  &:before {
    transform: rotate(45deg) translate(2px, 3px);
  }

  &:after {
    transform: rotate(-45deg) translate(-8px, 7px);
  }
}

.sectionTitle {
  font-size: 30px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  margin: 40px 0;
  text-transform: uppercase;
  color: $dark;
}

.popUpTitle {
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: 1.21333px;
  border-bottom: 2px solid $mainBtn;
  color: $dark;
}

.popUpWrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 25px 35px;
}

.popUpText {
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.visuallyHidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

.actionsBtn {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  margin-right: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 11px;
  color: $black;
  cursor: pointer;
  transition: 0.2s;
  /*width: 36px;*/

  svg {
    color: $red;
  }

  &:focus {
    outline: none;
  }
}

.actionsBtn.actionsBtnDisabled {
  background: none;
  opacity: 0.4;
}

.blocked {
  td,
  span {
    color: #cccccc !important;
  }

  :global {
    .ant-checkbox-input:focus + .ant-checkbox-inner,
    .ant-checkbox-inner {
      border-color: #cccccc;
    }
  }
}

.isAdmin,
.isAccountManager {
  td,
  span {
    font-weight: bold !important;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: -moz-pre-space;
  white-space: nowrap;
}
