
.wrapper {

}

.pointer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
}

.noWrap {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconPencil {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  right: 0;
}

.pickerActions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 5px  0;


  button:first-child {
    margin-right: 8px;
  }
  button {
    width: 50%;
  }
}

:global {
  .ant-calendar-input {
    pointer-events: none;
  }
  .ant-calendar-time-picker-btn,
  .ant-calendar-ok-btn {
    display: none !important;
  }
}