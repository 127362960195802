@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Muli:400,500,700&display=swap');
@font-face {
	font-family: "password-mask";
	font-weight: normal;
	src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);  src: url(./fonts/password.ttf);
  src: url(./fonts/password.ttf);
}

@import '../node_modules/antd/dist/antd.css';

* {
	margin: 0;
	padding: 0;
  font-family: 'Muli', sans-serif;
  box-sizing: border-box;
}

html,
body,
#root {
	height: 100%;
	scroll-behavior: smooth;
}

.slick-slider {
	width: 100%;
}

input:-webkit-autofill {
	font-family: 'Muli', sans-serif;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.ant-tooltip-content .ant-tooltip-inner {
	z-index: 2000;
	color: #424142;
	background-color: rgba(255,255,255, 0.98);
	padding: 10px 25px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
	border-bottom-color: white;
}

.ant-tooltip-placement-top .ant-tooltip-arrow:before,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow:before,
.ant-tooltip-placement-topRight .ant-tooltip-arrow:before {
	background-color: white;
}

