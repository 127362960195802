@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

.tableWrap {

  :global {
    .ant-table-wrapper {
      background: $white;

      table,
      tr,
      th,
      td {
        border-color: $grayLight !important;
      }

      th {
        border-radius: 0 !important;
        text-align: center;
      }
    }

    .ant-table-thead {
      th {
        background: $darkTwo;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        height: 40px;
        padding: 10px 20px;
      }

      .ant-table-selection-column {
        span {
          display: none;
        }
      }
    }

    .ant-table-tbody {
      tr {
        text-align: center;
      }

      tr:nth-child(even) {
        background: $grayLightTwo;
      }

      tr:hover:not(.ant-table-row-selected) > td {
        background: transparent !important;
      }

      td {
        color: $dark;
        font-size: 14px;
      }

      tr.ant-table-row-selected td {
        background: #ff9091;
        color: $white;

        span {
          color: $white;
        }
      }
    }
  }

  .datasetName {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .formInput {
      transition: .1s;
      margin-bottom: 1px;

      input {
        /*text-align: center;*/
        transition: .1s;
        padding-right: 5px;

        &:focus {
          text-align: left;
        }
      }

      &.disabled {
        border-color: transparent;

        input {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      margin-bottom: 3px;
      cursor: pointer;
      border: 0;
      background: transparent;

      svg {
        width: 10px;

        path {
          fill: $red;
        }
      }

      &[disabled] {
        cursor: not-allowed;

        svg {
          path {
            fill: rgba($red, 0.3);
          }
        }
      }
    }
  }

  .owned {
    td, input {
      font-weight: 800;
    }
  }

  .actionsTableCol {
    display: flex;
    justify-content: space-around;
    align-items: center;

    svg {
      color: $red;
      width: 19px;
      height: 25px;
    }

    .download, .upload, .delete {
      background: transparent;
      border: 0;
      cursor: pointer;

      button {
        &:disabled {
          background: transparent;

          svg {
            color: rgba($red, 0.3);
          }
        }
      }

      :global {
        .ant-upload-disabled {
          width: 100%;

          svg {
            color: rgba($red, 0.3);
          }
        }
      }
    }
  }
}
