@import '../../../../styles/common';

$width: 500px;
$height: 133px;

.modalWrap {
  width: $width !important;

  .wrapper {
    height: $height;
    padding: 25px;
  }
}

.innerWrap {
  width: 100%;
}

.dates {
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 40px 25px 0 25px;
}

.underContent {
  width: 100%;
  padding: 15px 47px 0;
  color: $red;

  p {
    margin-bottom: 0;
  }
}

.btnWrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 40px 25px 40px 25px;
}

.stepsBtn {
  @extend .btn;
  position: relative;
  background: $white;
  border: 2px solid $mainBtn;
  max-width: 176px;
  width: 100%;
  height: 40px;
}

.pointer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
}

.noWrap {
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
}

.iconPencil {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  left: 73px;
}

.link {
  font-weight: bold;
  color: #2944ae;
  text-decoration: underline;
}
