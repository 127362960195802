@import '../../../styles/variables';
@import '../../../styles/common';

.wrapper {
  min-height: 100vh;
  max-width: 1050px;
  width: 100%;
  margin: 0 auto 50px;
}

.title {
  @extend .sectionTitle;
  margin-top: 20px;
}

.tableWrap {
  :global {
    .ant-table-wrapper {
      background: $white;

      table,
      tr,
      th,
      td {
        border-color: $grayLight !important;
      }

      th {
        border-radius: 0 !important;
        text-align: center;
      }
    }

    .ant-table-thead {
      th {
        background: $darkTwo;
        color: $white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        height: 40px;
        padding: 10px 20px;
      }

      .ant-table-selection-column {
        span {
          display: none;
        }
      }
    }

    .ant-table-tbody {
      tr:nth-child(even) {
        background: $grayLightTwo;
      }

      tr:hover:not(.ant-table-row-selected) > td {
        background: transparent !important;
      }

      td {
        color: $dark;
        font-size: 14px;
      }

      tr.ant-table-row-selected td {
        background: #ff9091;
        color: $white;

        span {
          color: $white;
        }
      }
    }

    .ant-table-pagination {
      margin-right: 30px;
    }

    .ant-pagination {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      float: none;

      div,
      a {
        border: none;
      }

      * {
        color: $dark;
      }
    }

    .ant-pagination-item {
      display: none;
    }

    .ant-pagination-options-size-changer {
      border-bottom: 1px solid $dark !important;
    }

    .ant-pagination-options {
      order: -1;
      position: relative;

      &:before {
        position: absolute;
        content: 'Item per page:';
        display: flex;
        top: 4px;
        left: -105px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .ant-pagination-total-text {
      margin: 0 35px;
    }

    .ant-select-arrow {
      right: 2px;
      top: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 4px 0 4px;
      border-color: $dark transparent transparent transparent;

      * {
        display: none;
      }
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }

    .ant-select-dropdown-menu-item-active {
      background: $mainBtn !important;
      color: $white;
    }

    .ant-select-selection--single .ant-select-selection__rendered {
      margin: 0 15px 0 5px;
    }

    .ant-select-dropdown-menu-item {
      padding: 5px 10px;
    }
  }
}

.listOfRoles {
  p {
    margin: 0;
  }
}
.amount {
  padding: 10px 5px !important;
}

.optionsWrap {
  margin: 45px 0 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actionsTableCol {
  display: flex;
  justify-content: center;
  svg {
    width: 26px;
    height: 26px;
    font-size: 20px;
  }
}

.searchWrap {
  position: relative;
  flex-grow: 1;
  width: 280px;
  height: 40px;
  margin-right: 30px;

  input {
    width: 100%;
    height: 100%;
    max-width: 390px;
    padding: 10px 10px 10px 60px;
    background: $grayInput;
    border: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }

  &:before {
    position: absolute;
    display: flex;
    top: 13px;
    left: 20px;
    content: '';
    background: url('../../../images//icons/search.svg') no-repeat;
    background-size: contain;
    width: 17px;
    height: 17px;
  }
}

.details {
  color: $mainBtn;
  cursor: pointer;
  text-decoration: underline;
}

.actionsWrap {
  display: flex;
  align-items: center;
  button:last-child {
    margin-right: 5px;
  }
}

.actionsBtnWrap{
margin-left: 12px;
}

.actionsBtnWrapDisabled{
  cursor:not-allowed;
  margin-left: 12px;
}

.actionsBtnDisabled{
  pointer-events: none;
}

.sortImg {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: normal;
  img {
    cursor: pointer;
    margin-left: 5px;
    height: 14px;
  }
}

button:disabled {
  border: none;
}

.searchedWord {
  background-color: $red;
  color: $white;
  padding: 0 3px 1.5px;
  border-radius: 10px;
  font-weight: 600;
}
