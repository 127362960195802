// colors
$blue: #0093c2;
$dodgerBlue: #2267ed;
$darkBlue: #526071;
$red: #ed2224;
$redTwo: #DC2426;
$gray: #536070;
$grayTwo: #2E384D;
$grayDark:#817B7A;
$grayDarkTwo:#BABABA;
$grayLight: #C8CCCC;
$grayLightTwo: #F2F2F2;
$grayLightTree: #C8C8C8;
$grayInput: #F6F6F6;
$green: #28A181;
$white: #ffffff;
$black: #000000;
$dark: #424142;
$darkTwo: #666261;
$darkThree: #414042;
$yellow: #f9d772;
$orange: #f48e15;

$error: $red;
$mainBg: $white;
$mainBtn: $red;
$mainGray: $gray;
$grayTable: $grayDark;

// media queries
$media-md: 768px;
$media-lg: 991px;
$media-xl: 1024px;

$container: 1440px;
