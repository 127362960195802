@import '../../../styles/variables';

.checkboxesWrapper {
}

.itemTitle {
  display: block;
  color: $grayTwo;
  font-size: 15px;
  margin-bottom: 10px;
}

.checkboxes {

  span {
    color: $gray;
    font-size: 12px;
  }
}

.checkbox {
  margin-left: 0 !important;
  margin-right: 10px;
}


