@import '../../styles/variables';
@import '../../styles/common';


.wrapper {
  min-height: 100vh;
  width: 100%;
  margin: 0 auto 0;
  * {
    font-family: 'Roboto', sans-serif;
  }

  :global {
    // ANTD COLLAPSE

    .ant-collapse {
      border: 1px solid rgba(46, 56, 77, 0.2);

      &-header {
        background: $white;
        letter-spacing: 1.21333px;
        text-transform: uppercase;
        color: $gray;
        font-size: 16px;
        line-height: 19px;
        padding: 30px !important;
        padding-right: 45px !important;
        border-radius: 4px !important;

        i {
          width: 16px;
          right: 30px !important;
        }

        svg {
          color: red;
          width: 100%;
          height: 100%;
        }
      }

      &-content {
        border-top: none;

        > .ant-collapse-content-box {
          padding: 0 30px 10px 30px;
        }
      }

      &-item {
        border: none;
      }

      &-item-active {
        span.ant-collapse-arrow {
          transform: rotate(90deg) !important;
        }
      }
    }

    // END ANTD COLLAPSE
    // ANTD TABLE

    .ant-table {
      * {
        text-align: center !important;
      }
      .ant-table-header table {
        border-radius: 4px 0 0 0;
      }
    }
    .ant-table-thead tr:nth-child(1)::after {
      /*content: '';*/
      width: 99.9%;
      height: 58px;
      background: #526071;
      position: absolute;
      left: 0;
      top: 1px;
      z-index: -1;
      border-bottom: 1px solid rgb(200, 204, 204);
      border-right: 1px solid rgb(200, 204, 204);
      border-radius: 4px 4px 0 0;
    }
    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 0;
    }

    .deleteEmloyeeIcon {
      height: 30px;
    }

    .ant-table-content,
    .ant-table-fixed,
    .ant-table-fixed-left {
      z-index: -100;
      background: $white;

      tr,
      td,
      th {
        border-color: $grayLight !important;
      }
    }

    .ant-table-scroll-position-left {
      .ant-table-fixed-left {
        z-index: -100;
      }
    }
    .ant-table-fixed {
      width: min-content !important;
      border: 1px solid $grayLight !important;
      //border-right: none !important;
    }

    .ant-table-body {
      table {
        border: 0 solid $grayLight !important;
      }

      tr {
        height: 50px;
      }

    }

    .ant-table-thead {
      tr {
        height: 55px;
      }

      tr:first-child {
        text-transform: uppercase;
        background-color: $darkBlue;

        th:nth-child(1) {
          position: sticky;
          left: 0;
          z-index: 100;
          background-color: $darkBlue !important;
        }

        * {
          color: $white;
        }
      }
      tr:nth-child(2) {
        background-color: $white;

        th:nth-child(-n + 3) {
          box-sizing: border-box;
          position: sticky;
          z-index: 100;
          background-color: $white !important;
          border-right-width: 2px;
        }
        th:nth-child(1) {
          left: 0;
          border-left: 1px solid #e8e8e8;
        }
        th:nth-child(2) {
          left: 40px;
        }
        th:nth-child(3) {
          left: 125.5px;
        }
      }

      * {
        font-size: 12px;
        color: $dark;
      }
    }

    .ant-table-tbody {
      tr:nth-child(odd) {
        background-color: $grayLightTwo;
      }
      tr:nth-child(even) {
        background-color: #fff;
      }

      .ant-table-row-hover > td {
        background: unset !important;
      }

      tr.ant-table-row {
        &:hover {
          td {
            background-color: transparent !important;
          }
        }
      }

      td {
        color: $dark;
        font-size: 11px;
      }
    }

    // END ANTD TABLE
  }
}

.collapseWrap {
  margin-bottom: 35px;
}

.collapseWrapOnlyView {
  margin-bottom: 35px;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 4px;
  :focus {
    outline: none;
  }
}

.collapseArrow {
  @extend .arrow;
  top: 44% !important;
  right: 30px !important;

  &:before,
  &:after {
    display: flex !important;
  }
}

.checkboxes {
  margin: 10px;

  span {
    color: $gray;
  }
}

.itemTitle {
  display: inline-block;
  margin-right: 50px;
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: $grayTwo;
}
.actionsBtnImage {
  @extend .actionsBtn;
  position: absolute;
  left: 328px;
  top: 0;
  svg {
    height: 24px;
    width: 24px;
  }
}

.footerButton {
  @extend .btn;
  .centeredText {
    display: flex;
    align-items: center;
  }
  padding: 11px 30px;
  margin-right: 30px;
  border-radius: 4px;
  &:hover {
    .buttonImg {
      path {
        fill: $white;
      }
    }
  }
}
.buttonImg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  path {
    transition: fill 0.5s ease;
    fill: $mainBtn;
  }
}

.actionsBtn.actionsBtnDisabled {
  background: none;
  opacity: 0.4;
}

.itemFooter {
  margin: 10px 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.lastUpdate {
  display: flex;
  font-weight: 300;
  line-height: 13px;

  span {
    color: $black;
    font-size: 11px;
  }
}

.lastUpdateTitle {
  margin-left: 20px;
}

.actualWrap {
  position: relative;
}

.toolTipWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.actualBadge {
  position: absolute;
  bottom: -7px;
  right: -4px;
  background: $yellow;
  color: white;
  border-radius: 50%;
  font-size: 9px;
  width: 13px;
  height: 13px;
}

.behavior {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 0 auto;
  color: $white;
  font-size: 9px;
}

.fontSize9 {

  span {
    font-size: 9px !important;
  }
}
.score {
  @extend .behavior;
  width: 26px;
  height: 26px;
}
.scoreNoData {
  @extend .behavior;
  width: 31px;
  height: 31px;
  border: 1px solid $grayLight;
  color: $dark;
  text-align: center;
}

.scoreNoDataMod {
  @extend .scoreNoData;
  color: $white;
}

.headersWithPopover {
  max-width: 350px;
}

thead {
  th.behaviorHeader {
    border-right: none !important;
  }

  tr {
    height: 58px !important;
  }

  .behaviorHeaderFixed {
    white-space: nowrap;
  }
}

.graphImg {
  width: 85%;
  max-height: 30px;
  object-fit: scale-down;
  position: relative;
  //z-index: 50;
  transition: all 0.6s ease;
  transform-origin: bottom;
}

.graphImgModal {
  font-size: 13px;

  .graphImg {
    width: 100%;
    min-height: 160px;
    max-height: 100%;
    object-fit: contain;
  }

  div {
    margin: 5px 0;
  }

  b {
    margin: 0 5px;
  }
}

tbody {
  tr:first-child {
    .graphImg {
      transform-origin: top;
    }

    .graphImgMod {
      transform-origin: bottom;
    }
  }

  tr {
    height: 50px !important;
  }

  .location {
    @extend .ellipsis;
  }
}

th[colspan='3'],
td[colspan='3'] {
  max-width: 200px !important;
  overflow: hidden;

  * {
    @extend .ellipsis;
  }
}

.graphContainer {
  position: relative;
  padding: 8px 0 !important;
}

.behaviorGreen {
  background: $green;
}

.behaviorYellow {
  background: $yellow;
}

.behaviorRed {
  background: #c8c8c8;
}

.behaviorBlack {
  color: black;
}

.showArrayOnHover {
  list-style-type: none;
}

.imgLoader {
  margin: 0 auto;
  border: 2px solid $white;
  border-top: 2px solid $mainBtn;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.jobTitleWrap {
  display: flex;
  flex-direction: column;

  .jobTitle {
    font-weight: bold;
    font-size: 14px;
  }
}

.jobSupervisor {
  @extend .jobTitle;
}

.name {
  max-width: 80px;
  @extend .ellipsis;
}

.comboMatch {
  .sortImg {
    display: flex;
    word-break: normal;
    align-items: center;
    justify-content: center;
    span {
      font-size: 9px;
      display: flex;
    }
  }
  :global {
    .ant-table-column-title {
      font-size: 9px;
      display: flex;
    }
  }

  span.behavior {
    width: 30px;
    height: 30px;
  }
}

.tooltipOnButtonLink {
  width: 180px;
}

.statusDropdown {
  li {
    font-size: 11px;
    text-transform: lowercase;
    padding: 0 10px;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

.selectIcon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 3px 3px 0 3px;
  border-color: $dark transparent transparent transparent;
  margin-top: 4px;
}

.selectFilterIcon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: $gray transparent transparent transparent;
  transition: 0.3s;
}

.actionsWrap {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.actionsButtons {
  margin-left: 0;
}

.actionsBtn {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  margin-right: 15px;
  font-style: normal;
  font-weight: normal;
  font-size: 9px;
  line-height: 11px;
  color: $black;
  cursor: pointer;
}

.filterSets {
  position: relative;
  display: flex;
  align-items: baseline;
  margin: 25px 0;
}

.linkToShare {
  margin-left: 10px;
  margin-bottom: auto;
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: $grayTwo;
}

.actionsImg {
  margin-right: 5px;
}

.sortImg {
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: normal;
  img {
    cursor: pointer;
    margin-left: 5px;
    height: 14px;
  }
}
.filterOption {
  display: inline-block;
}
.teamFilter {
  position: relative;
  padding: 25px 0 0 25px;
  margin: 0 10px 10px 0;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 5px;
  & > div {
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

.teamFilter,
.filterSets {
  :global {
    .ant-select-selection {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $gray;
      box-shadow: none;
      cursor: pointer;
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }

    .ant-select-selection__choice {
      background: none;
      border-color: $mainBtn;
    }

    .ant-select-selection__placeholder {
      display: block !important;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $gray;
      transition: 0.3s;
    }

    .ant-select-focused {
      .ant-select-selection__placeholder {
        transform: translate(-10px, -25px);
      }
    }

    .ant-select-open {
      .ant-select-arrow-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.teamFilterHasValue {
  :global {
    .ant-select-selection__placeholder {
      transform: translate(-10px, -25px) !important;
    }
  }
}

.teamFilterDropdown,
.statusDropdown {
  :global {
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background: $mainBtn !important;
      color: white;

      svg {
        color: $white;
      }
    }

    .ant-select-dropdown-menu-item-selected {
      background: none !important;
      color: $mainBtn;

      svg {
        color: $mainBtn;
      }
    }
  }
}

:global {
  // ANTD CHECKBOX

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $white;
    border-color: $mainBtn;
    border-radius: 4px;

    &::after {
      border-color: $mainBtn;
      height: 8px;
    }
  }

  .ant-checkbox-inner {
    border-color: $mainBtn;
  }

  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: hidden;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: $mainBtn;
  }

  // END ANTD CHECKBOX
  // ANTD MODAL

  .ant-modal-body {
    padding: 0;
  }

  // END ANTD MODAL
  // ANTD TOOLTIP

  .ant-tooltip {
    max-width: 540px !important;

    div {
      text-align: justify;
    }
  }

  // END ANTD TOOLTIP
}

// SUCCESSION

.calculatorContainer {
  padding: 2px 10px !important;
}

.calculator {
  transition: all 0.6s ease;
  transform-origin: bottom;
}

.divisionCalculator {
  cursor: pointer;
}

///* width */
//::-webkit-scrollbar {
//  width: 10px;
//  height: 10px;
//  background-color: #F5F5F5;
//}
//
///* Track */
//::-webkit-scrollbar-track {
//  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
//  background-color: #F5F5F5;
//}
//
///* Handle */
//::-webkit-scrollbar-thumb {
//  background-color: #000000;
//  border: 2px solid #555555;
//}
//
///* Handle on hover */
//::-webkit-scrollbar-thumb:hover {
//  background: #b30000;
//}

.iconTrash {
  height: 20px;
  cursor: pointer;
}

.loading-mini {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 101;
}


.titleName {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  .formInput {
    transition: .1s;
    margin-bottom: 1px;
    margin-top: 0;

    input {
      /*text-align: center;*/
      transition: .1s;
      padding-right: 5px;
      text-transform: uppercase;

      &:focus {
        text-align: left;
      }
    }

    &.disabled {
      border-color: transparent;

      input {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  button {
    position: absolute;
    right: 0;
    margin-bottom: 3px;
    cursor: pointer;
    border: 0;
    background: transparent;
    z-index: 10;
    margin-right: -15px;

    svg {
      width: 10px;

      path {
        fill: white;
      }
    }

    &[disabled] {
      cursor: not-allowed;

      svg {
        path {
          fill: rgba($red, 0.3);
        }
      }
    }
  }
}

.tooltipJobTitle {
  background: #0093c2 !important;
  color: white !important;
  font-weight: bold !important;
}

.fontSize9OnHover {
  text-align: center;
  font-size: 9px;
}

th.fontSize9OnHover {
  background: #526071 !important;
  text-align: center !important;
  span {
    color: $white;
    text-align: center !important;
  }
}
