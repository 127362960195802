@import '../../../styles/variables';
@import '../../../styles/common';

.actionsWrapper {
  margin: 10px 0 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  button:first-child {
    margin-right: 20px;
  }
}

.buttonLoader {
  display: flex;
  margin-right: 8px;

  i {
    background: $redTwo;
  }
}

.footerButton {
  @extend .btn;

  .centeredText {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  padding: 5px;
  border-radius: 6px;
  &:hover {
    .buttonImg {
      path {
        fill: $white;
      }
    }
  }
  &:active {
    background: $redTwo;
    color: $white;
  }
  &:disabled {
    border: 2px solid $mainBtn;
    background: none;
    opacity: 0.4;
    .buttonImg {
      path {
        fill: $redTwo;
      }
    }
  }
}

.buttonImg {
  width: 12px;
  height: 16px;
  margin-right: 10px;
  path {
    transition: fill 0.5s ease;
    fill: $mainBtn;
  }
}
