@import '../../../../styles/variables';
@import '../../../../styles/common';

.modalWrap {
  max-width: 335px;
  width: 100%;

  .wrapper {
    @extend .popUpWrap;
  }
}

.title {
  @extend .popUpTitle;
  font-size: 30px;
  margin-bottom: 30px;
  text-align: left;
}

.subTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
}

.radioWrap {
  position: relative;

  .border {
    position: absolute;
    width: calc(100% + 15px);
    height: 85px;
    top: 50%;
    transform: translate(-10px, -55%);
    border: 1px solid $grayLight;
    border-radius: 4px;
  }
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.radio {
  margin: 0 5px;
}

.radioItemWrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  span {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 180%;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.statusWpar {
  position: absolute;
  left: 27px;
  border: none;
  margin: 0 10px 0 0;
}

.btnCancel {
  @extend .btn;

  background: $white;
  border: 2px solid $mainBtn;
  width: 100%;
}

.btnDone {
  @extend .btn;

  margin-right: 25px;
  color: $white;
  background: $mainBtn;
  width: 100%;
}

:global {
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: red;
  }

  .ant-radio + span {
    padding-left: 35px;
  }

  .ant-radio-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-radio-inner {
    border-color: $mainBtn;
    width: 10px;
    height: 10px;

    &::after {
      background-color: $mainBtn;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;
      width: 5px;
      height: 5px;
    }
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: $mainBtn;
  }
}

.statusTableWpar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin: 0 auto;
  background: $white;
  border: 1px solid $grayTable;
  border-radius: 50%;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    transform: translate(-50%, -50%);
    background: $yellow;
    border-radius: 50%;
  }
}

.statusActive {
  &::before {
    background: $green;
  }
}

.statusInActive {
  &::before {
    background: #c8c8c8;
  }
}

.statusInActiveVoluntary {
  &::before {
    background: $dodgerBlue;
  }
}

.statusInActiveInvoluntary {
  &::before {
    background: $orange;
  }
}

.warning {
  color: $red;
}
