@import '../../../styles/variables';
@import '../../../styles/common';


.wrapper {
  width: 100%;
  padding-top: 10px;
}

.itemTitle {
  display: block;
  font-size: 15px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
  color: $grayTwo;
}

.filterWrapper {
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
}

.filterSets {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 15px;
}

.teamFilter {
  position: relative;
  padding: 25px 0 0 25px;
  margin: 0 10px 10px 0;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 5px;
  & > div {
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

.filterOption {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 30px;
}

.selectFilterIcon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: $gray transparent transparent transparent;
  transition: 0.3s;
}

.teamFilterHasValue {
  :global {
    .ant-select-selection__placeholder {
      transform: translate(-10px, -25px) !important;
    }
  }
}

.teamFilter,
.filterSets {
  :global {
    .ant-select-selection {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $gray;
      box-shadow: none;
      cursor: pointer;
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }

    .ant-select-selection__choice {
      background: none;
      border-color: $mainBtn;
      max-width: 92%;
    }

    .ant-select-selection__placeholder {
      display: block !important;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $gray;
      transition: 0.3s;
    }

    .ant-select-focused {
      .ant-select-selection__placeholder {
        transform: translate(-10px, -25px);
      }
    }

    .ant-select-open {
      .ant-select-arrow-icon {
        transform: rotate(180deg);
      }
    }

    .ant-select-disabled {
      .ant-select-selection {
        background: transparent;
        /*opacity: unset;*/
        filter: opacity(0.5);
      }
    }
  }
}

.teamFilterDropdown,
.statusDropdown {
  :global {
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background: $mainBtn !important;
      color: white;

      svg {
        color: $white;
      }
    }

    .ant-select-dropdown-menu-item-selected {
      background: none !important;
      color: $mainBtn;

      svg {
        color: $mainBtn;
      }
    }
  }
}


