@import '../../../../styles/variables';
@import '../../../../styles/common';
/*@import '../../../../styles/popUpForms';*/

.badgesWrap {
  padding: 11px 0 17px;

  .badgesTitle {
    font-size: 15px;
    line-height: 22px;
    color: $grayTwo;
    margin-bottom: 11px;
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    /*margin-right: -45px;*/
    /*overflow: hidden;
    transition: .2s;*/

    .badgesItem {
      @extend .btn;
      @extend .ellipsis;
      display: block;
      border-color: $darkThree;
      margin-right: 45px;
      margin-bottom: 22.5px;
      width: 100px;
      padding: 8px;
      font-size: 14px;
      line-height: 1;
      transition: .2s;

      &:hover {
        background: #FAC5C5;
      }

      &.selected {
        background: #FAC5C5;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-color: red;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      }
    }

    .showMore {
      box-shadow: none;
      text-shadow: none;
      background: #fff;
      position: relative;
      border-color: $red;
    }
  }
}
