@import './variables';

.formInput {
  width: 100%;
  margin: 0 10px 0 0;
  input {
    font-size: 14px;
  }
}

.inputSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 25px;
  :global {
    .ant-select {
      color: #424142;
    }
    .ant-select-selection__placeholder {
      color: $mainGray;
      font-size: 16px;
      transition: transform 150ms ease-out, font-size 150ms ease-out,
        top 150ms ease-out;
    }
    .ant-select-selection__rendered {
      margin-left: 0;
      line-height: 40px;
    }
    .ant-select-selection {
      box-shadow: none;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $grayLight;
    }
    .ant-select-focused.ant-select-open {
      .ant-select-selection__placeholder {
        transform: translateY(-238%);
        font-size: 10px;
        color: $grayDark;
        top: 130%;
      }
    }
    .ant-select-focused {
      .ant-select-selection:focus {
        box-shadow: none;
        border-bottom: 1px solid $grayLight;
      }
    }
  }
}

.addUserPasswordSections {
  margin-bottom: 30px;
}

.companySelectHasValue,
.usersSelectHasValue,
.datasetHasValues{
  @extend .inputSection;
  :global {
    .ant-select-selection__placeholder {
      display: block !important;
      transform: translateY(-238%) !important;
      font-size: 10px;
      color: $grayDark;
      top: 130%;
    }
  }
}

.inputSectionLabel {
  min-width: 170px;
  width: 170px;
  flex: 1;
  margin-right: 15px;
  color: $dark;
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  padding: 0 50px;

  button {
    width: 100%;
  }
}

.btnWrapSingle {
  @extend .btnWrap;

  button {
    width: 200px;
  }
}

.editBtn {
  background: none;
  border: none;
  padding: 5px;
  cursor: pointer;

  svg {
    font-size: 14px;
    * {
      color: $mainBtn;
    }
  }
}

:global {
  .ant-select-selection__clear {
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
    opacity: 1;
  }
}
