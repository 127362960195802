@import '../../styles/variables';

.footer {
  padding: 20px 60px;
  text-align: center;
  color: $dark;
  font-size: 14px;
  background-color: rgba(200, 204, 204, 0.15);
  margin-top: 12px;
}
