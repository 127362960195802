@import '../../../../styles/common';

$width: 500px;
$height: 400px;

.modalWrap {
  width: $width !important;

  .wrapper {
    height: $height;
    padding: 25px;
  }
}

.disabled {
  opacity: 0.5;

  * {
    cursor: not-allowed !important;
  }
}

.innerWrap {
  height: 100%;
  width: 100%;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-height: $height;
  width: 100%;
  padding: 50px;

  .text {
    margin: 15px 10px 20px 10px;
    font-size: 10px;
  }
  :global {
    .ant-input:focus {
      border-color: $mainBtn;
      --antd-wave-shadow-color: $mainBtn;
      box-shadow: 0 0 0 2px #e76566;
    }

    .ant-input:hover {
      border-color: $mainBtn;
    }
  }
}

.btnWrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.stepsBtn {
  @extend .btn;
  position: relative;
  background: $white;
  border: 2px solid $mainBtn;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px;
}

.pointer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  cursor: pointer;
}

.noWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.textQuestion {
  margin: 0 10px 20px 10px;
  font-size: 16px;
}

.iconPencil {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -5px;
  left: 73px;
}

.link {
  font-weight: bold;
  color: #2944ae;
  text-decoration: underline;
}

.errorText {
  position: absolute;
  font-size: 10px;
  color: red;
}
