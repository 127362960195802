@import '../../../styles/variables';
@import '../../../styles/common';

.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
}

.title {
  margin-top: 40px;
  font-size: 26px;
  color: $dark;
}

.errorText {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 81px;
  text-align: center;
  font-size: 14px;
  color: $error;
}

.inner {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding: 40px;
  // box-shadow: 0 0 45px 25px rgba($color: $black, $alpha: 0.2);
}

.logoImg {
  width: 205px;
}

form {
  width: 100%;
}

.setPasswordBtn {
  @extend .btn;

  margin: 0 auto;
  width: 160px;
  color: $dark;

  span {
    margin-right: 10px;
    font-size: 20px;
  }
}

.subTitle {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: $black;
  text-align: left;
  align-self: flex-start;
  ul {
    margin-left: 20px;
  }
}
