@import '../../../styles/variables';
@import '../../../styles/common';

.tableWrap {
  position: relative;

  tr.boldDivision {
    .jobTitle {
      font-weight: bold !important;
      font-size: 14px !important;
    }

    td:not(.graphContainer) {
      padding: 2px 4px !important;
      font-size: 9px !important;
      color: $white !important;
      white-space: normal;
      background: inherit !important;
    }

    td:not(.wordWrap) {
      @extend .ellipsis;
    }
    td:nth-child(1) {
      z-index: 3;
      background: $darkBlue;
      position: sticky;
      left: 0;
      border-right-width: 2px;
    }

    background: $darkBlue !important;
  }

  .loading-mini {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    top: 50%;
    z-index: 5;
  }

  :global {
    .ant-table {
      * {
        text-align: center !important;
      }
      .ant-table-header table {
        border-radius: 4px 0 0 0;
      }
    }
    .ant-table-thead tr:nth-child(1)::after {
      /*content: '';*/
      width: 99.9%;
      height: 58px;
      background: #526071;
      position: absolute;
      left: 0;
      top: 1px;
      z-index: -1;
      border-bottom: 1px solid rgb(200, 204, 204);
      border-right: 1px solid rgb(200, 204, 204);
      border-radius: 4px 4px 0 0;
    }
    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 0;
    }

    .deleteEmloyeeIcon {
      height: 30px;
    }

    .ant-table-content,
    .ant-table-fixed,
    .ant-table-fixed-left {
      z-index: -100;
      background: $white;

      tr,
      td,
      th {
        border-color: $grayLight !important;
      }
    }

    .ant-table-scroll-position-left {
      .ant-table-fixed-left {
        z-index: -100;
      }
    }
    .ant-table-fixed {
      width: min-content !important;
      border: 1px solid $grayLight !important;
      //border-right: none !important;
    }

    .ant-table-body {
      table {
        border: 0 solid $grayLight !important;
      }

      tr {
        height: 50px;
      }
    }

    .ant-table-thead {
      tr {
        height: 55px;
      }

      tr:first-child {
        text-transform: uppercase;
        background-color: $darkBlue;

        th:nth-child(1) {
          position: sticky;
          left: 0;
          z-index: 100;
          background-color: $darkBlue !important;
        }

        * {
          color: $white;
        }
      }
      tr:nth-child(2) {
        background-color: $white;

        th:nth-child(-n + 3) {
          box-sizing: border-box;
          position: sticky;
          z-index: 100;
          background-color: $white !important;
          border-right-width: 2px;
        }
        th:nth-child(1) {
          left: 0;
          border-left: 1px solid #e8e8e8;
        }
        th:nth-child(2) {
          left: 40px;
        }
        th:nth-child(3) {
          left: 125.5px;
        }
      }

      * {
        font-size: 12px;
        color: $dark;
      }
    }

    .ant-table-tbody {
      tr:nth-child(odd) {
        background-color: $grayLightTwo;
      }
      tr:nth-child(even) {
        background-color: #fff;
      }

      .ant-table-row-hover > td {
        background: unset !important;
      }

      tr.ant-table-row {
        &:hover {
          td {
            background-color: transparent;
          }
        }
      }

      td {
        color: $dark;
        font-size: 11px;
      }
    }

    // END ANTD TABLE
    // ANTD SELECT

    .ant-select {
      display: flex;
    }

    .ant-select-selection {
      background: transparent;
      border: none;
      font-size: 11px;
      text-transform: lowercase;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      div:first-letter {
        text-transform: uppercase;
      }
      & .ant-select-arrow {
        display: flex;
        position: relative;
        top: auto;
        right: auto;
      }

      &__rendered {
        margin: 0px;
        display: flex;
        align-items: center;
      }

      &__placeholder,
      & .ant-select-selection-selected-value {
        color: $dark;
        text-align: start;
        position: relative;
        display: flex;
        margin: 0 10px 0 0;
        top: auto;
        right: auto;
        left: auto;
      }
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      box-shadow: none;
    }

    // END ANTD SELECT
  }
  tr.usial {
    td:nth-child(-n + 3) {
      box-sizing: border-box;
      position: sticky;
      z-index: 2;
      background: inherit !important;
      border-right-width: 2px;
    }
    td:nth-child(1) {
      left: 0;
      border-left: 1px solid #e8e8e8;
    }
    td:nth-child(2) {
      left: 40px;
    }
    td:nth-child(3) {
      left: 125px;
    }
  }

  tr.bold {
     background: $blue;

     td:not(.graphContainer) {
       padding: 2px 4px !important;
       font-size: 9px !important;
       color: $white !important;
       white-space: normal;
     }

     td:not(.wordWrap) {
       @extend .ellipsis;
     }
     td {
       &:nth-child(1) {
         z-index: 4;
         position: sticky;
         left: 0;
         border-right-width: 2px;
       }
       z-index: 1;
       background: $blue;
       position: sticky;
       top: 0;
     }

    &:hover {
      td {
        background: $blue !important;
      }
    }
   }
}

.jobSupervisor {
  @extend .jobTitle;
}

thead {
  th.behaviorHeader {
    border-right: none !important;
  }

  tr {
    height: 58px !important;
  }

  .behaviorHeaderFixed {
    white-space: nowrap;
  }
}

tbody {
  tr:first-child {
    .graphImg {
      transform-origin: top;
    }

    .graphImgMod {
      transform-origin: bottom;
    }
  }

  tr {
    height: 50px !important;
  }

  .location {
    @extend .ellipsis;
  }
}

th[colspan='3'],
td[colspan='3'] {
  max-width: 200px !important;
  overflow: hidden;

  * {
    @extend .ellipsis;
  }
}
