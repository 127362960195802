@import '../../../styles/variables';
@import '../../../styles/common';

.wrapper {
  display: flex;
  align-items: center;
}

.titleWrapper {
  display: flex;
  flex-shrink: 0;
  margin-right: 35px;
}

.actionsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}

.dashboardType {
  font-size: 12px;
  margin-right: 10px;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 500;
}

.editBtn {
  width: 10px;
  height: 11px;
  margin-left: 5px;
  margin-top: 4px;
  display: flex;
  background: none;
  border: none;
  cursor: pointer;
  svg {
    * {
      color: $mainBtn;
    }
  }
  &:disabled {
    border: none;
    svg {
      * {
         color: #ABB1B9;
      }
    }
  }
}



.tooltipOnButtonLink {
  width: 180px;
}

.actionsImg {
  margin-right: 5px;
  width: 15px !important;
} 

.trashIcon {
  width: 15px;
}

.actionsBtn{
  border: red;
  font-size: 9px;
  &:disabled {
    svg {

    }
  }
}

