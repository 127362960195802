@import '../../../styles/variables';
@import '../../../styles/common';

.wrapper {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 auto;
}

.title {
  margin-top: 40px;
  font-size: 26px;
  color: $dark;
}

.errorText {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 124px;
  text-align: center;
  font-size: 14px;
  color: $error;
}

.inner {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding: 40px;
  // box-shadow: 0 0 45px 25px rgba($color: $black, $alpha: 0.2);
}

.logoImg {
  width: 205px;
}

form {
  width: 100%;
}

.loginBtn {
  @extend .btn;

  margin: 40px auto 24px auto;
  width: 130px;
  color: $dark;

  span {
    margin-right: 10px;
    font-size: 20px;
  }
}

.passwordInput {
  margin-bottom: 12px;
}

.forgotPassword {
  text-align: center;
  text-decoration: underline;
}
