@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

.wrapper {
  @extend .popUpWrap;

  :global {
    .ant-select-focused .ant-select-selection,
    .ant-select-selection:focus,
    .ant-select-selection:active {
      border: none;
      box-shadow: 0 0 0 2px rgba($color: $mainBtn, $alpha: 0.5);
    }

    .ant-select-selection:hover {
      box-shadow: none;
      border-color: $grayLight;
    }

    .ant-select-selection--single .ant-select-selection__rendered {
      color: $dark;
    }
}
}

.title {
  @extend .popUpTitle;
  padding-bottom: 10px;
  margin-bottom: 5px;
  font-size: 30px;
}

.titleSection {
  margin: 10px 0 20px 0;
  color: $dark;
  text-align: center;
}

.generalItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.sectionWrap {
  padding: 10px 20px;
}

.btnSave {
  @extend .btnYes;
}

.addBtn {
  @extend .editBtn;
  margin-right: 2px;
  margin-left: 23px;
}

.changePasswordWrap {
  display: flex;
}

.changePassword {
  display: flex;
  color: #414042;
  margin: 7px 0 7px auto;
  border-radius: 6px;
  padding: 7px 10px;
  background: none;
  border: 1px solid $mainBtn;
  transition: 0.3s;
  cursor: pointer;
}

.errorText {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 65px;
  text-align: center;
  font-size: 14px;
  color: $error;
}

.changePasswordCancel {
  @extend .changePassword;
  border: 1px solid $mainBtn;
  margin-left: 20px;
}

.inputDisabled {
  border: none;
}

.modalWrap {
  margin: 0 auto;
  width: 400px !important;
}

.selectIcon {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: $dark transparent transparent transparent;
  margin-top: 4px;
}

.cognitiveText {
  margin: 0 10px 0 0;
}

:global {
  .ant-switch-checked {
    background: $mainBtn !important;
  }
}

.companiesDropdown,
.usersRolesDropdown {
  li {
    font-size: 14px;
    padding: 0 10px 5px;
  }

  :global {
    .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background: $mainBtn !important;
      color: white;
    }

    .ant-select-dropdown-menu-item-selected {
      background: none !important;
      color: $mainBtn;
    }
  }
}

.sections {
  display: flex;
  flex-direction: column;
}

.horizontalSections {
  flex-direction: row;

  > div {
    width: 50%;
  }
}

.sectionCompanies {
  display: flex;
  flex-direction: column;
}

.checkboxesWrap {
  position: relative;
  flex-grow: 1;
}

.checkboxesList {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  p {
    margin-bottom: 2px;
  }

  > label {
    margin-left: 0 !important;
  }
}
