@import '../../../../styles/common';

.menu {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  

  .dashboardsTitle {
    color: $red;
    letter-spacing: 1.21333px;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    padding: 12px 8px 24px;
    display: flex;
    justify-content: space-between;

    .title {
      transition: .1s;
      transform: scale(1);
    }

    svg {
      transition: .1s;
      cursor: pointer;

      &:hover {
        path {
          fill: rgba($red, 0.8);
        }
      }
    }
  }

  .dashboardsBtn {
    @extend .btn;
    width: calc(100% - 16px);
    margin: 0 8px 8px;
    height: 40px;
    align-items: center;
    transition: .1s;

    svg {
      color: red;
      margin-right: 14px;
    }

    &:hover {
      svg {
        color: white;
        rect {
          fill: white;
        }
      }
    }
  }

  .dashboardsIcon {
    border-radius: 50%;
    background: rgba(200, 204, 204, 0.61);
    width: 22px;
    height: 22px;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 1.21333px;
    color: $dark;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-right: 6px;
  }

  .dashboardsItem {
    width: calc(100% - 28px);
    overflow: hidden;
    text-overflow: ellipsis;
    transition: .1s;
  }

  :global {
    .ant-layout {
      background: #fff;
    }

    .ant-menu-submenu-title {
      transition: .1s;
    }

    .ant-menu-item {
      transition: .1s;
    }

    .ant-menu-inline {
      border-right: none;
    }

    .ant-layout-sider {
      background: #fff;
      border: 1px solid #C8CCCC;
      border-radius: 4px;
      margin-right: 8px;
      width: 200px !important;
      min-width: unset !important;
      max-width: unset !important;
      transition: .1s;
    }

    .ant-layout-sider-trigger {
      display: none;
    }

    .ant-menu-submenu {
      &-title {
        height: unset;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px !important;
        color: $dark;
        background: rgba(221, 224, 224, 0.4);
        border-radius: 8px;
        padding: 11px 7px !important;
      }

      .ant-menu-item {
        padding: 9px 8px !important;
        height: unset !important;
        margin: 6px 0;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1.21333px;

        display: flex;
        align-items: center;

        &:hover {
          color: $red
        }

        &.isOwned {
          font-weight: bold;
        }
      }

      .ant-menu-submenu-arrow {
        &:before, &:after {
          background: $red !important;
        }
      }
    }
  }

  .selected {
    background: rgba(244, 209, 209, 0.5) !important;
    color: $dark !important;

    .dashboardsIcon {
      color: #FF0000;
      background: #FFAEAE;
    }

    &:after {
      height: 55%;
      border-color: #FF0000;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      top: 23%;
    }
  }

  &.collapsed {
    :global {
      .ant-layout-sider {
        width: 68px !important;
      }

      .ant-menu-submenu-title {
        padding: 8px 12px !important;

        svg {
          width: 24px;
          height: 24px;
        }
      }

      .ant-menu-submenu-arrow {
        transform: translate3d(6px, -2px, 0) !important;
      }

      .ant-menu-item {
        justify-content: center;
      }
    }

    .dashboardsTitle {
      padding-top: 18px;
      justify-content: center;

      .title {
        transform: scale(0);
        display: none;
      }

      svg {
        transform: rotateY(180deg);
      }
    }

    .dashboardsBtn {
      svg {
        margin: 0;
      }

      &:before {
        left: unset;
      }
    }

    .dashboardsItem {
      width: 0;
    }
  }
}
.menu::-webkit-scrollbar {
  width: 10px;
}

.menu::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.menu::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
