@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

$width: 700px;
$height: 600px;

.wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;
  width: 50px;
  margin: 0 auto;
}

.innerWrap {
  @extend .wrapper;
  width: 100%;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemModal {
  display: flex;

  &:not(:nth-child(3n)) {
    border-right: 1px solid $grayLight;
  }

  &:not(:nth-child(n + 7)) {
    border-bottom: 1px solid $grayLight;
  }
}

.circle {
  width: 9px;
  height: 9px;
  margin: 2px;
  border-radius: 50%;
  background: #ffffff;
  border: 1px solid black;
}

.circleInModal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin: 10px 30px 10px 0;
  cursor: pointer;
}

.color {
  background: $mainBtn;
}

.colorOne {
  background: #d9b64c;
}

.colorTwo {
  background: $green;
}

.colorThree {
  background: #adadad;
}

.modalWrap {
  width: $width!important;

  .wrapper {
    height: $height;
    padding: 25px;
  }

  .item {
    flex-direction: column;
    justify-content: flex-start;
    padding: 10px;
    max-width: $width / 3;
    width: 100%;
    max-height: $height / 3;
    overflow-y: auto;
  }
}

.boxListWrap {
  width: 100%;
  padding: 10px;

  li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.boxListItemWrap {
  position: relative;
}

.boxListItem {
  padding-left: 20px;
}

.index {
  position: absolute;
  top: 0;
  left: 0;
}
