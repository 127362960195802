@import '../../../../styles/variables';
@import '../../../../styles/common';

.wrapper {
  padding: 20px;
  text-align: center;
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.btnSaveConfirmModal {
  @extend .btnNo;
  margin-right: 20px;
}
.btnNoConfirmModal {
  @extend .btnYes;
  margin-right: 0px;
}
