@import '../../styles/variables';

.wrapper {
  min-height: 100%;
  margin: 0 auto;
  background: $mainBg;
}

.inner {
  min-height: 100%;
  height: 100%;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 0 60px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
