@import '../../../styles/variables';
@import '../../../styles/common';

$width: 350px;
$height: 200px;

.wrapper {
  /*min-height: 100vh;*/
  width: 100%;
  margin: 0 auto 0;
  * {
    font-family: 'Roboto', sans-serif;
  }

  :global {
    // ANTD COLLAPSE

    .ant-collapse {
      border: 1px solid rgba(46, 56, 77, 0.2);

      &-header {
        background: $white;
        letter-spacing: 1.21333px;
        text-transform: uppercase;
        color: $gray;
        font-size: 16px;
        line-height: 19px;
        padding: 30px !important;
        padding-right: 45px !important;
        border-radius: 4px !important;

        i {
          width: 16px;
          right: 30px !important;
        }

        svg {
          color: red;
          width: 100%;
          height: 100%;
        }
      }

      &-content {
        border-top: none;

        > .ant-collapse-content-box {
          padding: 0 30px 10px 30px;
        }
      }

      &-item {
        border: none;
      }

      &-item-active {
        span.ant-collapse-arrow {
          transform: rotate(90deg) !important;
        }
      }
    }
    // END ANTD COLLAPSE
  }
}

.collapseWrap {
  margin-bottom: 8px;
}

.collapseWrapOnlyView {
  margin-bottom: 35px;
  border: 1px solid rgba(46, 56, 77, 0.2);
  border-radius: 4px;
  :focus {
    outline: none;
  }
}

.collapseArrow {
  @extend .arrow;
  top: 44% !important;
  right: 30px !important;

  &:before,
  &:after {
    display: flex !important;
  }
}

.modalWrap {
  width: $width !important;

  .wrapper {
    height: $height;
    padding: 25px;
  }
}

.innerWrap {
  height: 100%;
  width: 100%;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: $height;
  width: 100%;
  padding: 35px 35px 15px 35px;
}

.btnWrapper {
  display: flex;
  width: 100%;
  height: 40px;
}


.stepsBtn {
  @extend .btn;
  position: relative;
  background: $white;
  border: 2px solid $mainBtn;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
