@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

.modalWrap {
  @extend .popUpWrap;

  .innerWrap {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 25px 35px;

    .title {
      @extend .popUpTitle;
      padding-bottom: 10px;
      margin-bottom: 5px;
      font-size: 30px;
    }

    .inputSection {
      padding: 60px 20px 10px;
      flex-direction: column;
      margin-bottom: 0;

      .formInput {
        margin: 0 0 25px;
      }

      .formRadio {
        margin-bottom: 25px;
        align-self: baseline;

        .formRadioLabel {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 150%;
          margin-bottom: 0.5em;
          display: block;
          color: rgba(0, 0, 0, 0.85)
        }
      }

      :global {
        .ant-radio + span {
          padding-left: 15px;
          color: rgba(0, 0, 0, 0.65);
        }

        .ant-radio-wrapper {
          margin-bottom: 0.5em;
        }
      }

      .formSelect {
        margin-bottom: 40px;
        padding: 0;
        width: 100%;

        .selectIcon {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 5px 0 5px;
          border-color: $dark transparent transparent transparent;
          margin-top: 4px;
        }
      }

      .btnWrap {
        width: 100%;
        padding: 0 30px;
      }
    }
  }
}

.modalConfirm {
  width: 400px !important;
}


:global {
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    box-shadow: none;
  }

  .ant-select-selection:hover {
    box-shadow: none;
    border-color: $grayLight;
  }

  .ant-select-selection--single .ant-select-selection__rendered {
    color: $dark;
    overflow: hidden;
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background: $mainBtn !important;
    color: white;
  }

  .ant-select-dropdown-menu-item-selected {
    background: none !important;
    color: $mainBtn;
  }
}
