@import '../../../styles/variables';
@import '../../../styles/common';

$width: 350px;
$height: 200px;

.modalWrap {
  width: $width !important;

  .wrapper {
    height: $height;
    padding: 25px;
  }
}

.innerWrap {
  height: 100%;
  width: 100%;
}

.title {
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding-bottom: 50px;
  font-style: normal;
  font-weight: bold;
  color: $dark;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: $height;
  width: 100%;
  padding: 35px 35px 15px 35px;
}

.btnWrapper {
  display: flex;
  width: 100%;
  height: 40px;
}

.stepsBtn {
  @extend .btn;
  position: relative;
  background: $white;
  border: 2px solid $mainBtn;
  max-width: 200px;
  width: 100%;
  margin: auto 10px 0 10px;
  height: 40px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
