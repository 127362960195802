@import '../../../../styles/variables';
@import '../../../../styles/common';
@import '../../../../styles/popUpForms';

.modalWrap {
  .innerWrap {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 25px 35px;

    .title {
      @extend .popUpTitle;
      padding-bottom: 10px;
      margin-bottom: 5px;
      font-size: 30px;
    }
  }
}
